import * as React from 'react';
//import { useUser } from './auth';
import { useUserQuery } from './api/authClient';
import { LoadingRing } from '@components/loaders/LoadingRing';
import { useMsal } from '@azure/msal-react';

export enum ROLES {
  'Admin', // Full permissions, including ability to edit dropdown lists, choose which experiments are accessible to users, and grant access to new users
  'Scientist/Technician', // Read/Write permissions for all Experiments and Tickets
  'Product Mgr/BD', // Read permissions for all Experiments and Tickets
  'Operations', // Permission to Create new Tickets and Read Tickets, but no access to Read or Write Experiments
}

type RoleTypes = keyof typeof ROLES;

export const useAuthorization = () => {
  const { instance, accounts } = useMsal();

  const account = instance.getAllAccounts()[0];

  if (!account) {
    console.log('User does not exist');
    instance.loginRedirect();
  }

  const userName = account?.username.toLocaleLowerCase() || '';
  const { data: userRoles, isLoading: isLoadingRoles } = useUserQuery(userName);
  const checkAccess = React.useCallback(
    ({ allowedRoles }: { allowedRoles: RoleTypes[] }) => {
      if (allowedRoles && allowedRoles.length > 0 && Array.isArray(userRoles)) {
        // Verify if any of the user's roles are in the allowed roles
        return userRoles.some((role) => allowedRoles.includes(role as RoleTypes));
      }

      return false;
    },
    [userRoles]
  );

  return { checkAccess, roles: userRoles, isLoadingRoles };
};

type AuthorizationProps = {
  forbiddenFallback?: React.ReactNode;
  children: React.ReactNode;
} & (
  | {
      allowedRoles: RoleTypes[];
      policyCheck?: never;
    }
  | {
      allowedRoles?: never;
      policyCheck: boolean;
    }
);

export const Authorization = ({
  policyCheck,
  allowedRoles,
  forbiddenFallback = null,
  children,
}: AuthorizationProps) => {
  const { checkAccess, isLoadingRoles } = useAuthorization();

  let canAccess = false;

  if (allowedRoles) {
    canAccess = checkAccess({ allowedRoles });
  }

  if (typeof policyCheck !== 'undefined') {
    canAccess = policyCheck;
  }
  if (isLoadingRoles) return <LoadingRing />;

  return <>{canAccess ? children : forbiddenFallback}</>;
};
