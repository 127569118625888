import client from "../../lib/api/client";
import { useQuery, queryOptions } from "@tanstack/react-query";
import type { GetApiV1CatalogsDropdownsTypeidQueryResponse, GetApiV1CatalogsDropdownsTypeidPathParams, GetApiV1CatalogsDropdownsTypeid401, GetApiV1CatalogsDropdownsTypeid403 } from "../models/GetApiV1CatalogsDropdownsTypeid";
import type { QueryObserverOptions, UseQueryResult, QueryKey } from "@tanstack/react-query";

type GetApiV1CatalogsDropdownsTypeidClient = typeof client<GetApiV1CatalogsDropdownsTypeidQueryResponse, GetApiV1CatalogsDropdownsTypeid401 | GetApiV1CatalogsDropdownsTypeid403, never>;
type GetApiV1CatalogsDropdownsTypeid = {
    data: GetApiV1CatalogsDropdownsTypeidQueryResponse;
    error: GetApiV1CatalogsDropdownsTypeid401 | GetApiV1CatalogsDropdownsTypeid403;
    request: never;
    pathParams: GetApiV1CatalogsDropdownsTypeidPathParams;
    queryParams: never;
    headerParams: never;
    response: GetApiV1CatalogsDropdownsTypeidQueryResponse;
    client: {
        parameters: Partial<Parameters<GetApiV1CatalogsDropdownsTypeidClient>[0]>;
        return: Awaited<ReturnType<GetApiV1CatalogsDropdownsTypeidClient>>;
    };
};
export const getApiV1CatalogsDropdownsTypeidQueryKey = (typeId: GetApiV1CatalogsDropdownsTypeidPathParams["typeId"]) => [{ url: "/api/v1/catalogs/dropdowns/:typeId", params: { typeId: typeId } }] as const;
export type GetApiV1CatalogsDropdownsTypeidQueryKey = ReturnType<typeof getApiV1CatalogsDropdownsTypeidQueryKey>;
export function getApiV1CatalogsDropdownsTypeidQueryOptions(typeId: GetApiV1CatalogsDropdownsTypeidPathParams["typeId"], options: GetApiV1CatalogsDropdownsTypeid["client"]["parameters"] = {}) {
    const queryKey = getApiV1CatalogsDropdownsTypeidQueryKey(typeId);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<GetApiV1CatalogsDropdownsTypeid["data"], GetApiV1CatalogsDropdownsTypeid["error"]>({
                method: "get",
                url: `/api/v1/catalogs/dropdowns/${typeId}`,
                ...options
            });
            return res.data;
        },
    });
}
/**
     * @link /api/v1/catalogs/dropdowns/:typeId */
export function useGetApiV1CatalogsDropdownsTypeid<TData = GetApiV1CatalogsDropdownsTypeid["response"], TQueryData = GetApiV1CatalogsDropdownsTypeid["response"], TQueryKey extends QueryKey = GetApiV1CatalogsDropdownsTypeidQueryKey>(typeId: GetApiV1CatalogsDropdownsTypeidPathParams["typeId"], options: {
    query?: Partial<QueryObserverOptions<GetApiV1CatalogsDropdownsTypeid["response"], GetApiV1CatalogsDropdownsTypeid["error"], TData, TQueryData, TQueryKey>>;
    client?: GetApiV1CatalogsDropdownsTypeid["client"]["parameters"];
} = {}): UseQueryResult<TData, GetApiV1CatalogsDropdownsTypeid["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getApiV1CatalogsDropdownsTypeidQueryKey(typeId);
    const query = useQuery({
        ...getApiV1CatalogsDropdownsTypeidQueryOptions(typeId, clientOptions) as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, GetApiV1CatalogsDropdownsTypeid["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}