import client from "../../lib/api/client";
import { useMutation } from "@tanstack/react-query";
import type { PostApiV1ReportsProjectDetailsMutationRequest, PostApiV1ReportsProjectDetailsMutationResponse, PostApiV1ReportsProjectDetails401, PostApiV1ReportsProjectDetails403 } from "../models/PostApiV1ReportsProjectDetails";
import type { UseMutationOptions } from "@tanstack/react-query";

type PostApiV1ReportsProjectDetailsClient = typeof client<PostApiV1ReportsProjectDetailsMutationResponse, PostApiV1ReportsProjectDetails401 | PostApiV1ReportsProjectDetails403, PostApiV1ReportsProjectDetailsMutationRequest>;
type PostApiV1ReportsProjectDetails = {
    data: PostApiV1ReportsProjectDetailsMutationResponse;
    error: PostApiV1ReportsProjectDetails401 | PostApiV1ReportsProjectDetails403;
    request: PostApiV1ReportsProjectDetailsMutationRequest;
    pathParams: never;
    queryParams: never;
    headerParams: never;
    response: PostApiV1ReportsProjectDetailsMutationResponse;
    client: {
        parameters: Partial<Parameters<PostApiV1ReportsProjectDetailsClient>[0]>;
        return: Awaited<ReturnType<PostApiV1ReportsProjectDetailsClient>>;
    };
};
/**
     * @link /api/v1/reports/project-details */
export function usePostApiV1ReportsProjectDetails(options: {
    mutation?: UseMutationOptions<PostApiV1ReportsProjectDetails["response"], PostApiV1ReportsProjectDetails["error"], PostApiV1ReportsProjectDetails["request"]>;
    client?: PostApiV1ReportsProjectDetails["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    return useMutation({
        mutationFn: async (data) => {
            const res = await client<PostApiV1ReportsProjectDetails["data"], PostApiV1ReportsProjectDetails["error"], PostApiV1ReportsProjectDetails["request"]>({
                method: "post",
                url: `/api/v1/reports/project-details`,
                data,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions
    });
}