import { forwardRef } from 'react';
import { Form, Checkbox } from 'react-daisyui';

type Props = {
  label?: string;
  error?: string;
  is_required?: string;
};

export const FormCheckbox = forwardRef<HTMLInputElement, Props>(({ label, error, is_required, ...restProps }, ref) => {
  return (
    <div className="flex w-full component-preview pb-4 items-center justify-center gap-2 font-sans">
      <div className="form-control w-full max-w-xs">
        <label className="label">
          <span className="label-text">
            {label}
            {is_required && <span className="label-text text-red-400"> * </span>}
          </span>
          {error && <span className="label-text-alt text-red-400">{error}</span>}
        </label>
        {/* <Form.Label title={label}> */}
        <div className="text-center">
          <Checkbox
            ref={ref}
            size="sm"
            {...restProps}
          />
        </div>
        {/* </Form.Label> */}
      </div>
    </div>
  );
});
