import React from 'react';
import { Header } from './Header';
import './app-layout-min.css';
import { Outlet } from 'react-router';
import { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

type AppLayoutMinProps = {
  children?: React.ReactNode;
  msalInstance: IPublicClientApplication;
};

export const AppLayoutMin = ({ msalInstance, children }: AppLayoutMinProps) => {
  return (
    <MsalProvider instance={msalInstance}>
      <div className="app-layout-min m-4">
        <Header />
        <div className="main mx-auto px-4">
          <Outlet />
        </div>
      </div>
    </MsalProvider>
  );
};
