import { usePostApiV1ReportsProjectDetails } from '@sdk-client/hooks';
import { useCallback, useState } from 'react';
import { Alert, Button, Card, FileInput, Hero } from 'react-daisyui';

export const ImportProjectDedatilsData = () => {
  const [file, setFile] = useState<File | null>(null);

  const {
    mutateAsync: uploadFile,
    isPending,
    isError,
    isSuccess,
    error,
  } = usePostApiV1ReportsProjectDetails({
    client: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  });

  const handleFileChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile && selectedFile.type === 'text/csv') {
      setFile(selectedFile);
    } else {
      setFile(null);
    }
  }, []);

  const handleUpload = useCallback(async () => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target?.result) {
          const binaryString = event.target.result as string;
          uploadFile({ file: binaryString });
        }
      };
      reader.readAsArrayBuffer(file);
    }
  }, [file, uploadFile]);

  return (
    <Hero>
      <Card className="flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
        <Card.Body>
          <div className="form-control space-y-4">
            <h2 className="text-lg font-bold mb-2">Import Project Details (CSV file)</h2>
            <FileInput
              bordered={true}
              size="md"
              onChange={handleFileChange}
              accept=".csv"
              disabled={isPending}
            />
            {isError && (
              <Alert status="error">
                {error instanceof Error ? error.message : 'An error occurred while uploading the file.'}
              </Alert>
            )}
            {isSuccess && <Alert status="success">File uploaded successfully!</Alert>}
            <Button
              color="secondary"
              type="button"
              onClick={handleUpload}
              loading={isPending}
              disabled={!file || isPending}
            >
              {isPending ? 'Uploading...' : 'Upload CSV'}
            </Button>
          </div>
        </Card.Body>
      </Card>
    </Hero>
  );
};
