import client from "../../lib/api/client";
import { useQuery, queryOptions } from "@tanstack/react-query";
import type { GetApiV1ExperimentsCorrosionExperimentidResultsImageTypeQueryResponse, GetApiV1ExperimentsCorrosionExperimentidResultsImageTypePathParams, GetApiV1ExperimentsCorrosionExperimentidResultsImageType400, GetApiV1ExperimentsCorrosionExperimentidResultsImageType401, GetApiV1ExperimentsCorrosionExperimentidResultsImageType403, GetApiV1ExperimentsCorrosionExperimentidResultsImageType404 } from "../models/GetApiV1ExperimentsCorrosionExperimentidResultsImageType";
import type { QueryObserverOptions, UseQueryResult, QueryKey } from "@tanstack/react-query";

type GetApiV1ExperimentsCorrosionExperimentidResultsImageTypeClient = typeof client<GetApiV1ExperimentsCorrosionExperimentidResultsImageTypeQueryResponse, GetApiV1ExperimentsCorrosionExperimentidResultsImageType400 | GetApiV1ExperimentsCorrosionExperimentidResultsImageType401 | GetApiV1ExperimentsCorrosionExperimentidResultsImageType403 | GetApiV1ExperimentsCorrosionExperimentidResultsImageType404, never>;
type GetApiV1ExperimentsCorrosionExperimentidResultsImageType = {
    data: GetApiV1ExperimentsCorrosionExperimentidResultsImageTypeQueryResponse;
    error: GetApiV1ExperimentsCorrosionExperimentidResultsImageType400 | GetApiV1ExperimentsCorrosionExperimentidResultsImageType401 | GetApiV1ExperimentsCorrosionExperimentidResultsImageType403 | GetApiV1ExperimentsCorrosionExperimentidResultsImageType404;
    request: never;
    pathParams: GetApiV1ExperimentsCorrosionExperimentidResultsImageTypePathParams;
    queryParams: never;
    headerParams: never;
    response: GetApiV1ExperimentsCorrosionExperimentidResultsImageTypeQueryResponse;
    client: {
        parameters: Partial<Parameters<GetApiV1ExperimentsCorrosionExperimentidResultsImageTypeClient>[0]>;
        return: Awaited<ReturnType<GetApiV1ExperimentsCorrosionExperimentidResultsImageTypeClient>>;
    };
};
export const getApiV1ExperimentsCorrosionExperimentidResultsImageTypeQueryKey = (experimentId: GetApiV1ExperimentsCorrosionExperimentidResultsImageTypePathParams["experimentId"], type: GetApiV1ExperimentsCorrosionExperimentidResultsImageTypePathParams["type"]) => [{ url: "/api/v1/experiments/corrosion/:experimentId/results/image/:type", params: { experimentId: experimentId, type: type } }] as const;
export type GetApiV1ExperimentsCorrosionExperimentidResultsImageTypeQueryKey = ReturnType<typeof getApiV1ExperimentsCorrosionExperimentidResultsImageTypeQueryKey>;
export function getApiV1ExperimentsCorrosionExperimentidResultsImageTypeQueryOptions(experimentId: GetApiV1ExperimentsCorrosionExperimentidResultsImageTypePathParams["experimentId"], type: GetApiV1ExperimentsCorrosionExperimentidResultsImageTypePathParams["type"], options: GetApiV1ExperimentsCorrosionExperimentidResultsImageType["client"]["parameters"] = {}) {
    const queryKey = getApiV1ExperimentsCorrosionExperimentidResultsImageTypeQueryKey(experimentId, type);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<GetApiV1ExperimentsCorrosionExperimentidResultsImageType["data"], GetApiV1ExperimentsCorrosionExperimentidResultsImageType["error"]>({
                method: "get",
                url: `/api/v1/experiments/corrosion/${experimentId}/results/image/${type}`,
                ...options
            });
            return res.data;
        },
    });
}
/**
     * @link /api/v1/experiments/corrosion/:experimentId/results/image/:type */
export function useGetApiV1ExperimentsCorrosionExperimentidResultsImageType<TData = GetApiV1ExperimentsCorrosionExperimentidResultsImageType["response"], TQueryData = GetApiV1ExperimentsCorrosionExperimentidResultsImageType["response"], TQueryKey extends QueryKey = GetApiV1ExperimentsCorrosionExperimentidResultsImageTypeQueryKey>(experimentId: GetApiV1ExperimentsCorrosionExperimentidResultsImageTypePathParams["experimentId"], type: GetApiV1ExperimentsCorrosionExperimentidResultsImageTypePathParams["type"], options: {
    query?: Partial<QueryObserverOptions<GetApiV1ExperimentsCorrosionExperimentidResultsImageType["response"], GetApiV1ExperimentsCorrosionExperimentidResultsImageType["error"], TData, TQueryData, TQueryKey>>;
    client?: GetApiV1ExperimentsCorrosionExperimentidResultsImageType["client"]["parameters"];
} = {}): UseQueryResult<TData, GetApiV1ExperimentsCorrosionExperimentidResultsImageType["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getApiV1ExperimentsCorrosionExperimentidResultsImageTypeQueryKey(experimentId, type);
    const query = useQuery({
        ...getApiV1ExperimentsCorrosionExperimentidResultsImageTypeQueryOptions(experimentId, type, clientOptions) as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, GetApiV1ExperimentsCorrosionExperimentidResultsImageType["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}