import { useState, useCallback } from 'react';
import { Collapse } from 'react-daisyui';
import './collapse-style.css';

type CollapseProps = {
  openDefault?: boolean;
  children: React.ReactNode;
};

export const CollapseCustom = ({ openDefault = false, children }: CollapseProps) => {
  const [isOpen, setIsOpen] = useState(openDefault);
  const handleToggle = useCallback(() => {
    setIsOpen((prevState) => !prevState);
  }, []);
  return (
    <Collapse
      checkbox
      icon="arrow"
      className="overflow-visible bg-base-200"
      open={isOpen}
      onToggle={handleToggle}
    >
      {children}
    </Collapse>
  );
};
