import axios from 'axios';
import { API_URL } from '@config';
import type { AxiosError, AxiosHeaders, AxiosRequestConfig, AxiosResponse } from 'axios';
import { msalInstance } from '../../routes/AppRouter';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

const AXIOS_BASE: string = API_URL;
declare const AXIOS_HEADERS: string;

/**
 * Subset of AxiosRequestConfig
 */
export type RequestConfig<TData = unknown> = {
  baseURL?: string;
  url?: string;
  method: 'get' | 'put' | 'patch' | 'post' | 'delete';
  params?: unknown;
  data?: TData;
  responseType?: 'arraybuffer' | 'blob' | 'document' | 'json' | 'text' | 'stream';
  signal?: AbortSignal;
  headers?: AxiosRequestConfig['headers'];
};
/**
 * Subset of AxiosResponse
 */
export type ResponseConfig<TData = unknown> = {
  data: TData;
  status: number;
  statusText: string;
  headers?: AxiosResponse['headers'];
};

export const axiosInstance = axios.create({
  baseURL: typeof AXIOS_BASE !== 'undefined' ? AXIOS_BASE : undefined,
  headers: typeof AXIOS_HEADERS !== 'undefined' ? (JSON.parse(AXIOS_HEADERS) as AxiosHeaders) : undefined,
});

const getAccessToken = async () => {
  // MSAL.js v2 exposes several account APIs, logic to determine which account to use is the responsibility of the developer
  const account = msalInstance.getAllAccounts()[0];

  const accessTokenRequest = {
    scopes: ['api://b74957b4-1f9a-485d-a12d-8fe1024909e6/App.Read'],
    account: account,
  };

  // Use the same publicClientApplication instance provided to MsalProvider
  try {
    const accessTokenResponse = await msalInstance.acquireTokenSilent(accessTokenRequest);
    return accessTokenResponse.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      await msalInstance.acquireTokenRedirect(accessTokenRequest);
    } else {
      console.log('Access token error: ', error);
    }
  }
};
axiosInstance.interceptors.request.use(
  async (config) => {
    const accessToken = await getAccessToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const axiosClient = async <TData, TError = unknown, TVariables = unknown>(
  config: RequestConfig<TVariables>
): Promise<ResponseConfig<TData>> => {
  const promise = axiosInstance.request<TData, ResponseConfig<TData>>(config).catch((e: AxiosError<TError>) => {
    throw e;
  });

  return promise;
};

export default axiosClient;
