import client from "../../lib/api/client";
import { useQuery, queryOptions } from "@tanstack/react-query";
import type { GetApiV1ExperimentsCorrosionQueryResponse, GetApiV1ExperimentsCorrosion401, GetApiV1ExperimentsCorrosion403 } from "../models/GetApiV1ExperimentsCorrosion";
import type { QueryObserverOptions, UseQueryResult, QueryKey } from "@tanstack/react-query";

type GetApiV1ExperimentsCorrosionClient = typeof client<GetApiV1ExperimentsCorrosionQueryResponse, GetApiV1ExperimentsCorrosion401 | GetApiV1ExperimentsCorrosion403, never>;
type GetApiV1ExperimentsCorrosion = {
    data: GetApiV1ExperimentsCorrosionQueryResponse;
    error: GetApiV1ExperimentsCorrosion401 | GetApiV1ExperimentsCorrosion403;
    request: never;
    pathParams: never;
    queryParams: never;
    headerParams: never;
    response: GetApiV1ExperimentsCorrosionQueryResponse;
    client: {
        parameters: Partial<Parameters<GetApiV1ExperimentsCorrosionClient>[0]>;
        return: Awaited<ReturnType<GetApiV1ExperimentsCorrosionClient>>;
    };
};
export const getApiV1ExperimentsCorrosionQueryKey = () => [{ url: "/api/v1/experiments/corrosion" }] as const;
export type GetApiV1ExperimentsCorrosionQueryKey = ReturnType<typeof getApiV1ExperimentsCorrosionQueryKey>;
export function getApiV1ExperimentsCorrosionQueryOptions(options: GetApiV1ExperimentsCorrosion["client"]["parameters"] = {}) {
    const queryKey = getApiV1ExperimentsCorrosionQueryKey();
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<GetApiV1ExperimentsCorrosion["data"], GetApiV1ExperimentsCorrosion["error"]>({
                method: "get",
                url: `/api/v1/experiments/corrosion`,
                ...options
            });
            return res.data;
        },
    });
}
/**
     * @link /api/v1/experiments/corrosion */
export function useGetApiV1ExperimentsCorrosion<TData = GetApiV1ExperimentsCorrosion["response"], TQueryData = GetApiV1ExperimentsCorrosion["response"], TQueryKey extends QueryKey = GetApiV1ExperimentsCorrosionQueryKey>(options: {
    query?: Partial<QueryObserverOptions<GetApiV1ExperimentsCorrosion["response"], GetApiV1ExperimentsCorrosion["error"], TData, TQueryData, TQueryKey>>;
    client?: GetApiV1ExperimentsCorrosion["client"]["parameters"];
} = {}): UseQueryResult<TData, GetApiV1ExperimentsCorrosion["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getApiV1ExperimentsCorrosionQueryKey();
    const query = useQuery({
        ...getApiV1ExperimentsCorrosionQueryOptions(clientOptions) as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, GetApiV1ExperimentsCorrosion["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}