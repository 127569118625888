import { zStringRequired } from '@components/zod/rules';
import * as z from 'zod';

export enum TypeDualOptions {
  Yes = 'true',
  No = 'false',
}

const DATE_REGEX_MATCHER = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/; // YYYY-MM-DD

export const schema = z.object({
  name: z.string().min(1, { message: 'Required' }).default(''),
  workLocation: z.string(),
  phone: z.string().min(1, { message: 'Required' }),
  email: z.string().min(1, { message: 'Required' }).email(),
  customer: z.string().min(1, { message: 'Required' }),
  isRequestForTenderBid: z.nativeEnum(TypeDualOptions, {
    errorMap: () => ({ message: 'Required' }),
  }),
  formationName: z.string(),
  fieldName: z.string(),
  typeOfRequest: zStringRequired,
  application: zStringRequired,
  psl: zStringRequired,
  projectRevenue: z.string().min(1, { message: 'Required' }),
  isShippingSamples: z.nativeEnum(TypeDualOptions, {
    errorMap: () => ({ message: 'Required' }),
  }),
  isSampleCheckedForH2s: z.boolean(),
  h2sContent: z.string(),
  costCenter: z.string().min(1, { message: 'Required' }),
  wellName: z.string(),
  depth: z.string(),
  bottomHoleTemperature: z.string(),
  bottomHolePressure: z.string(),
  typeOfDataRequested: z.string().min(1, { message: 'Required' }),
  fluidDetails: z.string(),
  projectNumber: z.string(),
  projectTitle: z.string(),
  projectType: z.string(),
  networkCode: z.string(),
  laborCode: z.string(),
  materialsCode: z.string(),
  servicesCode: z.string(),
  travelCode: z.string(),
  projectLead: z.string(),
  projectLeadEmail: z.string(),
  shippingAddress: z.string(),
  projectResources: z.string(),
  status: z.string(),
  dateOfRequest: z.nullable(z.string()).default(null),
  dateFinalReportRequested: z
    .string({
      required_error: 'Required',
      invalid_type_error: 'Required',
    })
    .min(1, { message: 'Required' })
    .regex(DATE_REGEX_MATCHER, {
      message: 'Invalid date format',
    }),
  readyForCloseDate: z.nullable(z.string()).default(null),
});

// Function to check if a field is required
export const isFieldRequired = (fieldName: keyof FormInput) => {
  const field = schema.shape[fieldName];
  // Check if the field has a nonempty constraint
  return field.safeParse('').success === false;
};

export type FormInput = z.infer<typeof schema>;
