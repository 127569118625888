import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { CellProps } from '@components/tables/EditableTable';
import { useTableConfig } from '@components/tables/EditableTable/ConfigProvider';
import { additiveDegBeMap } from './constants';
import { getOtherVolumeMass, getStockAcidVolume } from './hcl-calculation';
import { AdditionRow } from '../../types';
import { setWaterVolMass } from './utils';
import { Row } from '@components/tables/types';

export const VolMassCell = <T extends Row>({ row, column }: CellProps<T>) => {
  const { setValue, getValues } = useFormContext();
  const { rowsPropertyName } = useTableConfig();

  const useWatchOptions = (name: string) => useWatch({ name, defaultValue: '' });
  const sampleVolumeValue = useWatchOptions(`sampleVolumeMl`);
  const desiredAcidConcentrationValue = useWatchOptions(`desiredAcidConcentration`);
  const additiveTypeValue = useWatchOptions(`${rowsPropertyName}.${row.index}.additiveType`);
  const additiveValue = useWatchOptions(`${rowsPropertyName}.${row.index}.additive`);
  const concentrationValue = useWatchOptions(`${rowsPropertyName}.${row.index}.concentration`);
  const volMassValue = useWatchOptions(`${rowsPropertyName}.${row.index}.${column.id}`);

  useEffect(() => {
    const setValueFunc = (value: number) =>
      setValue(`${rowsPropertyName}.${row.index}.${column.id}`, isNaN(value) ? '' : value.toFixed(2));

    if (additiveTypeValue === 'acid' && additiveDegBeMap.hasOwnProperty(additiveValue)) {
      const hciConc = additiveDegBeMap[additiveValue];
      const volume = getStockAcidVolume(sampleVolumeValue, hciConc, desiredAcidConcentrationValue);
      setValueFunc(volume);
    } else if (additiveValue !== 'water' && additiveTypeValue !== 'acid') {
      const otherVolume = getOtherVolumeMass(sampleVolumeValue, concentrationValue);
      setValueFunc(otherVolume);
    }
    setWaterVolMass(getValues(`${rowsPropertyName}`) as AdditionRow[], sampleVolumeValue, rowsPropertyName, setValue);
  }, [
    additiveTypeValue,
    additiveValue,
    column.id,
    concentrationValue,
    desiredAcidConcentrationValue,
    getValues,
    row.index,
    rowsPropertyName,
    sampleVolumeValue,
    setValue,
  ]);

  return <span className="font-sans">{volMassValue}</span>;
};
