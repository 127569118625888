import { UserPermissionsData } from '@lib/api/authClient';
import { createColumnHelper } from '@tanstack/react-table';

type CreateColumnsProps = {};

export const createColumns = (props: CreateColumnsProps) => {
  const columnHelper = createColumnHelper<UserPermissionsData>();
  return [
    columnHelper.accessor('name', {
      header: 'Name',
      size: 70,
      cell: (item) => item.getValue(),
    }),
    columnHelper.accessor('email_address', {
      header: 'Email Address',
      size: 70,
      cell: (item) => item.getValue(),
      maxSize: 2,
    }),
    columnHelper.accessor('halID', {
      header: 'Hal ID',
      size: 70,
      cell: (item) => item.getValue(),
      maxSize: 2,
    }),
    columnHelper.accessor('roles', {
      header: 'Roles',
      size: 70,
      cell: (item) => item.getValue().join(', '),
      maxSize: 2,
    }),
  ];
};
