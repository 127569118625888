import client from "../../lib/api/client";
import { useMutation } from "@tanstack/react-query";
import type { PostApiV1ExperimentsCorrosionCopyMutationRequest, PostApiV1ExperimentsCorrosionCopyMutationResponse, PostApiV1ExperimentsCorrosionCopy400, PostApiV1ExperimentsCorrosionCopy401, PostApiV1ExperimentsCorrosionCopy403 } from "../models/PostApiV1ExperimentsCorrosionCopy";
import type { UseMutationOptions } from "@tanstack/react-query";

type PostApiV1ExperimentsCorrosionCopyClient = typeof client<PostApiV1ExperimentsCorrosionCopyMutationResponse, PostApiV1ExperimentsCorrosionCopy400 | PostApiV1ExperimentsCorrosionCopy401 | PostApiV1ExperimentsCorrosionCopy403, PostApiV1ExperimentsCorrosionCopyMutationRequest>;
type PostApiV1ExperimentsCorrosionCopy = {
    data: PostApiV1ExperimentsCorrosionCopyMutationResponse;
    error: PostApiV1ExperimentsCorrosionCopy400 | PostApiV1ExperimentsCorrosionCopy401 | PostApiV1ExperimentsCorrosionCopy403;
    request: PostApiV1ExperimentsCorrosionCopyMutationRequest;
    pathParams: never;
    queryParams: never;
    headerParams: never;
    response: PostApiV1ExperimentsCorrosionCopyMutationResponse;
    client: {
        parameters: Partial<Parameters<PostApiV1ExperimentsCorrosionCopyClient>[0]>;
        return: Awaited<ReturnType<PostApiV1ExperimentsCorrosionCopyClient>>;
    };
};
/**
     * @link /api/v1/experiments/corrosion/copy */
export function usePostApiV1ExperimentsCorrosionCopy(options: {
    mutation?: UseMutationOptions<PostApiV1ExperimentsCorrosionCopy["response"], PostApiV1ExperimentsCorrosionCopy["error"], PostApiV1ExperimentsCorrosionCopy["request"]>;
    client?: PostApiV1ExperimentsCorrosionCopy["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    return useMutation({
        mutationFn: async (data) => {
            const res = await client<PostApiV1ExperimentsCorrosionCopy["data"], PostApiV1ExperimentsCorrosionCopy["error"], PostApiV1ExperimentsCorrosionCopy["request"]>({
                method: "post",
                url: `/api/v1/experiments/corrosion/copy`,
                data,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions
    });
}