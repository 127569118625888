import { Loading } from 'react-daisyui';

type LoadingRingProps = {};

export const LoadingRing = (props: LoadingRingProps) => {
  return (
    <div className="w-auto h-80 flex items-center justify-center">
      <Loading
        size="lg"
        variant="ring"
      />
    </div>
  );
};
