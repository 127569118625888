import client from "../../lib/api/client";
import { useMutation } from "@tanstack/react-query";
import type { PutApiV1TechnicalServicesTicketrequestidMutationRequest, PutApiV1TechnicalServicesTicketrequestidMutationResponse, PutApiV1TechnicalServicesTicketrequestidPathParams, PutApiV1TechnicalServicesTicketrequestid400, PutApiV1TechnicalServicesTicketrequestid401, PutApiV1TechnicalServicesTicketrequestid403 } from "../models/PutApiV1TechnicalServicesTicketrequestid";
import type { UseMutationOptions } from "@tanstack/react-query";

type PutApiV1TechnicalServicesTicketrequestidClient = typeof client<PutApiV1TechnicalServicesTicketrequestidMutationResponse, PutApiV1TechnicalServicesTicketrequestid400 | PutApiV1TechnicalServicesTicketrequestid401 | PutApiV1TechnicalServicesTicketrequestid403, PutApiV1TechnicalServicesTicketrequestidMutationRequest>;
type PutApiV1TechnicalServicesTicketrequestid = {
    data: PutApiV1TechnicalServicesTicketrequestidMutationResponse;
    error: PutApiV1TechnicalServicesTicketrequestid400 | PutApiV1TechnicalServicesTicketrequestid401 | PutApiV1TechnicalServicesTicketrequestid403;
    request: PutApiV1TechnicalServicesTicketrequestidMutationRequest;
    pathParams: PutApiV1TechnicalServicesTicketrequestidPathParams;
    queryParams: never;
    headerParams: never;
    response: PutApiV1TechnicalServicesTicketrequestidMutationResponse;
    client: {
        parameters: Partial<Parameters<PutApiV1TechnicalServicesTicketrequestidClient>[0]>;
        return: Awaited<ReturnType<PutApiV1TechnicalServicesTicketrequestidClient>>;
    };
};
/**
     * @link /api/v1/technical-services/:ticketRequestId */
export function usePutApiV1TechnicalServicesTicketrequestid(ticketRequestId: PutApiV1TechnicalServicesTicketrequestidPathParams["ticketRequestId"], options: {
    mutation?: UseMutationOptions<PutApiV1TechnicalServicesTicketrequestid["response"], PutApiV1TechnicalServicesTicketrequestid["error"], PutApiV1TechnicalServicesTicketrequestid["request"]>;
    client?: PutApiV1TechnicalServicesTicketrequestid["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    return useMutation({
        mutationFn: async (data) => {
            const res = await client<PutApiV1TechnicalServicesTicketrequestid["data"], PutApiV1TechnicalServicesTicketrequestid["error"], PutApiV1TechnicalServicesTicketrequestid["request"]>({
                method: "put",
                url: `/api/v1/technical-services/${ticketRequestId}`,
                data,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions
    });
}