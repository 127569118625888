import { Select } from 'react-daisyui';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { CellProps, DropDownCellMeta } from '@components/tables/EditableTable';
import { useTableConfig } from '@components/tables/EditableTable/ConfigProvider';
import { useEffect, useState } from 'react';
import { Row } from '@components/tables/types';

export const AdditiveCell = <T extends Row>({ row, column }: CellProps<T>) => {
  const { rowsPropertyName } = useTableConfig();
  const [additiveTypeOld, setAdditiveTypeOld] = useState<string | undefined>();
  const [rowIdValueOld, setRowIdValueOld] = useState<string | undefined>();
  const meta = column.columnDef.meta as DropDownCellMeta;
  const { register } = useFormContext();
  const { update } = useFieldArray({
    name: rowsPropertyName,
  });

  const additiveTypeValue = useWatch({
    name: `${rowsPropertyName}.${row.index}.additiveType`,
    defaultValue: '',
  });
  useEffect(() => {
    if (
      additiveTypeValue &&
      additiveTypeOld !== additiveTypeValue &&
      (!rowIdValueOld || rowIdValueOld === row.original.id)
    ) {
      setRowIdValueOld(row.original.id);
      setAdditiveTypeOld(additiveTypeValue);
    }
  }, [
    additiveTypeOld,
    additiveTypeValue,
    column.id,
    row.index,
    row.original,
    row.original.id,
    rowIdValueOld,
    rowsPropertyName,
    update,
  ]);

  return (
    <Select
      size="sm"
      key={row.original.id}
      {...register(`${rowsPropertyName}.${row.index}.${column.id}`)}
      defaultValue={''}
    >
      <Select.Option
        key={'-1'}
        value=""
        disabled
      >
        Pick one
      </Select.Option>
      {meta.options
        .filter((option) => option.relatedKeys.includes(additiveTypeValue))
        .map((option) => (
          <Select.Option
            key={option.value}
            value={option.value}
          >
            {option.text}
          </Select.Option>
        ))}
    </Select>
  );
};
