import { AdditionRow } from '../types';
import { DropDownCellMeta, EditableTable } from '@components/tables/EditableTable';
import createColumns from './columns';
import { TableCtxProvider } from '@components/tables/EditableTable/ConfigProvider';
import { CatalogType } from '@pages/Admin/DropDownListData/types';
import { useGetApiV1CatalogsDropdownsTypeid } from '@sdk-client/hooks';
import { LoadingRing } from '@components/loaders/LoadingRing';

const newRow: AdditionRow = {
  id: '0',
  physicalState: '',
  orderOfAddition: '',
  additiveType: '',
  additive: '',
  lotNumber: '',
  concentration: '',
  volMass: '',
};

const RecipeTable = () => {
  const { data: additiveType, isPending: isPendingAdditiveType } = useGetApiV1CatalogsDropdownsTypeid(
    Number(CatalogType.ADDITIVE_TYPE)
  );
  const { data: additive, isPending: isPendingAdditive } = useGetApiV1CatalogsDropdownsTypeid(
    Number(CatalogType.ADDITIVE)
  );
  const { data: physicalState, isPending: isPendingPhysicalState } = useGetApiV1CatalogsDropdownsTypeid(
    Number(CatalogType.PHYSICAL_STATE)
  );

  const additiveTypeOp: DropDownCellMeta = {
    options: additiveType ?? [],
  };
  const additiveOp: DropDownCellMeta = {
    options: additive ?? [],
  };

  const columns = createColumns({ additiveTypeOp, additiveOp, physicalStateOp: physicalState ?? [] });

  if (isPendingAdditiveType || isPendingAdditive || isPendingPhysicalState) return <LoadingRing />;

  return (
    <TableCtxProvider value={{ rowsPropertyName: 'acidBlendAdditions' }}>
      <EditableTable
        data={[]}
        columns={columns}
        rowDefaultValues={newRow}
      />
    </TableCtxProvider>
  );
};

export default RecipeTable;
