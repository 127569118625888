import { createColumnHelper } from '@tanstack/react-table';
import { AdditionRow } from '../types';
import { TextBoxCell, DropDownCellMeta, OptionRelated } from '@components/tables/EditableTable';
import { AdditiveTypeCell } from './components/additive-type-cell';
import { AdditiveCell } from './components/additive-cell';
import { PhysicalStateCell } from './components/physical-state-cell';
import { OrderOfAddition } from './components/order-of-addition';
import { ConcentrationCell } from './components/concentration-cell';
import { VolMassCell } from './components/vol-mass-cell';
import { ActionsCell } from './components/actions-cell';

type createColumnsProps = {
  additiveTypeOp: DropDownCellMeta;
  additiveOp: DropDownCellMeta;
  physicalStateOp: OptionRelated[];
};

const createColumns = ({ additiveTypeOp, additiveOp, physicalStateOp }: createColumnsProps) => {
  const columnHelper = createColumnHelper<AdditionRow>();
  return [
    columnHelper.accessor('physicalState', {
      header: 'Physical State',
      cell: PhysicalStateCell,
      meta: physicalStateOp,
    }),
    columnHelper.accessor('orderOfAddition', {
      header: 'Order of Addition',
      cell: OrderOfAddition,
    }),
    columnHelper.accessor('additiveType', {
      header: 'Additive Type',
      cell: AdditiveTypeCell,
      meta: additiveTypeOp,
    }),
    columnHelper.accessor('additive', {
      header: 'Additive',
      cell: AdditiveCell,
      meta: additiveOp,
    }),
    columnHelper.accessor('lotNumber', {
      header: 'Lot Number',
      cell: TextBoxCell,
    }),
    columnHelper.accessor('concentration', {
      header: 'Concentration (gpt, ppt)',
      cell: ConcentrationCell,
    }),
    columnHelper.accessor('volMass', {
      header: 'Vol/Mass (mL, g)',
      cell: VolMassCell,
    }),
    columnHelper.display({
      id: 'actions',
      header: 'Actions',
      cell: ActionsCell,
    }),
  ];
};

export default createColumns;
