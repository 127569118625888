import { TextBox } from '@components/form';
import { Form } from 'react-daisyui';
import { optionSchema } from './schema';
import { useFormContextExtended } from '@components/form/hooks/useFormContextExtended';
import { CatalogType } from '../types';
import { DropDownCatalogViewSch } from '@sdk-client/models';
import { useEffect } from 'react';

export type AddNewItemFormSimpleProps = {
  typeId: CatalogType;
  initialData: DropDownCatalogViewSch | null;
};

export const AddNewItemFormSimple = ({ typeId, initialData }: AddNewItemFormSimpleProps) => {
  const { extendRegister, errors, setValue } = useFormContextExtended(optionSchema);
  useEffect(() => {
    if (initialData) {
      setValue('id', initialData.id);
      setValue('value', initialData.value);
      setValue('text', initialData.text);
    }
  }, [initialData, setValue]);

  return (
    <>
      <Form>
        <TextBox
          label="Value"
          error={errors.value?.message}
          {...extendRegister('value')}
        />
        <TextBox
          label="Text"
          error={errors.text?.message}
          {...extendRegister('text')}
        />
      </Form>
    </>
  );
};
