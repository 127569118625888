import OperationTileList from './components/operation-tile-list';
import { useEffect, useState } from 'react';
import { Operation } from './types';

export const TestMethods = () => {
  const [ops, setOperations] = useState<Operation[]>([]);
  useEffect(() => {
    const ops = getOperations();
    setOperations(ops);
  }, []);
  return <OperationTileList list={ops} />;
};

const getOperations = () => {
  return [
    {
      id: 1,
      name: 'Acidizing',
      processes: [
        { name: 'Corrosion', linkTo: '/experiments/corrosion' },
        { name: 'Acid Dissolution', linkTo: '' },
        { name: 'Core Flood (PVBT)', linkTo: '' },
        { name: 'Acid Etching', linkTo: '' },
        { name: 'Sludge', linkTo: '' },
        { name: 'Emulsion Break', linkTo: '' },
        { name: 'Blend Compatibility', linkTo: '' },
      ],
    },
    {
      id: 2,
      name: 'Fluids',
      processes: [
        { name: 'Friction Reduction', linkTo: '' },
        { name: 'FR Viscosity', linkTo: '' },
        { name: 'Regainen Perm', linkTo: '' },
        { name: 'Break test', linkTo: '' },
        { name: 'Slot flow', linkTo: '' },
        { name: 'Scale loop', linkTo: '' },
        { name: 'Foam rheology', linkTo: '' },
        { name: 'Water analysis', linkTo: '' },
        { name: 'Hydration time', linkTo: '' },
        { name: 'Crossling test', linkTo: '' },
      ],
    },
    {
      id: 3,
      name: 'Sand Control',
      processes: [
        { name: 'Sieve Analysis', linkTo: '' },
        { name: 'Crush Resistance', linkTo: '' },
        { name: 'Sand Consolidation (UCS)', linkTo: '' },
        { name: 'PSD, sphericity, roundness', linkTo: '' },
      ],
    },
    {
      id: 4,
      name: 'Diverters',
      processes: [
        { name: 'Degradation Time', linkTo: '' },
        { name: 'Fluid loss control', linkTo: '' },
        { name: 'PSD', linkTo: '' },
      ],
    },
    {
      id: 5,
      name: 'Surfactants',
      processes: [
        { name: 'Interfacial tension', linkTo: '' },
        { name: 'Contact angle', linkTo: '' },
        { name: 'Surface tension', linkTo: '' },
      ],
    },
  ];
};
