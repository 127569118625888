import { ConfirmationAlertTextContent, ConfirmationAlertType } from './ConfirmationAlert';

const buildDeleteConfirmationAlertTextContent = (itemName: string, details?: string): ConfirmationAlertTextContent => {
  return {
    title: `Removal of ${itemName}`,
    message: `Do you really want to delete this ${itemName}?`,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
    details: details,
  };
};

const buildDefaultConfirmationAlertTextContent = (): ConfirmationAlertTextContent => {
  return {
    title: 'Removal of Item',
    message: 'Do you really want to delete this item?',
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
  };
};

export const buildConfirmationAlertTextContent = (
  itemName: string,
  confirmType: ConfirmationAlertType,
  details?: string
): ConfirmationAlertTextContent => {
  switch (confirmType) {
    case ConfirmationAlertType.Delete:
      return buildDeleteConfirmationAlertTextContent(itemName, details);
    default:
      return buildDefaultConfirmationAlertTextContent();
  }
};
