import { TextBox, valueAsNumberOptional } from '@components/form';
import PaddedDivision from '../padded-division';
import useFormContextExtended from '../useFormContextExtended';
import { schema } from '../../schema';
import { getFlatRectangularSquareNoHoleSurfaceArea } from './surface-area-calculations';
import { useEffect } from 'react';

type FlatRectangularSquareNoHoleProps = {};

const FlatRectangularSquareNoHole = (props: FlatRectangularSquareNoHoleProps) => {
  const { extendRegister, errors, watch, setValue } = useFormContextExtended(schema);
  const length = watch('flatRectangularSquareNoHole.length');
  const width = watch('flatRectangularSquareNoHole.width');
  const thickness = watch('flatRectangularSquareNoHole.thickness');

  useEffect(() => {
    const sa = getFlatRectangularSquareNoHoleSurfaceArea(length, width, thickness);
    const roundedSa = Number(sa.toFixed(4));
    setValue('flatRectangularSquareNoHole.surfaceArea', roundedSa);
    setValue('surfaceArea', roundedSa);
  }, [length, width, thickness, setValue]);

  return (
    <PaddedDivision>
      <TextBox
        type="number"
        label="Length (L), in"
        error={errors.flatRectangularSquareNoHole?.length?.message}
        {...extendRegister('flatRectangularSquareNoHole.length', {
          setValueAs: valueAsNumberOptional,
        })}
      />
      <TextBox
        type="number"
        label="Width (W), in"
        error={errors.flatRectangularSquareNoHole?.width?.message}
        {...extendRegister('flatRectangularSquareNoHole.width', {
          setValueAs: valueAsNumberOptional,
        })}
      />
      <TextBox
        type="number"
        label="Thickness (T), in"
        error={errors.flatRectangularSquareNoHole?.thickness?.message}
        {...extendRegister('flatRectangularSquareNoHole.thickness', {
          setValueAs: valueAsNumberOptional,
        })}
      />
      <TextBox
        type="number"
        readOnly={true}
        label={
          <span>
            Surface Area, in <sup>2</sup>
          </span>
        }
        error={errors.flatRectangularSquareNoHole?.surfaceArea?.message}
        {...extendRegister('flatRectangularSquareNoHole.surfaceArea', {
          setValueAs: valueAsNumberOptional,
        })}
      />
    </PaddedDivision>
  );
};

export default FlatRectangularSquareNoHole;
