import React from 'react';

interface ConfigType {
  rowsPropertyName: string;
}

const ConfigContext = React.createContext<ConfigType | undefined>(undefined);

export const useTableConfig = (): ConfigType => {
  const context = React.useContext(ConfigContext);
  if (!context) {
    throw new Error('useTableConfig should be used within a TableProvider');
  }
  return context;
};

interface TableCtxProviderProps {
  children: React.ReactNode;
  value: ConfigType;
}

export const TableCtxProvider = ({ children, value }: TableCtxProviderProps) => {
  return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>;
};
