import client from "../../lib/api/client";
import { useMutation } from "@tanstack/react-query";
import type { DeleteApiV1CatalogsDropdownsTypeidCatalogidMutationResponse, DeleteApiV1CatalogsDropdownsTypeidCatalogidPathParams, DeleteApiV1CatalogsDropdownsTypeidCatalogidHeaderParams, DeleteApiV1CatalogsDropdownsTypeidCatalogid400, DeleteApiV1CatalogsDropdownsTypeidCatalogid401, DeleteApiV1CatalogsDropdownsTypeidCatalogid403, DeleteApiV1CatalogsDropdownsTypeidCatalogid404 } from "../models/DeleteApiV1CatalogsDropdownsTypeidCatalogid";
import type { UseMutationOptions } from "@tanstack/react-query";

type DeleteApiV1CatalogsDropdownsTypeidCatalogidClient = typeof client<DeleteApiV1CatalogsDropdownsTypeidCatalogidMutationResponse, DeleteApiV1CatalogsDropdownsTypeidCatalogid400 | DeleteApiV1CatalogsDropdownsTypeidCatalogid401 | DeleteApiV1CatalogsDropdownsTypeidCatalogid403 | DeleteApiV1CatalogsDropdownsTypeidCatalogid404, never>;
type DeleteApiV1CatalogsDropdownsTypeidCatalogid = {
    data: DeleteApiV1CatalogsDropdownsTypeidCatalogidMutationResponse;
    error: DeleteApiV1CatalogsDropdownsTypeidCatalogid400 | DeleteApiV1CatalogsDropdownsTypeidCatalogid401 | DeleteApiV1CatalogsDropdownsTypeidCatalogid403 | DeleteApiV1CatalogsDropdownsTypeidCatalogid404;
    request: never;
    pathParams: DeleteApiV1CatalogsDropdownsTypeidCatalogidPathParams;
    queryParams: never;
    headerParams: DeleteApiV1CatalogsDropdownsTypeidCatalogidHeaderParams;
    response: DeleteApiV1CatalogsDropdownsTypeidCatalogidMutationResponse;
    client: {
        parameters: Partial<Parameters<DeleteApiV1CatalogsDropdownsTypeidCatalogidClient>[0]>;
        return: Awaited<ReturnType<DeleteApiV1CatalogsDropdownsTypeidCatalogidClient>>;
    };
};
/**
     * @link /api/v1/catalogs/dropdowns/:typeId/:catalogId */
export function useDeleteApiV1CatalogsDropdownsTypeidCatalogid(typeId: DeleteApiV1CatalogsDropdownsTypeidCatalogidPathParams["typeId"], catalogId: DeleteApiV1CatalogsDropdownsTypeidCatalogidPathParams["catalogId"], headers: DeleteApiV1CatalogsDropdownsTypeidCatalogid["headerParams"], options: {
    mutation?: UseMutationOptions<DeleteApiV1CatalogsDropdownsTypeidCatalogid["response"], DeleteApiV1CatalogsDropdownsTypeidCatalogid["error"], void>;
    client?: DeleteApiV1CatalogsDropdownsTypeidCatalogid["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    return useMutation({
        mutationFn: async () => {
            const res = await client<DeleteApiV1CatalogsDropdownsTypeidCatalogid["data"], DeleteApiV1CatalogsDropdownsTypeidCatalogid["error"], void>({
                method: "delete",
                url: `/api/v1/catalogs/dropdowns/${typeId}/${catalogId}`,
                headers: { ...headers, ...clientOptions.headers },
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions
    });
}