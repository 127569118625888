import { TextBox, valueAsNumberOptional } from '@components/form';
import PaddedDivision from '../padded-division';
import useFormContextExtended from '../useFormContextExtended';
import { schema } from '../../schema';
import { getFlatCircleWithHoleSurfaceArea } from './surface-area-calculations';
import { useEffect } from 'react';

type FlatCircleWithHoleProps = {};

const FlatCircleWithHole = (props: FlatCircleWithHoleProps) => {
  const { extendRegister, errors, watch, setValue } = useFormContextExtended(schema);
  const diameter = watch('flatCircleWithHole.diameter');
  const thickness = watch('flatCircleWithHole.thickness');
  const holeDiameter = watch('flatCircleWithHole.holeDiameter');

  useEffect(() => {
    const sa = getFlatCircleWithHoleSurfaceArea(diameter, thickness, holeDiameter);
    const roundedSa = Number(sa.toFixed(4));
    setValue('flatCircleWithHole.surfaceArea', roundedSa);
    setValue('surfaceArea', roundedSa);
  }, [diameter, thickness, holeDiameter, setValue]);

  return (
    <PaddedDivision>
      <TextBox
        type="number"
        label="Diameter (D), in"
        error={errors.flatCircleWithHole?.diameter?.message}
        {...extendRegister('flatCircleWithHole.diameter', {
          setValueAs: valueAsNumberOptional,
        })}
      />
      <TextBox
        type="number"
        label="Thickness (T), in"
        error={errors.flatCircleWithHole?.thickness?.message}
        {...extendRegister('flatCircleWithHole.thickness', {
          setValueAs: valueAsNumberOptional,
        })}
      />
      <TextBox
        type="number"
        label="Hole Diameter (D), in"
        error={errors.flatCircleWithHole?.holeDiameter?.message}
        {...extendRegister('flatCircleWithHole.holeDiameter', {
          setValueAs: valueAsNumberOptional,
        })}
      />
      <TextBox
        type="number"
        readOnly={true}
        label={
          <span>
            Surface Area, in <sup>2</sup>
          </span>
        }
        error={errors.flatCircleWithHole?.surfaceArea?.message}
        {...extendRegister('flatCircleWithHole.surfaceArea', {
          setValueAs: valueAsNumberOptional,
        })}
      />
    </PaddedDivision>
  );
};

export default FlatCircleWithHole;
