import { getHCIAndSGByDegBe } from './acid-table-lookup';
import { WATER_DENSITY_60F_LB_GAL, CONVERSION_FACTORS } from './constants';

/**
 * Density of water at 60°F in g/ml
 */
const WATER_DENSITY_G_ML = (WATER_DENSITY_60F_LB_GAL * CONVERSION_FACTORS.g_lb) / CONVERSION_FACTORS.ml_gal;

/**
 * function to get the density in g/ml and lb/gal for a given specific S.G
 * @param sg
 * @returns g/mL and lb/gal
 */
export const getDensity = (sg: number) => ({
  g_ml: sg * WATER_DENSITY_G_ML,
  lb_gal: sg * WATER_DENSITY_60F_LB_GAL,
});
/**
 * function to get the % HCI and S.G. by degrees Baumé
 * @param degBe
 * @returns lb HCl/gal and g HCl/mL
 */
export const getHCIPorcentage = (degBe: number) => {
  const { hci, sg } = getHCIAndSGByDegBe(degBe);
  const density = getDensity(sg);
  const hclPorc = hci / 100;
  return {
    lb_HCL_gal: hclPorc * density.lb_gal,
    g_HCL_mL: hclPorc * density.g_ml,
  };
};

/**
 * function to get the target S.G. of the acid needed to dilute to the target concentration
 * @param targetHCIAcidConc
 * @returns
 */
export const getTargetSG = (targetHCIAcidConc: number) => 0.005057 * targetHCIAcidConc + 0.999776;

/**
 * function to get the target density of the acid needed to dilute to the target concentration
 * @param targetHCIAcidConc
 * @returns g/mL
 */
export const getTargetDensity = (targetHCIAcidConc: number) => getTargetSG(targetHCIAcidConc) * WATER_DENSITY_G_ML;

/**
 * function to get the mass of the acid needed to dilute to the target concentration
 * @param targetVolume
 * @param targetHCIAcidConc
 * @returns g HCl
 */
export const getTargetAcidMass = (targetVolume: number, targetHCIAcidConc: number) =>
  targetVolume * getTargetDensity(targetHCIAcidConc) * (targetHCIAcidConc / 100);

/**
 * function to get the volume of the stock acid needed to dilute to the target concentration
 * @param targetVolume
 * @param degBe
 * @param targetHCIAcidConc
 * @returns mL
 */
export const getStockAcidVolume = (targetVolume: number, degBe: number, targetHCIAcidConc: number) =>
  getTargetAcidMass(targetVolume, targetHCIAcidConc) / getHCIPorcentage(degBe).g_HCL_mL;

/**
 * function to get the concentration of the stock acid needed to dilute to the target concentration
 * @param targetVolume
 * @param degBe
 * @param targetHCIAcidConc
 * @returns gal stock acid/1000 gal target vol (gpt)
 */
export const getStockAcidConc = (targetVolume: number, degBe: number, targetHCIAcidConc: number) =>
  (getStockAcidVolume(targetVolume, degBe, targetHCIAcidConc) / targetVolume) * 1000;

/**
 * function to get the volume of water needed to dilute the acid to the target concentration
 * @param targetVolume
 * @param targetHCIAcidConc
 * @returns mL
 */
export const getWaterVolume = (targetVolume: number, targetHCIAcidConc: number) =>
  targetVolume - getTargetAcidMass(targetVolume, targetHCIAcidConc);

/**
 * function to get the concentration of water needed to dilute the acid to the target concentration
 * @param targetVolume
 * @param targetHCIAcidConc
 * @returns gal water/1000 gal target vol (gpt)
 */
export const getWaterConc = (targetVolume: number, targetHCIAcidConc: number) =>
  (getWaterVolume(targetVolume, targetHCIAcidConc) / targetVolume) * 1000;

/**
 * function to get the volume/mass of other additive concentrations input by the user
 * @param targetVolume
 * @param targetAdditiveConc
 * @returns
 */
export const getOtherVolumeMass = (targetVolume: number, targetAdditiveConc: number) =>
  (targetAdditiveConc / 1000) * targetVolume;

/**
 * function to get the volume of water
 * @param targetVolume
 * @param totalAdditivesVolume
 * @returns
 */
export const getWaterVolume2 = (targetVolume: number, totalAdditivesVolume: number) =>
  targetVolume - totalAdditivesVolume;

/**
 * function to get the concentration of water
 * @param targetVolume
 * @param totalAdditivesVolume
 * @returns
 */
export const getWaterConc2 = (targetVolume: number, waterVolume: number) => (waterVolume / targetVolume) * 1000;
