import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { AdditionRow } from '../../types';
import { getWaterConc2, getWaterVolume2 } from './hcl-calculation';

export const setWaterVolMass = (
  payload: AdditionRow[],
  sampleVolumeValue: number,
  rowsPropertyName: string,
  setValue: UseFormSetValue<FieldValues>
) => {
  // TODO: CSOTO is possible to have two or more water rows?
  // aren't there any dry additives?
  const rowIndex = payload.findIndex((item) => item.additive === 'water');
  if (rowIndex < 0) return;
  const totalVolume = payload.reduce((acc, item, i) => (i === rowIndex ? acc : acc + Number(item.volMass)), 0);
  const newVolMass = getWaterVolume2(sampleVolumeValue, totalVolume);
  const volMass = Number.isNaN(newVolMass) ? 0 : newVolMass;
  setValue(`${rowsPropertyName}.${rowIndex}.volMass`, volMass.toFixed(2));

  const waterConc = getWaterConc2(sampleVolumeValue, volMass);
  setValue(`${rowsPropertyName}.${rowIndex}.concentration`, Number.isNaN(waterConc) ? 0 : waterConc.toFixed(0));
};
