import client from "../../lib/api/client";
import { useMutation } from "@tanstack/react-query";
import type { PutApiV1CatalogsDropdownsTypeidCatalogidMutationRequest, PutApiV1CatalogsDropdownsTypeidCatalogidMutationResponse, PutApiV1CatalogsDropdownsTypeidCatalogidPathParams, PutApiV1CatalogsDropdownsTypeidCatalogidHeaderParams, PutApiV1CatalogsDropdownsTypeidCatalogid400, PutApiV1CatalogsDropdownsTypeidCatalogid401, PutApiV1CatalogsDropdownsTypeidCatalogid403, PutApiV1CatalogsDropdownsTypeidCatalogid404 } from "../models/PutApiV1CatalogsDropdownsTypeidCatalogid";
import type { UseMutationOptions } from "@tanstack/react-query";

type PutApiV1CatalogsDropdownsTypeidCatalogidClient = typeof client<PutApiV1CatalogsDropdownsTypeidCatalogidMutationResponse, PutApiV1CatalogsDropdownsTypeidCatalogid400 | PutApiV1CatalogsDropdownsTypeidCatalogid401 | PutApiV1CatalogsDropdownsTypeidCatalogid403 | PutApiV1CatalogsDropdownsTypeidCatalogid404, PutApiV1CatalogsDropdownsTypeidCatalogidMutationRequest>;
type PutApiV1CatalogsDropdownsTypeidCatalogid = {
    data: PutApiV1CatalogsDropdownsTypeidCatalogidMutationResponse;
    error: PutApiV1CatalogsDropdownsTypeidCatalogid400 | PutApiV1CatalogsDropdownsTypeidCatalogid401 | PutApiV1CatalogsDropdownsTypeidCatalogid403 | PutApiV1CatalogsDropdownsTypeidCatalogid404;
    request: PutApiV1CatalogsDropdownsTypeidCatalogidMutationRequest;
    pathParams: PutApiV1CatalogsDropdownsTypeidCatalogidPathParams;
    queryParams: never;
    headerParams: PutApiV1CatalogsDropdownsTypeidCatalogidHeaderParams;
    response: PutApiV1CatalogsDropdownsTypeidCatalogidMutationResponse;
    client: {
        parameters: Partial<Parameters<PutApiV1CatalogsDropdownsTypeidCatalogidClient>[0]>;
        return: Awaited<ReturnType<PutApiV1CatalogsDropdownsTypeidCatalogidClient>>;
    };
};
/**
     * @link /api/v1/catalogs/dropdowns/:typeId/:catalogId */
export function usePutApiV1CatalogsDropdownsTypeidCatalogid(typeId: PutApiV1CatalogsDropdownsTypeidCatalogidPathParams["typeId"], catalogId: PutApiV1CatalogsDropdownsTypeidCatalogidPathParams["catalogId"], headers: PutApiV1CatalogsDropdownsTypeidCatalogid["headerParams"], options: {
    mutation?: UseMutationOptions<PutApiV1CatalogsDropdownsTypeidCatalogid["response"], PutApiV1CatalogsDropdownsTypeidCatalogid["error"], PutApiV1CatalogsDropdownsTypeidCatalogid["request"]>;
    client?: PutApiV1CatalogsDropdownsTypeidCatalogid["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    return useMutation({
        mutationFn: async (data) => {
            const res = await client<PutApiV1CatalogsDropdownsTypeidCatalogid["data"], PutApiV1CatalogsDropdownsTypeidCatalogid["error"], PutApiV1CatalogsDropdownsTypeidCatalogid["request"]>({
                method: "put",
                url: `/api/v1/catalogs/dropdowns/${typeId}/${catalogId}`,
                data,
                headers: { ...headers, ...clientOptions.headers },
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions
    });
}