import { useState } from 'react';
import { Form, Tabs } from 'react-daisyui';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormInput, schema, TypeDualOptions } from './schema';
import { DateValueType } from 'react-tailwindcss-datepicker';
import RequestDetailsTab from './components/request-details-tab';
import TechnicalDetailsTab from './components/technical-details-tab';
import ProjectDetails from './components/project-details';
import TabsFooter from './components/tabs-footer';
import { RequestFormSelection } from './RequestFormSelection';
import { RequestLocation } from './components/types';
import { usePostApiV1TechnicalServicesRequest } from '@sdk-client/hooks';
import { LoadingRing } from '@components/loaders/LoadingRing';
import { CreateTicketRequestSch } from '@sdk-client/models';
import { useNavigate } from 'react-router';
import { dateToYYYYMMDD } from '@utils/dateUtils';

export const RequestForm = () => {
  const navigate = useNavigate();
  const activeTabState = useState<number>(1);

  const [ticketRequestStarted, setTicketRequestStarted] = useState<boolean>(false);
  const [requestFacility, setRequestFacility] = useState<RequestLocation | undefined>(undefined);
  const [isSaved, setIsSaved] = useState(false);

  const [activeTab, setActiveTab] = activeTabState;
  const dateInitialValue = {
    startDate: null,
    endDate: null,
  };
  const dateOfRequestState = useState<DateValueType>({
    startDate: new Date(),
    endDate: new Date(),
  });
  const dateFinalReportState = useState<DateValueType>(dateInitialValue);
  const dateReadyCloseState = useState<DateValueType>(dateInitialValue);
  const [, setDateOfRequestValue] = dateOfRequestState;
  const [, setDateFinalReportValue] = dateFinalReportState;

  const totalTabs = 3;

  const methods = useForm<FormInput>({
    mode: 'onChange',
    resolver: zodResolver(schema),
    defaultValues: {
      dateOfRequest: dateToYYYYMMDD(),
    },
  });

  const { mutateAsync: mutateFormAsync, isPending: isPendingForm } = usePostApiV1TechnicalServicesRequest({
    mutation: {
      onSuccess: (responseData, postedData) => {
        if (isSaved) {
          navigate(`/ticketing/request/update/${responseData.id}`);
          return;
        }
        navigate('/ticketing/tickets');
      },
    },
  });

  const onSubmit: SubmitHandler<FormInput> = async (data, e) => {
    const nativeEvent = e?.nativeEvent as SubmitEvent;
    const submitter = nativeEvent.submitter;
    setIsSaved(submitter?.id === 'save');
    const body: CreateTicketRequestSch = {
      ...data,
      isRequestForTenderBid: data.isRequestForTenderBid === TypeDualOptions.Yes,
      isShippingSamples: data.isShippingSamples === TypeDualOptions.Yes,
      requestThroughId: requestFacility === 'Houston' ? 1 : 2,
    };
    await mutateFormAsync(body);
  };
  const formReset = () => {
    methods.reset();
    setActiveTab(1);
    setDateOfRequestValue({
      startDate: new Date(),
      endDate: new Date(),
    });
    setDateFinalReportValue(dateInitialValue);
    setTicketRequestStarted(false);
  };

  const formSave = () => {
    console.log('Form values: ', methods.getValues());
    console.log('Form Errors: ', methods.formState.errors);
  };

  if (isPendingForm) return <LoadingRing />;

  return (
    <>
      {!ticketRequestStarted ? (
        <RequestFormSelection
          onSelectionMade={(location: RequestLocation) => {
            setTicketRequestStarted(true);
            setRequestFacility(location);
          }}
        />
      ) : (
        <FormProvider {...methods}>
          <Form
            className="shadow shadow-gray-600 bg-base-200 w-full rounded-lg p-2"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <Tabs
              variant="lifted"
              size="md"
            >
              <RequestDetailsTab
                indexTab={1}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                {...{ dateOfRequestState, dateFinalReportState }}
              />
              <TechnicalDetailsTab
                indexTab={2}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
              <ProjectDetails
                indexTab={3}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                {...{ dateReadyCloseState }}
              />
            </Tabs>
            <TabsFooter
              reset={formReset}
              save={formSave}
              {...{ totalTabs, activeTabState }}
            />
          </Form>
        </FormProvider>
      )}
    </>
  );
};
