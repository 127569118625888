import { Outlet } from 'react-router';
import './App.css';
import Welcome from '@pages/Welcome';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { loginRequest } from '@authConfig';

const App = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  // if (!authState) {
  //   return <LoadingRing />;
  // }

  const login = () =>
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log('LogIn redirect error: ', e);
    });
  return isAuthenticated ? <Outlet /> : <Welcome onClick={login} />;
};

export default App;
