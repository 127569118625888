import { Select } from 'react-daisyui';
import { useMemo, useState } from 'react';
import { useGetApiV1CatalogsDropdownsTypeid } from '@sdk-client/hooks';
import { LoadingRing } from '@components/loaders/LoadingRing';
import { CatalogType, catalogComponentsMap } from './DropDownListData/types';

type TsRequestCatalogsProps = {};

export const TsRequestCatalogs = (props: TsRequestCatalogsProps) => {
  const [value, setValue] = useState<string>(CatalogType.TYPE_OF_REQUEST);
  const { data, isPending, refetch } = useGetApiV1CatalogsDropdownsTypeid(Number(value));

  const catalogComponent = useMemo(() => {
    const Component = catalogComponentsMap[value];
    const onSave = async () => await refetch();
    // if exists a component for the current catalog type, render it with the data
    return Component ? (
      <Component
        data={data ?? []}
        type={value as CatalogType}
        refetch={onSave}
      />
    ) : null;
  }, [data, refetch, value]);

  if (isPending) return <LoadingRing />;

  return (
    <>
      <div className="flex w-full component-preview p-1 items-center justify-center gap-2 font-sans">
        <Select
          value={value}
          onChange={(event) => setValue(event.target.value)}
        >
          <Select.Option
            value={''}
            disabled
          >
            Pick a catalog
          </Select.Option>
          <Select.Option value={CatalogType.TYPE_OF_REQUEST}>Type of Request</Select.Option>
          <Select.Option value={CatalogType.APPLICATION}>Application</Select.Option>
          <Select.Option value={CatalogType.PSL}>PSL</Select.Option>
          <Select.Option value={CatalogType.STATUS}>Status</Select.Option>
        </Select>
      </div>
      {catalogComponent}
    </>
  );
};
