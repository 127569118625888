import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { AUTHORIZATION_SERVICE_BASE_URL, AUTHORIZATION_SERVICE_ACCESS_TOKEN, authorizationSettings } from '@config';

type UserPermissionModel = {
  id: string;
  type: string;
  display: string;
  name: string;
  role_id: string;
  user_id: string;
};

type UserInfoModel = {
  id: string;
  name: string;
  email_address: string;
  lab_id: string;
  country_id: string;
  multiple_regions: string;
  multiple_countries: string;
  halID: string;
};

export type UserPermissionsData = {
  roles: string[];
} & UserInfoModel;

type CommonObject = {
  id: string;
  name: string;
  display: string;
};

const authApi = axios.create({
  baseURL: AUTHORIZATION_SERVICE_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${AUTHORIZATION_SERVICE_ACCESS_TOKEN}`,
  },
});

const getFromApiAsync = async <T extends Object>(requestUri: string): Promise<T | null> => {
  try {
    const { data } = await authApi.get(requestUri);
    if (data && data.objects && data.objects.length > 0) {
      const resp: T = data.objects;
      return resp;
    } else if (data && !data.objects) {
      const resp: T = data;
      return resp;
    }
    return null;
  } catch (error) {
    throw new Error('Error fetching data from API');
  }
};

const getUserByEmailAsync = async (userEmail: string): Promise<UserInfoModel | null> => {
  const requestUri = `/objects/user.json?q[deleted]=false&q[email_address]=${userEmail}`;
  const data = await getFromApiAsync<UserInfoModel[]>(requestUri);
  if (data) {
    return data[0];
  }
  return data;
};

const getUserRoles = async (userId: string): Promise<string[]> => {
  const requestUri = `/objects/user_permission.json?q[deleted]=false&q[user_id]=${userId}`;
  const userPermissions = await getFromApiAsync<UserPermissionModel[]>(requestUri);
  if (userPermissions) {
    const roles = userPermissions.map((userPermission) => userPermission.name);
    return roles;
  }
  return [];
};

const isUserValid = async (user: UserInfoModel): Promise<boolean> => {
  const isUserInCountryOrRegion = authorizationSettings.userFilter.countries.some(
    (allowedCountry) =>
      user.multiple_countries.includes(allowedCountry) || user.multiple_regions.includes(allowedCountry)
  );

  // Country validation
  if (user.country_id) {
    const requestUri = `/objects/country/${user.country_id}`;
    const country = await getFromApiAsync<CommonObject>(requestUri);
    const isValidCountry = country && authorizationSettings.userFilter.countries.includes(country.name);
    if (!isUserInCountryOrRegion && !isValidCountry) {
      return false;
    }
  } else if (!isUserInCountryOrRegion) {
    return false;
  }

  // Lab Validation
  if (!user.lab_id) {
    return false;
  }
  const requestUri = `/objects/lab/${user.lab_id}?q[deleted]=false`;
  const lab = await getFromApiAsync<CommonObject>(requestUri);
  return !!lab && lab.name === authorizationSettings.userFilter.laboratory;
};

const getUserRolesByEmailAsync = async (userEmail: string): Promise<string[]> => {
  const user = await getUserByEmailAsync(userEmail);
  if (!user) {
    return [];
  }
  const isValidUser = await isUserValid(user);
  if (!isValidUser) {
    return [];
  }
  const roles = await getUserRoles(user.id);
  if (!roles) {
    return [];
  }
  return roles;
};

const getLaboratoryBy = async (name: string): Promise<CommonObject | null> => {
  const requestUri = `/objects/lab?q[deleted]=false&q[name]=${name}`;
  const labs = await getFromApiAsync<CommonObject[]>(requestUri);
  if (labs && labs.length > 0) {
    return labs[0];
  }
  return null;
};
const getUsersBy = async (labId: string): Promise<UserInfoModel[]> => {
  const requestUri = `/objects/user.json?q[deleted]=false&q[lab_id]=${labId}`;
  const resp = await getFromApiAsync<UserInfoModel[]>(requestUri);
  if (resp && resp.length > 0) {
    return resp;
  }
  return [];
};

const getAllUsersAsync = async (labName: string): Promise<UserPermissionsData[]> => {
  const lab = await getLaboratoryBy(labName);
  if (!lab) return [];

  const users = await getUsersBy(lab.id);
  if (!users || users.length === 0) return [];

  const validUsersWithRoles = await Promise.all(
    users.map(async (user) => {
      const [isValid, roles] = await Promise.all([isUserValid(user), getUserRoles(user.id)]);

      if (isValid && roles) {
        return { ...user, roles };
      }
      return null;
    })
  );
  return validUsersWithRoles.filter((user): user is UserPermissionsData => user !== null);
};

export const useUserQuery = (userEmail: string) => {
  return useQuery({
    queryKey: ['userEmail', userEmail],
    queryFn: () => getUserRolesByEmailAsync(userEmail),
    retry: false,
    staleTime: Infinity, // Configure the cache to only refresh if the userEmail changes
  });
};

export const useAllUsersQuery = (labName: string) => {
  return useQuery({
    queryKey: ['labName', labName],
    queryFn: () => getAllUsersAsync(labName),
    retry: false,
    staleTime: Infinity,
  });
};
