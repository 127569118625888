import { DebouncedInput } from '@components/form/DebouncedInput';
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  ColumnDef,
  getFilteredRowModel,
  FilterFn,
  ColumnFiltersState,
  Column,
  PaginationState,
  getPaginationRowModel,
} from '@tanstack/react-table';
import { useState } from 'react';
import { Paginator } from '../Paginator';

type ExistingExperimentsTableProps<TData extends object> = {
  data: TData[];
  columns: ColumnDef<TData, any>[];
  globalFilter: string;
  setGlobalFilter: (value: string) => void;
  fuzzyFilterDef: FilterFn<TData>;
};

export const ExistingExperimentsTable = <TModel extends object>({
  data,
  columns,
  globalFilter,
  setGlobalFilter,
  fuzzyFilterDef,
}: ExistingExperimentsTableProps<TModel>): React.ReactElement => {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const table = useReactTable<TModel>({
    data: data,
    columns: columns,
    filterFns: {
      fuzzy: fuzzyFilterDef, //define as a filter function that can be used in column definitions
    },
    state: {
      columnFilters,
      globalFilter,
      pagination,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    globalFilterFn: fuzzyFilterDef, //apply fuzzy filter to the global filter (most common use case for fuzzy filter)
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(), //client side filtering
    getPaginationRowModel: getPaginationRowModel(), //client side pagination
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  });

  return (
    <div>
      <table className="table table-xs border-2 border-separate table-zebra rounded-none">
        <thead className="bg-gray-100">
          {table.getHeaderGroups().map((hg, index) => (
            <tr key={index}>
              {hg.headers.map((header, index) => {
                return (
                  <th
                    key={index}
                    colSpan={header.colSpan}
                  >
                    {header.isPlaceholder ? null : (
                      <>
                        <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                        {header.column.getCanFilter() ? (
                          <div>
                            <Filter column={header.column} />
                          </div>
                        ) : null}
                      </>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell, index) => (
                <td key={`${row.id}-${index}`}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <Paginator table={table} />
    </div>
  );
};

function Filter({ column }: { column: Column<any, unknown> }) {
  const columnFilterValue = column.getFilterValue();

  return (
    <DebouncedInput
      value={(columnFilterValue ?? '') as string}
      onChange={(value) => column.setFilterValue(value)}
      placeholder={`Search...`}
      className="w-20 font-light border-1"
      sizeProp={'xs'}
    />
  );
}
