import { DropDownCatalogViewSch } from '@sdk-client/models';
import { DropDownAdminRelated } from './DropDownAdminRelated';
import { DropDownAdminSimple } from './DropDownAdminSimple';

export enum CatalogType {
  EQUIPMENTS = '1',
  CELL_NUMBERS = '2',
  METALLURGIES = '3',
  ADDITIVE_TYPE = '4',
  ADDITIVE = '5',
  PHYSICAL_STATE = '6',
  PITTING_INDEX_SOURCE = '7',
  LOCATION = '12',
  //Request DropDown Catalog Type
  TYPE_OF_REQUEST = '8',
  APPLICATION = '9',
  PSL = '10',
  STATUS = '11',
}

type CatalogComponentsMap = {
  [key in string]: React.FC<{ data: DropDownCatalogViewSch[]; type: CatalogType; refetch?: () => void }>;
};

type CatalogComponentsNames = {
  [key in string]: string;
};

export const catalogComponentsNames: CatalogComponentsNames = {
  [CatalogType.EQUIPMENTS]: 'Equipment',
  [CatalogType.ADDITIVE_TYPE]: 'Additive Type',
  [CatalogType.ADDITIVE]: 'Additive',
  [CatalogType.PHYSICAL_STATE]: 'Physical State',
  [CatalogType.CELL_NUMBERS]: 'Cell Numbers',
  [CatalogType.METALLURGIES]: 'Metallurgies',
  [CatalogType.PITTING_INDEX_SOURCE]: 'Pitting Index',
  [CatalogType.LOCATION]: 'Locations',
  [CatalogType.TYPE_OF_REQUEST]: 'Type of Request',
  [CatalogType.APPLICATION]: 'Application',
  [CatalogType.PSL]: 'PSL',
  [CatalogType.STATUS]: 'Status',
};

export const catalogComponentsMap: CatalogComponentsMap = {
  [CatalogType.EQUIPMENTS]: DropDownAdminRelated,
  [CatalogType.ADDITIVE_TYPE]: DropDownAdminRelated,
  [CatalogType.ADDITIVE]: DropDownAdminRelated,
  [CatalogType.PHYSICAL_STATE]: DropDownAdminRelated,
  [CatalogType.CELL_NUMBERS]: DropDownAdminSimple,
  [CatalogType.METALLURGIES]: DropDownAdminSimple,
  [CatalogType.PITTING_INDEX_SOURCE]: DropDownAdminSimple,
  [CatalogType.LOCATION]: DropDownAdminSimple,
  [CatalogType.TYPE_OF_REQUEST]: DropDownAdminSimple,
  [CatalogType.APPLICATION]: DropDownAdminSimple,
  [CatalogType.PSL]: DropDownAdminSimple,
  [CatalogType.STATUS]: DropDownAdminSimple,
};

export type DropDownDataProps = {
  data: DropDownCatalogViewSch[];
  type: CatalogType;
  refetch?: () => void;
};

export type DropDownAdminProps = {
  data: DropDownCatalogViewSch[];
  type: CatalogType;
  refetch?: () => void;
};
