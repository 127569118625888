import { Path, RegisterOptions, useFormContext } from 'react-hook-form';
import { ZodObject, ZodRawShape, z } from 'zod';
import { isFieldRequired } from './is-field-required';

export const useFormContextExtended = <TSchema extends ZodRawShape>(schema: ZodObject<TSchema>) => {
  type typeSchema = z.infer<typeof schema>;
  const {
    control,
    register,
    watch,
    formState: { errors },
    setValue,
    unregister,
    handleSubmit,
  } = useFormContext<typeSchema>();

  // Generic function to validate if a field is required
  const extendRegister = (fieldName: string, options?: RegisterOptions) => {
    const required = isFieldRequired(schema, fieldName);
    return {
      is_required: required ? 'true' : '',
      ...register(fieldName as Path<typeSchema>, options),
    };
  };

  return {
    control,
    extendRegister,
    errors,
    setValue,
    register,
    watch,
    unregister,
    handleSubmit,
  };
};
