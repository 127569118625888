const isProduction = process.env.NODE_ENV === 'production';
export const API_URL = isProduction
  ? process.env.REACT_APP_API_URL_PROD || 'notSetUrlProd'
  : process.env.REACT_APP_API_URL_DEV || 'notSetUrlDev';

export const AUTHORIZATION_SERVICE_BASE_URL = process.env.REACT_APP_AUTHORIZATION_SERVICE_BASE_URL || 'notSetUrl';
export const AUTHORIZATION_SERVICE_ACCESS_TOKEN =
  process.env.REACT_APP_AUTHORIZATION_SERVICE_ACCESS_TOKEN || 'notSetToken';

export const authorizationSettings = {
  userFilter: {
    countries: ['Global'],
    laboratory: 'StimChem',
  },
};
