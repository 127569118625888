import { TextBox, valueAsNumberOptional } from '@components/form';
import PaddedDivision from '../padded-division';
import useFormContextExtended from '../useFormContextExtended';
import { schema } from '../../schema';
import { getCurvedSampleNoHoleSurfaceArea } from './surface-area-calculations';
import { useEffect } from 'react';

type CurvedSampleNoHoleProps = {};

const CurvedSampleNoHole = (props: CurvedSampleNoHoleProps) => {
  const { extendRegister, errors, watch, setValue } = useFormContextExtended(schema);
  const width1 = watch('curvedSampleNoHole.width1');
  const width2 = watch('curvedSampleNoHole.width2');
  const length = watch('curvedSampleNoHole.length');
  const thickness = watch('curvedSampleNoHole.thickness');

  useEffect(() => {
    const sa = getCurvedSampleNoHoleSurfaceArea(length, thickness, width1, width2);
    const roundedSa = Number(sa.toFixed(4));
    setValue('curvedSampleNoHole.surfaceArea', roundedSa);
    setValue('surfaceArea', roundedSa);
  }, [width1, width2, length, thickness, setValue]);
  return (
    <PaddedDivision>
      <TextBox
        type="number"
        label="Width 1 (W1), in"
        error={errors.curvedSampleNoHole?.width1?.message}
        {...extendRegister('curvedSampleNoHole.width1', {
          setValueAs: valueAsNumberOptional,
        })}
      />
      <TextBox
        type="number"
        label="Width 2 (W2), in"
        error={errors.curvedSampleNoHole?.width2?.message}
        {...extendRegister('curvedSampleNoHole.width2', {
          setValueAs: valueAsNumberOptional,
        })}
      />
      <TextBox
        type="number"
        label="Length (L), in"
        error={errors.curvedSampleNoHole?.length?.message}
        {...extendRegister('curvedSampleNoHole.length', {
          setValueAs: valueAsNumberOptional,
        })}
      />
      <TextBox
        type="number"
        label="Thickness (T), in"
        error={errors.curvedSampleNoHole?.thickness?.message}
        {...extendRegister('curvedSampleNoHole.thickness', {
          setValueAs: valueAsNumberOptional,
        })}
      />
      <TextBox
        type="number"
        readOnly={true}
        label={
          <span>
            Surface Area, in <sup>2</sup>
          </span>
        }
        error={errors.curvedSampleNoHole?.surfaceArea?.message}
        {...extendRegister('curvedSampleNoHole.surfaceArea', {
          setValueAs: valueAsNumberOptional,
        })}
      />
    </PaddedDivision>
  );
};

export default CurvedSampleNoHole;
