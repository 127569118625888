import { Badge, Button, Dropdown } from 'react-daisyui';
import { avatarDefault } from '@images/avatar';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { loginRequest } from '@authConfig';

const ProfileControl = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  //const [userInfo, setUserInfo] = useState<any>(null);

  // const fetchUser = useCallback(() => {
  //   if (isAuthenticated) {
  //     //oktaAuth.getUser().then(setUserInfo).catch(console.error);
  //   } else {
  //     setUserInfo(null);
  //   }
  // }, [authState, oktaAuth]);

  // useEffect(() => {
  //   fetchUser();
  // }, [fetchUser]);

  //if (!authState) return null;

  const handleAuth = async () => {
    isAuthenticated
      ? instance.logoutRedirect({
          postLogoutRedirectUri: '/',
        })
      : instance.loginRedirect(loginRequest).catch((e) => {
          console.log('LogIn redirect error: ', e);
        });
  };

  //const avatar = authState.isAuthenticated && userInfo?.picture ? userInfo.picture : avatarDefault;

  return (
    <Dropdown end>
      <Button
        tag="label"
        tabIndex={0}
        color="ghost"
        className="avatar"
        shape="circle"
      >
        <div className="w-10 rounded-full">
          <img
            alt=""
            src={avatarDefault}
          />
        </div>
      </Button>
      <Dropdown.Menu className="w-52 menu-sm mt-3 z-[10] p-2">
        {/* <li>
          <a
            href="/"
            className="justify-between"
          >
            Profile
            <Badge>New</Badge>
          </a>
        </li> */}
        {/* <Dropdown.Item>Settings</Dropdown.Item> */}
        <Dropdown.Item onClick={handleAuth}>{isAuthenticated ? 'Logout' : 'Login'}</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileControl;
