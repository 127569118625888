import { XMarkIcon } from '@heroicons/react/24/outline';
import { Button, Modal } from 'react-daisyui';
import { DropDownDataProps } from './types';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { OptionRelatedType } from './components/schema';
import { forwardRef } from 'react';

type ModalAddItemProps = {
  isEdition?: boolean;
  reset: () => void;
  onValid: SubmitHandler<OptionRelatedType>;
  formComponent: React.ReactElement<DropDownDataProps>;
};

export const ModalAddItem = forwardRef<HTMLDialogElement, ModalAddItemProps>(
  ({ isEdition, reset, onValid, formComponent }, ref) => {
    const { handleSubmit } = useFormContext<OptionRelatedType>();
    return (
      <Modal ref={ref}>
        <form method="dialog">
          <Button
            size="sm"
            color="ghost"
            shape="circle"
            className="absolute right-2 top-2"
            onClick={() => reset()}
          >
            <XMarkIcon className="w-5 h-5" />
          </Button>
        </form>
        <Modal.Header className="font-bold">{isEdition ? 'Edit Item' : 'Add Item'}</Modal.Header>
        <Modal.Body>{formComponent}</Modal.Body>
        <Modal.Actions>
          <form method="dialog">
            <Button
              color="secondary"
              size="xs"
              onClick={handleSubmit(onValid, (errors) => console.log('errors', errors))}
            >
              {isEdition ? 'Update Item' : 'Add New Item'}
            </Button>
          </form>
        </Modal.Actions>
      </Modal>
    );
  }
);
