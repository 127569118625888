import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
//import { Suspense, lazy } from 'react';
import {ExistingExperiments} from '@pages/experiments/corrosion/ExistingExperiments';
import { AppLayoutMin } from '@components/layouts';
import { PrivateRoutes } from './components/PrivateRoutes';
import { RequestForm } from '@pages/RequestForm';
import { LoginCallback } from '@okta/okta-react';
import { LoadingRing } from '@components/loaders/LoadingRing';
import { TestMethods } from '@pages/Home';
import { ExistingTickets } from '@pages/ExistingTickets';
import { CorrosionCreate, CorrosionUpdate } from '@pages/experiments/corrosion/Create';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from '@authConfig';
import { MultipleView } from '@pages/experiments/MultipleView';
import { Authorization } from '@lib/Authorization';
import { CorrosionCatalogs } from '@pages/Admin';
import { TsRequestCatalogs } from '@pages/Admin/TsRequestCatalogs';
import { UsersAndRoles } from '@pages/Admin/UsersAndRoles/UsersAndRoles';
import { UpdateRequestForm } from '@pages/RequestForm/UpdateRequestForm';
import { ImportProjectDedatilsData } from '@pages/Admin/ImportData/ImportProjectDedatilsData';

//const Home = lazy(() => import('@pages/Home'));
// const RequestForm = lazy(() => import('@pages/RequestForm'));

export const msalInstance = new PublicClientApplication(msalConfig);

export const AppRouter = createBrowserRouter([
  {
    element: <AppLayoutMin msalInstance={msalInstance} />,
    children: [
      {
        path: '/',
        element: <App />,
        children: [
          {
            element: <PrivateRoutes />,
            children: [
              {
                path: 'import-project-detail-data',
                element: (
                  <Authorization allowedRoles={['Admin']}>
                    <ImportProjectDedatilsData />
                  </Authorization>
                ),
              },
              {
                path: 'users-and-roles',
                element: (
                  <Authorization allowedRoles={['Admin']}>
                    <UsersAndRoles />
                  </Authorization>
                ),
              },
              {
                path: 'corrosion-catalogs',
                element: (
                  <Authorization allowedRoles={['Admin']}>
                    <CorrosionCatalogs />
                  </Authorization>
                ),
              },
              {
                path: 'request-catalogs',
                element: (
                  <Authorization allowedRoles={['Admin']}>
                    <TsRequestCatalogs />
                  </Authorization>
                ),
              },
              {
                path: 'test-methods',
                element: (
                  <Authorization allowedRoles={['Admin', 'Scientist/Technician', 'Product Mgr/BD']}>
                    <TestMethods />
                  </Authorization>
                ),
              },
              {
                path: 'ticketing',
                children: [
                  {
                    path: 'request',
                    element: (
                      <Authorization allowedRoles={['Admin', 'Scientist/Technician', 'Operations', 'Product Mgr/BD']}>
                        <RequestForm />
                      </Authorization>
                    ),
                  },
                  {
                    path: 'request/update/:id',
                    element: (
                      <Authorization allowedRoles={['Admin', 'Scientist/Technician', 'Operations', 'Product Mgr/BD']}>
                        <UpdateRequestForm />
                      </Authorization>
                    ),
                  },
                  {
                    path: 'tickets',
                    element: (
                      <Authorization allowedRoles={['Admin', 'Scientist/Technician', 'Operations', 'Product Mgr/BD']}>
                        <ExistingTickets />
                      </Authorization>
                    ),
                  },
                ],
              },
              {
                path: 'experiments/corrosion/create',
                element: (
                  <Authorization allowedRoles={['Admin', 'Scientist/Technician']}>
                    <CorrosionCreate />
                  </Authorization>
                ),
              },
              {
                path: 'experiments/corrosion/update/:id',
                element: (
                  <Authorization allowedRoles={['Admin', 'Scientist/Technician']}>
                    <CorrosionUpdate />
                  </Authorization>
                ),
              },
              {
                path: 'experiments/:experimentType',
                element: (
                  <Authorization allowedRoles={['Admin', 'Scientist/Technician', 'Product Mgr/BD']}>
                    <ExistingExperiments />
                  </Authorization>
                ),
              },
              {
                path: 'experiments/multipleview',
                element: (
                  <Authorization allowedRoles={['Admin', 'Scientist/Technician', 'Product Mgr/BD']}>
                    <MultipleView />
                  </Authorization>
                ),
              },
            ],
          },
        ],
      },
      {
        path: 'login/callback',
        element: <LoginCallback loadingElement={<LoadingRing />} />,
      },
    ],
  },
]);
/*
<Suspense
  fallback={
    <div className="w-auto h-80 flex items-center justify-center">
      <Loading size="lg" variant="ring" />
    </div>
  }
>*/
