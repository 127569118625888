import { Tabs } from 'react-daisyui';
import { TabProps } from './types';
import { TextArea, TextBox } from '@components/form';
import TabContent from './tab-content';
import { CONTENT_TAB_STYLE } from './constants';
import useFormContextExtended from './useFormContextExtended';

const TechnicalDetailsTab = ({ indexTab, activeTab, setActiveTab }: TabProps) => {
  const { extendRegister, errors } = useFormContextExtended();

  return (
    <Tabs.RadioTab
      checked={activeTab === indexTab}
      onChange={() => setActiveTab(indexTab)}
      name="section"
      label="Technical Details"
      contentClassName={CONTENT_TAB_STYLE}
    >
      <TabContent>
        <div className="px-1 w-72">
          <TextBox
            label="Well Name"
            {...extendRegister('wellName')}
          />
          <TextBox
            label="Depth (ft)"
            {...extendRegister('depth')}
          />
          <TextBox
            label="Bottom Hole Temperature (°F)"
            {...extendRegister('bottomHoleTemperature')}
          />
          <TextBox
            label="Bottom Hole Pressure (psi)"
            {...extendRegister('bottomHolePressure')}
          />
        </div>

        <div className="px-1 flex flex-auto w-0">
          <TextArea
            label="Type of Data Requested"
            className="w-[30vw] h-[283px] rounded-badge"
            error={errors.typeOfDataRequested?.message}
            {...extendRegister('typeOfDataRequested')}
          />
          <TextArea
            label="Fluid Details"
            className="w-[30vw] h-[283px] rounded-badge"
            {...extendRegister('fluidDetails')}
          />
        </div>
      </TabContent>
    </Tabs.RadioTab>
  );
};

export default TechnicalDetailsTab;
