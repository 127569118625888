import { TextBox, valueAsNumberOptional } from '@components/form';
import PaddedDivision from '../padded-division';
import useFormContextExtended from '../useFormContextExtended';
import { schema } from '../../schema';

type CustomShapeProps = {};

export const CustomShape = (props: CustomShapeProps) => {
  const { extendRegister, watch, setValue, errors } = useFormContextExtended(schema);

  const sa = watch('customShape.surfaceArea');
  if (sa) {
    const roundedSa = Number(sa.toFixed(4));
    setValue('surfaceArea', roundedSa);
  }

  return (
    <PaddedDivision>
      <TextBox
        type="number"
        label={
          <span>
            Surface Area, in <sup>2</sup>
          </span>
        }
        error={errors.customShape?.surfaceArea?.message}
        {...extendRegister('customShape.surfaceArea', {
          setValueAs: valueAsNumberOptional,
        })}
      />
    </PaddedDivision>
  );
};
