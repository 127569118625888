import { Table } from '@tanstack/react-table';
import { useMemo } from 'react';
import { Button, Select } from 'react-daisyui';

type PaginatorProps<TModel extends object> = {
  table: Table<TModel>;
};

export const Paginator = <TModel extends object>({ table }: PaginatorProps<TModel>) => {
  const paginationButtons = useMemo(() => {
    const buttons = [];
    for (let i = 0; i < table.getPageCount(); i++) {
      buttons.push(
        <Button
          size="sm"
          key={i}
          onClick={() => table.setPageIndex(i)}
          active={table.getState().pagination.pageIndex === i}
        >
          {i + 1}
        </Button>
      );
    }
    return buttons;
  }, [table, table.getState().pagination]);
  return (
    <div className="flex items-center justify-between mt-4">
      <div className="flex items-center space-x-2">
        <span>Records per page:</span>
        <Select
          id="records-per-page"
          name="records-per-page"
          size="sm"
          value={table.getState().pagination.pageSize}
          onChange={(e) => table.setPageSize(Number(e.target.value))}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <Select.Option
              key={pageSize}
              value={pageSize}
            >
              {pageSize}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div className="space-x-1">
        <Button
          size="sm"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          Prev
        </Button>
        {paginationButtons}
        <Button
          size="sm"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          Next
        </Button>
      </div>
      <div>
        Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
      </div>
    </div>
  );
};
