import { BasicTable } from '@components/tables/BasicTable/BasicTable';
import { createColumnsRelated } from './columns';
import { Button } from 'react-daisyui';
import { PlusIcon } from '@heroicons/react/24/outline';
import { AddNewItemFormRelated } from './components/AddNewItemFormRelated';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { OptionRelatedType, optionRelatedSchema } from './components/schema';
import { DropDownCatalogViewSch } from '@sdk-client/models';
import { LoadingRing } from '@components/loaders/LoadingRing';
import { DropDownDataProps, catalogComponentsNames } from './types';
import { ModalAddItem } from './ModalAddItem';
import {
  ConfirmationAlert,
  ConfirmationAlertType,
  buildConfirmationAlertTextContent,
} from '@components/alerts/ConfirmationAlert';
import { useDropDownAdmin } from './hooks/useDropDownAdmin';

export const DropDownAdminRelated = ({ data, type, refetch }: DropDownDataProps) => {
  const {
    itemSelected,
    setItemSelected,
    methods,
    modalRef,
    modalConfirmationRef,
    resetFormModal,
    handleShow,
    mutateCreateAsync,
    isCreatePending,
    mutateUpdateAsync,
    isUpdatePending,
    mutateDeleteAsync,
    isDeletePending,
  } = useDropDownAdmin(optionRelatedSchema, type, refetch);

  const onClickEdit = (itemId: string) => {
    const item = data.find((item) => item.id === itemId);
    if (item) {
      setItemSelected(item);
      handleShow();
    }
  };
  const confirmationCallback = async (resp: boolean) => {
    if (resp) {
      await mutateDeleteAsync();
    } else {
      setItemSelected(null);
    }
  };

  const onClickDelete = async (itemId: string) => {
    console.log('Delete: ', itemId);
    const item = data.find((item) => item.id === itemId);
    if (item) {
      setItemSelected(item);
      modalConfirmationRef.current?.showModal();
    }
  };

  const columns = createColumnsRelated({ type, onClickEdit, onClickDelete });
  const onValid: SubmitHandler<OptionRelatedType> = async (data) => {
    console.log('onSubmit: ', data);
    if (data?.id) {
      await mutateUpdateAsync({
        value: data.value,
        text: data.text,
        relatedKeys: data.relatedKeys,
      });
    } else {
      await mutateCreateAsync(data);
    }
    return;
  };
  const buildItemTextDetails = (item: DropDownCatalogViewSch | null) => {
    if (!item) return '';
    const itemDetails = `Value: ${item.value}, Text: ${item.text}`;
    const maxLength = 40;
    if (itemDetails.length > maxLength) {
      return `${itemDetails.substring(0, maxLength)}...`;
    }
    return itemDetails;
  };

  if (isCreatePending || isUpdatePending || isDeletePending) return <LoadingRing />;

  return (
    <FormProvider {...methods}>
      <div className="flex gap-2">
        <Button
          startIcon={<PlusIcon className="h-6 w-6" />}
          color="primary"
          size="sm"
          onClick={handleShow}
        >
          Add Item
        </Button>
        <ConfirmationAlert
          textContent={buildConfirmationAlertTextContent(
            catalogComponentsNames[type],
            ConfirmationAlertType.Delete,
            buildItemTextDetails(itemSelected)
          )}
          ref={modalConfirmationRef}
          callback={confirmationCallback}
        />
      </div>
      <BasicTable
        data={data}
        columns={columns}
      />
      <ModalAddItem
        isEdition={!!itemSelected}
        ref={modalRef}
        onValid={onValid}
        reset={resetFormModal}
        formComponent={
          <AddNewItemFormRelated
            typeId={type}
            initialData={itemSelected}
          />
        }
      />
    </FormProvider>
  );
};
