import { Authorization } from '@lib/Authorization';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { Button, Menu } from 'react-daisyui';
import { Link } from 'react-router-dom';

const Submenu = () => {
  const [menuVisible, setMenuVisibility] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleLinkClicked = () => {
    const elem = document.activeElement as HTMLElement;
    if (elem) {
      elem?.blur();
      setMenuVisibility(false);
      //setTimeout(() => setMenuVisibility(false), 200); //
    }
  };

  const handleMenuClicked = () => {
    setMenuVisibility(!menuVisible);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setMenuVisibility(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      className="flex flex-col"
      ref={menuRef}
    >
      <Button
        tag="label"
        color="ghost"
        shape="circle"
        tabIndex={0}
        onClick={handleMenuClicked}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 6h16M4 12h16M4 18h7"
          />
        </svg>
      </Button>
      <div>
        <Menu
          className={clsx('absolute p-2 shadow bg-base-100 rounded-box menu-sm w-52 mt-3 z-50', {
            visible: menuVisible,
            invisible: !menuVisible,
          })}
        >
          <Authorization allowedRoles={['Admin']}>
            <Menu.Item>
              <Menu.Details label="Admin">
                <Menu.Item>
                  <Link
                    to="users-and-roles"
                    onClick={handleLinkClicked}
                  >
                    Users
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link
                    to="request-catalogs"
                    onClick={handleLinkClicked}
                  >
                    TS Request Catalogs
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link
                    to="corrosion-catalogs"
                    onClick={handleLinkClicked}
                  >
                    Corrosion Catalogs
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link
                    to="import-project-detail-data"
                    onClick={handleLinkClicked}
                  >
                    Import Data
                  </Link>
                </Menu.Item>
              </Menu.Details>
            </Menu.Item>
          </Authorization>
          <Authorization allowedRoles={['Admin', 'Scientist/Technician', 'Operations', 'Product Mgr/BD']}>
            <Menu.Item>
              <Menu.Details label="Technical Services">
                <Menu.Item>
                  <Link
                    to="ticketing/request"
                    onClick={handleLinkClicked}
                  >
                    Request
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link
                    to="ticketing/tickets"
                    onClick={handleLinkClicked}
                  >
                    Tickets
                  </Link>
                </Menu.Item>
              </Menu.Details>
            </Menu.Item>
          </Authorization>
          <Authorization allowedRoles={['Admin', 'Scientist/Technician', 'Product Mgr/BD']}>
            <Menu.Item>
              <Menu.Details label="Experiments">
                <Menu.Item>
                  <Link
                    to="test-methods"
                    onClick={handleLinkClicked}
                  >
                    Test Methods
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link
                    to="experiments/corrosion"
                    onClick={handleLinkClicked}
                  >
                    Corrosion
                  </Link>
                </Menu.Item>
              </Menu.Details>
            </Menu.Item>
          </Authorization>
        </Menu>
      </div>
    </div>
  );
};

export default Submenu;

/*
  <Dropdown.Menu >
  <Dropdown.Item anchor={false}>

  </Dropdown.Item>
  <Dropdown.Item anchor={false}>
    
  </Dropdown.Item>
  <Dropdown.Item anchor={false}>

  </Dropdown.Item>
</Dropdown.Menu>
</Dropdown>*/
