import client from "../../lib/api/client";
import { useMutation } from "@tanstack/react-query";
import type { PostApiV1TechnicalServicesRequestMutationRequest, PostApiV1TechnicalServicesRequestMutationResponse, PostApiV1TechnicalServicesRequest400, PostApiV1TechnicalServicesRequest401, PostApiV1TechnicalServicesRequest403 } from "../models/PostApiV1TechnicalServicesRequest";
import type { UseMutationOptions } from "@tanstack/react-query";

type PostApiV1TechnicalServicesRequestClient = typeof client<PostApiV1TechnicalServicesRequestMutationResponse, PostApiV1TechnicalServicesRequest400 | PostApiV1TechnicalServicesRequest401 | PostApiV1TechnicalServicesRequest403, PostApiV1TechnicalServicesRequestMutationRequest>;
type PostApiV1TechnicalServicesRequest = {
    data: PostApiV1TechnicalServicesRequestMutationResponse;
    error: PostApiV1TechnicalServicesRequest400 | PostApiV1TechnicalServicesRequest401 | PostApiV1TechnicalServicesRequest403;
    request: PostApiV1TechnicalServicesRequestMutationRequest;
    pathParams: never;
    queryParams: never;
    headerParams: never;
    response: PostApiV1TechnicalServicesRequestMutationResponse;
    client: {
        parameters: Partial<Parameters<PostApiV1TechnicalServicesRequestClient>[0]>;
        return: Awaited<ReturnType<PostApiV1TechnicalServicesRequestClient>>;
    };
};
/**
     * @link /api/v1/technical-services/request */
export function usePostApiV1TechnicalServicesRequest(options: {
    mutation?: UseMutationOptions<PostApiV1TechnicalServicesRequest["response"], PostApiV1TechnicalServicesRequest["error"], PostApiV1TechnicalServicesRequest["request"]>;
    client?: PostApiV1TechnicalServicesRequest["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    return useMutation({
        mutationFn: async (data) => {
            const res = await client<PostApiV1TechnicalServicesRequest["data"], PostApiV1TechnicalServicesRequest["error"], PostApiV1TechnicalServicesRequest["request"]>({
                method: "post",
                url: `/api/v1/technical-services/request`,
                data,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions
    });
}