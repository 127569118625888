import { forwardRef } from 'react';
import { AsyncPaginate, LoadOptions } from 'react-select-async-paginate';
import { GroupBase } from 'react-select';
import { API_URL } from '@config';
import axiosClient from '@lib/api/client';
import { ProjectDetailsReportResponseDtoPaginatedListDto } from '@sdk-client/models';

export interface SelectAutocompleteOption {
  value: string;
  label: string;
}
interface Additional {
  page: number;
}

type SelectAutocompleteProps = {
  label?: string;
  error?: string;
  selectedOption: SelectAutocompleteOption | null;
  onChange: (newValue: SelectAutocompleteOption | null) => void;
  is_required?: string;
};

export const SelectAutocomplete = forwardRef<HTMLInputElement, SelectAutocompleteProps>(
  ({ label, error, selectedOption, onChange, is_required, ...rest }, ref) => {
    const loadOptions: LoadOptions<SelectAutocompleteOption, GroupBase<SelectAutocompleteOption>, Additional> = async (
      search: any,
      loadedOptions: any,
      { page }: any
    ) => {
      const response = await axiosClient({
        url: `/api/v1/reports/project-details`,
        baseURL: API_URL,
        method: 'get',
        params: {
          PageIndex: page,
          PageSize: 20,
          SearchTerm: search,
        },
      });
      const data = response.data as ProjectDetailsReportResponseDtoPaginatedListDto;

      const options =
        data.items?.map(
          (item) => ({ value: item.projectDefinition, label: item.projectName } as SelectAutocompleteOption)
        ) || [];
      return {
        options: options,
        hasMore: data.hasNextPage || false,
        additional: {
          page: page + 1,
        },
      };
    };

    return (
      <div className="flex w-full component-preview pb-3 items-center justify-center gap-2 font-sans">
        <div className="form-control w-full max-w-xs">
          <label className="label">
            <span className="label-text">
              {label}
              {is_required && <span className="label-text text-red-400"> * </span>}
            </span>
            {error && <span className="label-text-alt text-red-400">{error}</span>}
          </label>

          <AsyncPaginate
            {...rest}
            className="z-10"
            value={selectedOption}
            loadOptions={loadOptions}
            onChange={onChange}
            additional={{
              page: 1,
            }}
            debounceTimeout={300}
          />
        </div>
      </div>
    );
  }
);
