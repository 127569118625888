import { TextBox, valueAsNumberOptional } from '@components/form';
import PaddedDivision from '../padded-division';
import useFormContextExtended from '../useFormContextExtended';
import { schema } from '../../schema';
import { useEffect } from 'react';
import { getCylinderSurfaceArea } from './surface-area-calculations';

type CylinderProps = {};

const Cylinder = (props: CylinderProps) => {
  const { extendRegister, watch, setValue, errors } = useFormContextExtended(schema);

  const diameter = watch('cylinder.diameter');
  const length = watch('cylinder.length');

  useEffect(() => {
    const sa = getCylinderSurfaceArea(diameter, length);
    const roundedSa = Number(sa.toFixed(4));
    setValue('cylinder.surfaceArea', roundedSa);
    setValue('surfaceArea', roundedSa);
  }, [diameter, length, setValue]);

  return (
    <PaddedDivision>
      <TextBox
        type="number"
        label="Diameter (D), in"
        error={errors.cylinder?.diameter?.message}
        {...extendRegister('cylinder.diameter', {
          setValueAs: valueAsNumberOptional,
        })}
      />
      <TextBox
        type="number"
        label="Length (L), in"
        error={errors.cylinder?.length?.message}
        {...extendRegister('cylinder.length', {
          setValueAs: valueAsNumberOptional,
        })}
      />
      <TextBox
        type="number"
        readOnly={true}
        label={
          <span>
            Surface Area, in <sup>2</sup>
          </span>
        }
        error={errors.cylinder?.surfaceArea?.message}
        {...extendRegister('cylinder.surfaceArea', {
          setValueAs: valueAsNumberOptional,
        })}
      />
    </PaddedDivision>
  );
};

export default Cylinder;
