import { TextBox, valueAsNumberOptional } from '@components/form';
import { Collapse } from 'react-daisyui';
import RecipeTable from './recipe-table/RecipeTable';
import useFormContextExtended from './useFormContextExtended';
import { schema } from '../schema';
import { CollapseCustom } from './collapse-custom';

type AcidBlendRecipeProps = {
  openDefault?: boolean;
};

const AcidBlendRecipe = ({ openDefault = false }: AcidBlendRecipeProps) => {
  const { extendRegister, errors } = useFormContextExtended(schema);
  return (
    <CollapseCustom openDefault={openDefault}>
      <Collapse.Title className="text-xl font-medium">Acid Blend Recipe</Collapse.Title>
      <Collapse.Content>
        <div className="flex flex-row gap-5">
          <span>
            <TextBox
              label="Sample Volume (mL)"
              type="number"
              error={errors.sampleVolumeMl?.message}
              {...extendRegister('sampleVolumeMl', {
                setValueAs: valueAsNumberOptional,
              })}
            />
          </span>
          <span>
            <TextBox
              label="Desired Acid Conc (%)"
              type="number"
              error={errors.desiredAcidConcentration?.message}
              {...extendRegister('desiredAcidConcentration', {
                setValueAs: valueAsNumberOptional,
              })}
            />
          </span>
        </div>
        <RecipeTable />
      </Collapse.Content>
    </CollapseCustom>
  );
};

export default AcidBlendRecipe;
