import { useEffect, useMemo, useState } from 'react';
import { Form, Tabs } from 'react-daisyui';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormInput, schema, TypeDualOptions } from './schema';
import { DateValueType } from 'react-tailwindcss-datepicker';
import RequestDetailsTab from './components/request-details-tab';
import TechnicalDetailsTab from './components/technical-details-tab';
import ProjectDetails from './components/project-details';
import TabsFooter from './components/tabs-footer';
import {
  useGetApiV1TechnicalServicesTicketrequestid,
  usePutApiV1TechnicalServicesTicketrequestid,
} from '@sdk-client/hooks';
import { LoadingRing } from '@components/loaders/LoadingRing';
import { CreateTicketRequestSch } from '@sdk-client/models';
import { useNavigate, useParams } from 'react-router';
import { convertStringToDate } from '@utils/dateUtils';

type UpdateRequestFormProps = {
  ticketRequestId?: string;
};
export const UpdateRequestForm = ({ ticketRequestId = '' }: UpdateRequestFormProps) => {
  const navigate = useNavigate();
  const [isSaved, setIsSaved] = useState(false);
  const { id = ticketRequestId } = useParams();

  const { data: ticketData, isPending: isTicketDataPending } = useGetApiV1TechnicalServicesTicketrequestid(id);
  const { mutateAsync: mutatePutAsync, isPending: isPutPending } = usePutApiV1TechnicalServicesTicketrequestid(id, {
    mutation: {
      onSuccess: () => {
        if (!isSaved) {
          navigate('/ticketing/tickets');
        }
      },
    },
  });

  const activeTabState = useState<number>(1);

  const [activeTab, setActiveTab] = activeTabState;
  const dateOfRequestState = useState<DateValueType>({
    startDate: convertStringToDate(ticketData?.dateOfRequest ?? ''),
    endDate: convertStringToDate(ticketData?.dateOfRequest ?? ''),
  });
  const dateFinalReportState = useState<DateValueType>({
    startDate: convertStringToDate(ticketData?.dateFinalReportRequested ?? ''),
    endDate: convertStringToDate(ticketData?.dateFinalReportRequested ?? ''),
  });
  const dateReadyCloseState = useState<DateValueType>({
    startDate: convertStringToDate(ticketData?.readyForCloseDate ?? ''),
    endDate: convertStringToDate(ticketData?.readyForCloseDate ?? ''),
  });
  const [, setDateOfRequestState] = dateOfRequestState;
  const [, setDateFinalReportState] = dateFinalReportState;
  const [, setDateReadyCloseState] = dateReadyCloseState;

  const totalTabs = 3;

  const methods = useForm<FormInput>({
    mode: 'onChange',
    resolver: zodResolver(schema),
    values: {
      name: ticketData?.name ?? '',
      workLocation: ticketData?.workLocation ?? '',
      phone: ticketData?.phone ?? '',
      email: ticketData?.email ?? '',
      customer: ticketData?.customer ?? '',
      isRequestForTenderBid: ticketData?.isRequestForTenderBid ? TypeDualOptions.Yes : TypeDualOptions.No,
      formationName: ticketData?.formationName ?? '',
      fieldName: ticketData?.fieldName ?? '',
      typeOfRequest: ticketData?.typeOfRequest ?? '',
      application: ticketData?.application ?? '',
      psl: ticketData?.psl ?? '',
      projectRevenue: ticketData?.projectRevenue ?? '',
      isSampleCheckedForH2s: ticketData?.isSampleCheckedForH2s ?? false,
      h2sContent: ticketData?.h2sContent ?? '',
      isShippingSamples: ticketData?.isShippingSamples ? TypeDualOptions.Yes : TypeDualOptions.No,
      costCenter: ticketData?.costCenter ?? '',
      wellName: ticketData?.wellName ?? '',
      depth: ticketData?.depth ?? '',
      bottomHoleTemperature: ticketData?.bottomHoleTemperature ?? '',
      bottomHolePressure: ticketData?.bottomHolePressure ?? '',
      typeOfDataRequested: ticketData?.typeOfDataRequested ?? '',
      fluidDetails: ticketData?.fluidDetails ?? '',
      projectNumber: ticketData?.projectNumber ?? '',
      projectTitle: ticketData?.projectTitle ?? '',
      projectType: ticketData?.projectType ?? '',
      networkCode: ticketData?.networkCode ?? '',
      laborCode: ticketData?.laborCode ?? '',
      materialsCode: ticketData?.materialsCode ?? '',
      servicesCode: ticketData?.servicesCode ?? '',
      travelCode: ticketData?.travelCode ?? '',
      projectLead: ticketData?.projectLead ?? '',
      projectLeadEmail: ticketData?.projectLeadEmail ?? '',
      shippingAddress: ticketData?.shippingAddress ?? '',
      projectResources: ticketData?.projectResources ?? '',
      status: ticketData?.status ?? '',
      dateOfRequest: ticketData?.dateOfRequest ?? null,
      dateFinalReportRequested: ticketData?.dateFinalReportRequested ?? '',
      readyForCloseDate: ticketData?.readyForCloseDate ?? null,
    },
  });

  const onSubmit: SubmitHandler<FormInput> = async (data, e) => {
    const nativeEvent = e?.nativeEvent as SubmitEvent;
    const submitter = nativeEvent.submitter;
    setIsSaved(submitter?.id === 'save');
    const body: CreateTicketRequestSch = {
      ...data,
      isRequestForTenderBid: data.isRequestForTenderBid === TypeDualOptions.Yes,
      isShippingSamples: data.isShippingSamples === TypeDualOptions.Yes,
      requestThroughId: 0,
    };
    await mutatePutAsync(body);
  };
  const formReset = () => {
    methods.reset();
    setActiveTab(1);
    navigate('/ticketing/tickets');
  };

  const formSave = () => {
    console.log('Form values: ', methods.getValues());
    console.log('Form Errors: ', methods.formState.errors);
  };

  const dates = useMemo(() => {
    return {
      dateOfRequest: convertStringToDate(ticketData?.dateOfRequest ?? ''),
      dateFinalReport: convertStringToDate(ticketData?.dateFinalReportRequested ?? ''),
      readyForClose: convertStringToDate(ticketData?.readyForCloseDate ?? ''),
    };
  }, [ticketData?.dateFinalReportRequested, ticketData?.dateOfRequest, ticketData?.readyForCloseDate]);

  useEffect(() => {
    setDateOfRequestState({
      startDate: dates.dateOfRequest,
      endDate: dates.dateOfRequest,
    });
    setDateFinalReportState({
      startDate: dates.dateFinalReport,
      endDate: dates.dateFinalReport,
    });
    setDateReadyCloseState({
      startDate: dates.readyForClose,
      endDate: dates.readyForClose,
    });
  }, [dates, setDateFinalReportState, setDateOfRequestState, setDateReadyCloseState]);

  if (isPutPending || isTicketDataPending) return <LoadingRing />;

  return (
    <>
      <FormProvider {...methods}>
        <Form
          className="shadow shadow-gray-600 bg-base-200 w-full rounded-lg p-2"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <Tabs
            variant="lifted"
            size="md"
          >
            <RequestDetailsTab
              indexTab={1}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              {...{ dateOfRequestState, dateFinalReportState }}
            />
            <TechnicalDetailsTab
              indexTab={2}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            <ProjectDetails
              indexTab={3}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              {...{ dateReadyCloseState }}
            />
          </Tabs>
          <TabsFooter
            reset={formReset}
            save={formSave}
            {...{ totalTabs, activeTabState }}
          />
        </Form>
      </FormProvider>
    </>
  );
};
