import { useFormContext, useWatch } from 'react-hook-form';
import { Input } from 'react-daisyui';
import { CellProps } from '@components/tables/EditableTable';
import { useTableConfig } from '@components/tables/EditableTable/ConfigProvider';
import { useEffect } from 'react';
import { getStockAcidConc } from './hcl-calculation';
import { additiveDegBeMap } from './constants';
import { Row } from '@components/tables/types';

export const ConcentrationCell = <T extends Row>({ row: { index, original }, column }: CellProps<T>) => {
  const { rowsPropertyName } = useTableConfig();
  const { register, setValue } = useFormContext();

  const namePrefix = `${rowsPropertyName}.${index}`;

  const sampleVolumeValue = useWatch({ name: `sampleVolumeMl`, defaultValue: '' });
  const desiredAcidConcentrationValue = useWatch({ name: `desiredAcidConcentration`, defaultValue: '' });
  const additiveTypeValue = useWatch({ name: `${namePrefix}.additiveType`, defaultValue: '' });
  const additiveValue = useWatch({ name: `${namePrefix}.additive`, defaultValue: '' });

  const calculateConcentration = () => {
    if (additiveTypeValue === 'acid' && Object.keys(additiveDegBeMap).includes(additiveValue)) {
      const hciConc = additiveDegBeMap[additiveValue];
      const concentration = getStockAcidConc(sampleVolumeValue, hciConc, desiredAcidConcentrationValue);
      const validConcentration = Number.isNaN(concentration) ? 0 : concentration.toFixed(0);

      setValue(`${namePrefix}.${column.id}`, validConcentration);
    }
  };

  useEffect(calculateConcentration, [
    additiveTypeValue,
    additiveValue,
    column.id,
    desiredAcidConcentrationValue,
    namePrefix,
    sampleVolumeValue,
    setValue,
  ]);

  return (
    <Input
      className="input input-sm input-bordered w-full"
      disabled={additiveValue === 'water' || additiveTypeValue === 'acid'}
      key={original.id}
      {...register(`${namePrefix}.${column.id}`)}
    />
  );
};
