import { zStringRequired } from '@components/zod/rules';
import * as z from 'zod';

export const optionSchema = z.object({
  id: z.string().optional(),
  value: zStringRequired,
  text: zStringRequired,
});

export const optionRelatedSchema = optionSchema.extend({
  relatedObject: z.string().optional(),
  relatedKeys: z.array(z.string()),
  relatedKeysStr: zStringRequired,
});

export type OptionRelatedType = z.infer<typeof optionRelatedSchema>;
export type OptionType = z.infer<typeof optionSchema>;
