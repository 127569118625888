import { TextBox, valueAsNumberOptional } from '@components/form';
import PaddedDivision from '../padded-division';
import useFormContextExtended from '../useFormContextExtended';
import { schema } from '../../schema';
import { getCurvedSampleWithHoleSurfaceArea } from './surface-area-calculations';
import { useEffect } from 'react';

type CurvedSampleWithHoleProps = {};

const CurvedSampleWithHole = (props: CurvedSampleWithHoleProps) => {
  const { extendRegister, errors, watch, setValue } = useFormContextExtended(schema);
  const width1 = watch('curvedSampleWithHole.width1');
  const width2 = watch('curvedSampleWithHole.width2');
  const length = watch('curvedSampleWithHole.length');
  const thickness = watch('curvedSampleWithHole.thickness');
  const holeDiameter = watch('curvedSampleWithHole.holeDiameter');

  useEffect(() => {
    const sa = getCurvedSampleWithHoleSurfaceArea(length, thickness, width1, width2, holeDiameter);
    const roundedSa = Number(sa.toFixed(4));
    setValue('curvedSampleWithHole.surfaceArea', roundedSa);
    setValue('surfaceArea', roundedSa);
  }, [width1, width2, length, thickness, holeDiameter, setValue]);

  return (
    <PaddedDivision>
      <TextBox
        type="number"
        label="Width 1 (W1), in"
        error={errors.curvedSampleWithHole?.width1?.message}
        {...extendRegister('curvedSampleWithHole.width1', {
          setValueAs: valueAsNumberOptional,
        })}
      />
      <TextBox
        type="number"
        label="Width 2 (W2), in"
        error={errors.curvedSampleWithHole?.width2?.message}
        {...extendRegister('curvedSampleWithHole.width2', {
          setValueAs: valueAsNumberOptional,
        })}
      />
      <TextBox
        type="number"
        label="Length (L), in"
        error={errors.curvedSampleWithHole?.length?.message}
        {...extendRegister('curvedSampleWithHole.length', {
          setValueAs: valueAsNumberOptional,
        })}
      />
      <TextBox
        type="number"
        label="Thickness (T), in"
        error={errors.curvedSampleWithHole?.thickness?.message}
        {...extendRegister('curvedSampleWithHole.thickness', {
          setValueAs: valueAsNumberOptional,
        })}
      />
      <TextBox
        type="number"
        label="Hole Diameter (D), in"
        error={errors.curvedSampleWithHole?.holeDiameter?.message}
        {...extendRegister('curvedSampleWithHole.holeDiameter', {
          setValueAs: valueAsNumberOptional,
        })}
      />
      <TextBox
        type="number"
        readOnly={true}
        label={
          <span>
            Surface Area, in <sup>2</sup>
          </span>
        }
        error={errors.curvedSampleWithHole?.surfaceArea?.message}
        {...extendRegister('curvedSampleWithHole.surfaceArea', {
          setValueAs: valueAsNumberOptional,
        })}
      />
    </PaddedDivision>
  );
};

export default CurvedSampleWithHole;
