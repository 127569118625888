import { MinusCircleIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { Row } from '@tanstack/react-table';
import { Button } from 'react-daisyui';

type RowBase = {
  id: string;
};

type ActionEditDeleteCellProps<T extends RowBase> = {
  row: Row<T>;
  onClickEdit: (itemId: string) => void;
  onClickDelete: (itemId: string) => void;
};

export const ActionEditDeleteCell = <T extends RowBase>({
  row,
  onClickEdit,
  onClickDelete,
}: ActionEditDeleteCellProps<T>) => {
  return (
    <div className="w-full text-left">
      <Button
        size="xs"
        onClick={() => onClickEdit(row.original.id)}
        endIcon={<PencilSquareIcon className="h-5 w-5" />}
        title="Edit Item"
      ></Button>
      <Button
        size="xs"
        onClick={() => onClickDelete(row.original.id)}
        endIcon={<MinusCircleIcon className="h-5 w-5" />}
        title="Delete Item"
      ></Button>
    </div>
  );
};
