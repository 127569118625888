import client from "../../lib/api/client";
import { useQuery, queryOptions } from "@tanstack/react-query";
import type { GetApiV1ExperimentsCorrosionExperimentidQueryResponse, GetApiV1ExperimentsCorrosionExperimentidPathParams, GetApiV1ExperimentsCorrosionExperimentid400, GetApiV1ExperimentsCorrosionExperimentid401, GetApiV1ExperimentsCorrosionExperimentid403, GetApiV1ExperimentsCorrosionExperimentid404 } from "../models/GetApiV1ExperimentsCorrosionExperimentid";
import type { QueryObserverOptions, UseQueryResult, QueryKey } from "@tanstack/react-query";

type GetApiV1ExperimentsCorrosionExperimentidClient = typeof client<GetApiV1ExperimentsCorrosionExperimentidQueryResponse, GetApiV1ExperimentsCorrosionExperimentid400 | GetApiV1ExperimentsCorrosionExperimentid401 | GetApiV1ExperimentsCorrosionExperimentid403 | GetApiV1ExperimentsCorrosionExperimentid404, never>;
type GetApiV1ExperimentsCorrosionExperimentid = {
    data: GetApiV1ExperimentsCorrosionExperimentidQueryResponse;
    error: GetApiV1ExperimentsCorrosionExperimentid400 | GetApiV1ExperimentsCorrosionExperimentid401 | GetApiV1ExperimentsCorrosionExperimentid403 | GetApiV1ExperimentsCorrosionExperimentid404;
    request: never;
    pathParams: GetApiV1ExperimentsCorrosionExperimentidPathParams;
    queryParams: never;
    headerParams: never;
    response: GetApiV1ExperimentsCorrosionExperimentidQueryResponse;
    client: {
        parameters: Partial<Parameters<GetApiV1ExperimentsCorrosionExperimentidClient>[0]>;
        return: Awaited<ReturnType<GetApiV1ExperimentsCorrosionExperimentidClient>>;
    };
};
export const getApiV1ExperimentsCorrosionExperimentidQueryKey = (experimentId: GetApiV1ExperimentsCorrosionExperimentidPathParams["experimentId"]) => [{ url: "/api/v1/experiments/corrosion/:experimentId", params: { experimentId: experimentId } }] as const;
export type GetApiV1ExperimentsCorrosionExperimentidQueryKey = ReturnType<typeof getApiV1ExperimentsCorrosionExperimentidQueryKey>;
export function getApiV1ExperimentsCorrosionExperimentidQueryOptions(experimentId: GetApiV1ExperimentsCorrosionExperimentidPathParams["experimentId"], options: GetApiV1ExperimentsCorrosionExperimentid["client"]["parameters"] = {}) {
    const queryKey = getApiV1ExperimentsCorrosionExperimentidQueryKey(experimentId);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<GetApiV1ExperimentsCorrosionExperimentid["data"], GetApiV1ExperimentsCorrosionExperimentid["error"]>({
                method: "get",
                url: `/api/v1/experiments/corrosion/${experimentId}`,
                ...options
            });
            return res.data;
        },
    });
}
/**
     * @link /api/v1/experiments/corrosion/:experimentId */
export function useGetApiV1ExperimentsCorrosionExperimentid<TData = GetApiV1ExperimentsCorrosionExperimentid["response"], TQueryData = GetApiV1ExperimentsCorrosionExperimentid["response"], TQueryKey extends QueryKey = GetApiV1ExperimentsCorrosionExperimentidQueryKey>(experimentId: GetApiV1ExperimentsCorrosionExperimentidPathParams["experimentId"], options: {
    query?: Partial<QueryObserverOptions<GetApiV1ExperimentsCorrosionExperimentid["response"], GetApiV1ExperimentsCorrosionExperimentid["error"], TData, TQueryData, TQueryKey>>;
    client?: GetApiV1ExperimentsCorrosionExperimentid["client"]["parameters"];
} = {}): UseQueryResult<TData, GetApiV1ExperimentsCorrosionExperimentid["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getApiV1ExperimentsCorrosionExperimentidQueryKey(experimentId);
    const query = useQuery({
        ...getApiV1ExperimentsCorrosionExperimentidQueryOptions(experimentId, clientOptions) as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, GetApiV1ExperimentsCorrosionExperimentid["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}