import { ACID_TABLE_RO } from './constants';

/**
 * function to find the index of the target value in col1 (°Bé) using binary search
 * @param target
 * @returns
 */
const findIndexByCol1 = (target: number): number => {
  let left = 0;
  let right = ACID_TABLE_RO.length - 1;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);
    const midValue = ACID_TABLE_RO[mid][0];

    if (midValue === target) {
      return mid;
    } else if (midValue < target) {
      left = mid + 1;
    } else {
      right = mid - 1;
    }
  }

  return -1; // Target not found
};

/**
 * function to get the value of col3(% HCI) by providing the value of col1 (°Bé)
 * @param degBe
 * @returns
 */
export const getHCIByDegBe = (degBe: number): number => {
  const index = findIndexByCol1(degBe);
  return index === -1 ? NaN : ACID_TABLE_RO[index][2];
};

/**
 * function to get the value of col2(SG (60/60F)) by providing the value of col1 (°Bé)
 * @param degBe
 * @returns
 */
export const getSGByDegBe = (degBe: number): number => {
  const index = findIndexByCol1(degBe);
  return index === -1 ? NaN : ACID_TABLE_RO[index][1];
};

/**
 * function to get the % HCI and S.G. by degrees Baumé
 * @param degBe
 * @returns
 */
export const getHCIAndSGByDegBe = (degBe: number): { hci: number; sg: number } => {
  const index = findIndexByCol1(degBe);
  if (index === -1) return { hci: 0, sg: 0 };
  return {
    hci: ACID_TABLE_RO[index][2],
    sg: ACID_TABLE_RO[index][1],
  };
};
