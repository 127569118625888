import * as z from 'zod';

export const DATE_REGEX_MATCHER = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/; // YYYY-MM-DD

export const zStringRequired = z.string().min(1, { message: 'Required' });

export const zDateRequired = z
  .string({
    required_error: 'Required',
    invalid_type_error: 'Required',
  })
  .min(1, { message: 'Required' })
  .regex(DATE_REGEX_MATCHER, {
    message: 'Invalid date format',
  });
