export const getHollowCylinderSourfaceArea = (
  diameter1: number,
  diameter2: number,
  lenght: number
): number => {
  const pi = Math.PI;
  const firstTerm = pi * diameter1 * lenght;
  const secondTerm = 2 * ((diameter1 / 2) ** 2 * pi - (diameter2 / 2) ** 2 * pi);
  const thirdTerm = pi * diameter2 * lenght;

  return firstTerm + secondTerm + thirdTerm;
};

export const getCylinderSurfaceArea = (diameter: number, length: number): number => {
  const pi = Math.PI;
  const firstTerm = 2 * (pi * Math.pow(diameter / 2, 2));
  const secondTerm = pi * diameter * length;
  return firstTerm + secondTerm;
};

export const getCurvedSampleNoHoleSurfaceArea = (
  length: number,
  thickness: number,
  width1: number,
  width2: number
): number => {
  const firstTerm = 2 * length * thickness;
  const secondTerm = length * width1;
  const thirdTerm = length * width2;
  const fourthTerm = (width1 + width2) * thickness;
  return firstTerm + secondTerm + thirdTerm + fourthTerm;
};

export const getCurvedSampleWithHoleSurfaceArea = (
  length: number,
  thickness: number,
  width1: number,
  width2: number,
  holeDiameter: number
): number => {
  const firstTerm = 2 * length * thickness;
  const secondTerm = length * width1;
  const thirdTerm = length * width2;
  const fourthTerm = (width1 + width2) * thickness;
  const fifthTerm = Math.PI * holeDiameter * thickness;
  const sixthTerm = 2 * Math.PI * Math.pow(holeDiameter / 2, 2);
  return firstTerm + secondTerm + thirdTerm + fourthTerm + fifthTerm - sixthTerm;
};

export const getFlatRectangularSquareNoHoleSurfaceArea = (
  length: number,
  width: number,
  thickness: number
): number => {
  const firstTerm = 2 * length * width;
  const secondTerm = 2 * length * thickness;
  const thirdTerm = 2 * length * thickness;
  return firstTerm + secondTerm + thirdTerm;
};

export const getFlatRectangularSquareWithHoleSurfaceArea = (
  length: number,
  width: number,
  thickness: number,
  holeDiameter: number
): number => {
  const firstTerm = 2 * length * width;
  const secondTerm = 2 * length * thickness;
  const thirdTerm = 2 * width * thickness;
  const fourthTerm = Math.PI * holeDiameter * thickness;
  const fifthTerm = 2 * Math.PI * Math.pow(holeDiameter / 2, 2);
  return firstTerm + secondTerm + thirdTerm + fourthTerm - fifthTerm;
};

export const getFlatCircleNoHoleSurfaceArea = (diameter: number, thickness: number): number => {
  const pi = Math.PI;
  const firstTerm = 2 * pi * (diameter * 0.5) * thickness;
  const secondTerm = 2 * pi * Math.pow(diameter * 0.5, 2);
  return firstTerm + secondTerm;
};

export const getFlatCircleWithHoleSurfaceArea = (
  diameter: number,
  thickness: number,
  holeDiameter: number
): number => {
  const pi = Math.PI;
  const firstTerm = 2 * pi * (diameter * 0.5) * thickness;
  const secondTerm = 2 * pi * Math.pow(diameter * 0.5, 2);
  const thirdTerm = 2 * pi * Math.pow(holeDiameter * 0.5, 2);
  const fourthTerm = 2 * pi * (holeDiameter * 0.5) * thickness;
  return firstTerm + secondTerm - thirdTerm + fourthTerm;
};

export const getSphereSurfaceArea = (diameter: number): number => {
  const pi = Math.PI;
  const result = 4 * (pi * Math.pow(diameter / 2, 2));
  return result;
};
