import { Navbar } from 'react-daisyui';
import Submenu from './components/sub-menu';
import SearchControl from './components/search-control';
import ProfileControl from './components/profile-control';
import NotificationsControl from './components/notifications-control';
import { useIsAuthenticated } from '@azure/msal-react';

export const Header = () => {
  const isAuthenticated = useIsAuthenticated();
  return (
    <Navbar className="header">
      {isAuthenticated && (
        <>
          <Navbar.Start>
            <Submenu />
          </Navbar.Start>
          <Navbar.End className="navbar-end">
            {/* <SearchControl /> */}
            <ProfileControl />
            {/* <NotificationsControl /> */}
          </Navbar.End>
        </>
      )}
    </Navbar>
  );
};
