import { forwardRef } from 'react';
import { Button, Modal } from 'react-daisyui';

export type ConfirmationAlertTextContent = {
  title: string;
  message: string;
  confirmButtonText: string;
  cancelButtonText: string;
  details?: string;
};

export enum ConfirmationAlertType {
  Delete = 'Delete',
}

type ConfirmationAlertProps = {
  textContent: ConfirmationAlertTextContent;
  callback: (resp: boolean) => void;
};

export const ConfirmationAlert = forwardRef<HTMLDialogElement, ConfirmationAlertProps>(
  ({ callback, textContent }: ConfirmationAlertProps, ref) => {
    const { title, message, details } = textContent;
    return (
      <div className="font-sans">
        <Modal ref={ref}>
          <Modal.Header className="font-bold">{title}</Modal.Header>
          <Modal.Body>
            {message}
            {details && (
              <div className="text-red-500">
                <br />
                <p>"{details}"</p>
              </div>
            )}
          </Modal.Body>
          <Modal.Actions>
            <form
              method="dialog"
              className="flex gap-2"
            >
              <Button
                color="secondary"
                onClick={() => callback(false)}
              >
                No
              </Button>
              <Button
                color="primary"
                onClick={() => callback(true)}
              >
                Yes
              </Button>
            </form>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
);
