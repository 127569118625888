import { useState } from 'react';
import { Button, Pagination } from 'react-daisyui';
import { CorrosionUpdate } from '../corrosion/Create';
import { useLocation } from 'react-router';

type MultipleViewProps = {};

export type OpenedExperiment = {
  key: string;
  expNumber: string;
  checked: boolean;
};

export const MultipleView = (props: MultipleViewProps) => {
  const location = useLocation();
  const { openedExperiments } = location.state || [];
  const [activeExpKey, setActiveExpKey] = useState(openedExperiments[0]?.key || '');
  const handleButtonClick = (expKey: string) => {
    setActiveExpKey(expKey);
  };

  return (
    <>
      <Pagination
        className="flex overflow-x-auto whitespace-nowrap"
        horizontal
      >
        {openedExperiments.map((exp: OpenedExperiment) => (
          <Button
            size="sm"
            className="join-item"
            key={exp.key}
            onClick={() => handleButtonClick(exp.key)}
            active={activeExpKey === exp.key}
          >
            {exp.expNumber}
          </Button>
        ))}
      </Pagination>
      <div className="content">
        {openedExperiments.map((exp: OpenedExperiment) => {
          if (exp.key === activeExpKey) {
            return (
              <div key={exp.key}>
                <CorrosionUpdate experimentId={exp.key} />
              </div>
            );
          }
          return null; // not rendering anything if it's not the active experiment
        })}
      </div>
    </>
  );
};
