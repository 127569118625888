import client from "../../lib/api/client";
import { useMutation } from "@tanstack/react-query";
import type { PutApiV1ExperimentsCorrosionExperimentidResultsImageMutationRequest, PutApiV1ExperimentsCorrosionExperimentidResultsImageMutationResponse, PutApiV1ExperimentsCorrosionExperimentidResultsImagePathParams, PutApiV1ExperimentsCorrosionExperimentidResultsImage400, PutApiV1ExperimentsCorrosionExperimentidResultsImage401, PutApiV1ExperimentsCorrosionExperimentidResultsImage403 } from "../models/PutApiV1ExperimentsCorrosionExperimentidResultsImage";
import type { UseMutationOptions } from "@tanstack/react-query";

type PutApiV1ExperimentsCorrosionExperimentidResultsImageClient = typeof client<PutApiV1ExperimentsCorrosionExperimentidResultsImageMutationResponse, PutApiV1ExperimentsCorrosionExperimentidResultsImage400 | PutApiV1ExperimentsCorrosionExperimentidResultsImage401 | PutApiV1ExperimentsCorrosionExperimentidResultsImage403, PutApiV1ExperimentsCorrosionExperimentidResultsImageMutationRequest>;
type PutApiV1ExperimentsCorrosionExperimentidResultsImage = {
    data: PutApiV1ExperimentsCorrosionExperimentidResultsImageMutationResponse;
    error: PutApiV1ExperimentsCorrosionExperimentidResultsImage400 | PutApiV1ExperimentsCorrosionExperimentidResultsImage401 | PutApiV1ExperimentsCorrosionExperimentidResultsImage403;
    request: PutApiV1ExperimentsCorrosionExperimentidResultsImageMutationRequest;
    pathParams: PutApiV1ExperimentsCorrosionExperimentidResultsImagePathParams;
    queryParams: never;
    headerParams: never;
    response: PutApiV1ExperimentsCorrosionExperimentidResultsImageMutationResponse;
    client: {
        parameters: Partial<Parameters<PutApiV1ExperimentsCorrosionExperimentidResultsImageClient>[0]>;
        return: Awaited<ReturnType<PutApiV1ExperimentsCorrosionExperimentidResultsImageClient>>;
    };
};
/**
     * @link /api/v1/experiments/corrosion/:experimentId/results/image */
export function usePutApiV1ExperimentsCorrosionExperimentidResultsImage(experimentId: PutApiV1ExperimentsCorrosionExperimentidResultsImagePathParams["experimentId"], options: {
    mutation?: UseMutationOptions<PutApiV1ExperimentsCorrosionExperimentidResultsImage["response"], PutApiV1ExperimentsCorrosionExperimentidResultsImage["error"], PutApiV1ExperimentsCorrosionExperimentidResultsImage["request"]>;
    client?: PutApiV1ExperimentsCorrosionExperimentidResultsImage["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    return useMutation({
        mutationFn: async (data) => {
            const res = await client<PutApiV1ExperimentsCorrosionExperimentidResultsImage["data"], PutApiV1ExperimentsCorrosionExperimentidResultsImage["error"], PutApiV1ExperimentsCorrosionExperimentidResultsImage["request"]>({
                method: "put",
                url: `/api/v1/experiments/corrosion/${experimentId}/results/image`,
                data,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions
    });
}