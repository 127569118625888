import { TextBox, valueAsNumberOptional } from '@components/form';
import PaddedDivision from '../padded-division';
import useFormContextExtended from '../useFormContextExtended';
import { schema } from '../../schema';
import { getFlatCircleNoHoleSurfaceArea } from './surface-area-calculations';
import { useEffect } from 'react';

type FlatCircleNoHoleProps = {};

const FlatCircleNoHole = (props: FlatCircleNoHoleProps) => {
  const { extendRegister, errors, watch, setValue } = useFormContextExtended(schema);
  const diameter = watch('flatCircleNoHole.diameter');
  const thickness = watch('flatCircleNoHole.thickness');

  useEffect(() => {
    const sa = getFlatCircleNoHoleSurfaceArea(diameter, thickness);
    const roundedSa = Number(sa.toFixed(4));
    setValue('flatCircleNoHole.surfaceArea', roundedSa);
    setValue('surfaceArea', roundedSa);
  }, [diameter, thickness, setValue]);

  return (
    <PaddedDivision>
      <TextBox
        type="number"
        label="Diameter (D), in"
        error={errors.flatCircleNoHole?.diameter?.message}
        {...extendRegister('flatCircleNoHole.diameter', {
          setValueAs: valueAsNumberOptional,
        })}
      />
      <TextBox
        type="number"
        label="Thickness (T), in"
        error={errors.flatCircleNoHole?.thickness?.message}
        {...extendRegister('flatCircleNoHole.thickness', {
          setValueAs: valueAsNumberOptional,
        })}
      />
      <TextBox
        type="number"
        readOnly={true}
        label={
          <span>
            Surface Area, in <sup>2</sup>
          </span>
        }
        error={errors.flatCircleNoHole?.surfaceArea?.message}
        {...extendRegister('flatCircleNoHole.surfaceArea', {
          setValueAs: valueAsNumberOptional,
        })}
      />
    </PaddedDivision>
  );
};

export default FlatCircleNoHole;
