import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Navigate, Outlet } from 'react-router';
import { loginRequest } from '@authConfig';

export const PrivateRoutes = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  if (!isAuthenticated) {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log('LogIn redirect error: ', e);
    });
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};
