import { zodResolver } from '@hookform/resolvers/zod';
import { DropDownCatalogViewSch } from '@sdk-client/models';
import { useCallback, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  useDeleteApiV1CatalogsDropdownsTypeidCatalogid,
  usePostApiV1CatalogsDropdownsTypeid,
  usePutApiV1CatalogsDropdownsTypeidCatalogid,
} from '@sdk-client/hooks';
import { CatalogType } from '../types';
import { ZodObject, ZodRawShape, z } from 'zod';

export const useDropDownAdmin = <TSchema extends ZodRawShape>(
  schema: ZodObject<TSchema>,
  type: CatalogType,
  refetch: (() => void) | undefined
) => {
  const [itemSelected, setItemSelected] = useState<DropDownCatalogViewSch | null>(null);
  const modalRef = useRef<HTMLDialogElement>(null);
  const modalConfirmationRef = useRef<HTMLDialogElement>(null);
  type typeSchema = z.infer<typeof schema>;

  const methods = useForm<typeSchema>({
    mode: 'onChange',
    resolver: zodResolver(schema),
  });

  const resetFormModal = useCallback(() => {
    methods.reset();
    setItemSelected(null);
  }, [methods]);

  const handleShow = useCallback(() => {
    modalRef.current?.showModal();
  }, [modalRef]);

  const handleClose = useCallback(() => {
    resetFormModal();
    modalRef.current?.close();
  }, [modalRef, resetFormModal]);

  const onRemoteSucess = () => {
    refetch?.();
    handleClose();
  };

  const { mutateAsync: mutateCreateAsync, isPending: isCreatePending } = usePostApiV1CatalogsDropdownsTypeid(
    Number(type),
    {
      userId: '8888', // TODO: get from auth
    },
    {
      mutation: {
        onSuccess: onRemoteSucess,
      },
    }
  );

  const { mutateAsync: mutateUpdateAsync, isPending: isUpdatePending } = usePutApiV1CatalogsDropdownsTypeidCatalogid(
    Number(type),
    itemSelected?.id ?? '',
    {
      userId: '8888', // TODO: get from auth
    },
    {
      mutation: {
        onSuccess: onRemoteSucess,
      },
    }
  );

  const { mutateAsync: mutateDeleteAsync, isPending: isDeletePending } = useDeleteApiV1CatalogsDropdownsTypeidCatalogid(
    Number(type),
    itemSelected?.id ?? '',
    {
      userId: '8888', // TODO: get from auth
    },
    {
      mutation: {
        onSuccess: onRemoteSucess,
      },
    }
  );

  return {
    itemSelected,
    setItemSelected,
    methods,
    modalRef,
    modalConfirmationRef,
    resetFormModal,
    handleShow,
    handleClose,
    mutateCreateAsync,
    isCreatePending,
    mutateUpdateAsync,
    isUpdatePending,
    mutateDeleteAsync,
    isDeletePending,
  };
};
