import { DatePicker, Dropdown, FormCheckbox, TextBox } from '@components/form';
import { Tabs } from 'react-daisyui';
import { Controller } from 'react-hook-form';
import TabContent from './tab-content';
import TabContentCard from './tab-content-card';
import { TabProps } from './types';
import { Dispatch, SetStateAction } from 'react';
import { DateValueType } from 'react-tailwindcss-datepicker';
import { CONTENT_TAB_STYLE } from './constants';
import useFormContextExtended from './useFormContextExtended';
import { dpConfig } from './dummy-dropdown-config';
import { CatalogType } from '@pages/Admin/DropDownListData/types';
import { useGetApiV1CatalogsDropdownsTypeid } from '@sdk-client/hooks';
import { LoadingRing } from '@components/loaders/LoadingRing';

type RequestDetailsTabProps = {
  dateOfRequestState: [DateValueType, Dispatch<SetStateAction<DateValueType>>];
  dateFinalReportState: [DateValueType, Dispatch<SetStateAction<DateValueType>>];
} & TabProps;

const RequestDetailsTab = ({
  indexTab,
  activeTab,
  setActiveTab,
  dateOfRequestState,
  dateFinalReportState,
}: RequestDetailsTabProps) => {
  const [dateOfRequestValue, setDateOfRequestValue] = dateOfRequestState;
  const [dateFinalReportValue, setDateFinalReportValue] = dateFinalReportState;
  const { control, extendRegister, errors } = useFormContextExtended();

  const { data: typeOfRequestData, isPending: isPendingTypeOfRequestData } = useGetApiV1CatalogsDropdownsTypeid(
    Number(CatalogType.TYPE_OF_REQUEST)
  );
  const { data: applicationData, isPending: isPendingApplicationData } = useGetApiV1CatalogsDropdownsTypeid(
    Number(CatalogType.APPLICATION)
  );
  const { data: pslData, isPending: isPendingpslData } = useGetApiV1CatalogsDropdownsTypeid(Number(CatalogType.PSL));

  if (isPendingTypeOfRequestData || isPendingApplicationData || isPendingpslData) return <LoadingRing />;

  console.log('RequestDetailsTab errors: ', errors);

  return (
    <Tabs.RadioTab
      checked={activeTab === indexTab}
      onChange={() => setActiveTab(indexTab)}
      name="section"
      label="Request Details"
      contentClassName={CONTENT_TAB_STYLE}
    >
      <TabContent>
        <TabContentCard>
          <TextBox
            label="Name"
            error={errors.name?.message}
            {...extendRegister('name')}
          />
          <TextBox
            label="Work Location (City, State, Country)"
            error={errors.workLocation?.message}
            {...extendRegister('workLocation')}
          />
          <TextBox
            label="Phone"
            error={errors.phone?.message}
            {...extendRegister('phone')}
          />
          <TextBox
            label="Email"
            error={errors.email?.message}
            {...extendRegister('email')}
          />
          <TextBox
            label="Customer"
            error={errors.customer?.message}
            {...extendRegister('customer')}
          />
        </TabContentCard>
        <TabContentCard>
          <Dropdown
            label="Request for a Tender/Bid?"
            {...extendRegister('isRequestForTenderBid')}
            error={errors.isRequestForTenderBid?.message}
            options={dpConfig.booleanOptions}
          />
          <TextBox
            label="Formation Name"
            {...extendRegister('formationName')}
          />
          <TextBox
            label="Field Name"
            {...extendRegister('fieldName')}
          />
          <Dropdown
            label="Type of Request"
            {...extendRegister('typeOfRequest')}
            error={errors.typeOfRequest?.message}
            options={typeOfRequestData}
          />
          <Dropdown
            label="Application"
            {...extendRegister('application')}
            error={errors.status?.message}
            options={applicationData}
          />
        </TabContentCard>
        <TabContentCard>
          <Dropdown
            label="PSL"
            error={errors.psl?.message}
            {...extendRegister('psl')}
            options={pslData}
          />
          <TextBox
            label="Project Revenue"
            error={errors.projectRevenue?.message}
            {...extendRegister('projectRevenue')}
          />
          <Controller
            name="dateOfRequest"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                label="Date of Request"
                value={dateOfRequestValue}
                onChange={(newValue) => {
                  field.onChange(newValue?.startDate);
                  field.value = newValue?.startDate as string;
                  setDateOfRequestValue(newValue);
                }}
                error={error?.message}
              />
            )}
          />
          <Controller
            name="dateFinalReportRequested"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                label="Date Final Report is Requested"
                is_required="true"
                value={dateFinalReportValue}
                onChange={(newValue) => {
                  field.onChange(newValue?.startDate);
                  field.value = newValue?.startDate as string;
                  setDateFinalReportValue(newValue);
                }}
                error={error?.message}
              />
            )}
          />
          <Dropdown
            label="Shipping samples?"
            {...extendRegister('isShippingSamples')}
            error={errors.isShippingSamples?.message}
            options={dpConfig.booleanOptions}
          />
        </TabContentCard>
        <TabContentCard>
          <FormCheckbox
            label="Sample Checked for H2S"
            error={errors.isSampleCheckedForH2s?.message}
            {...extendRegister('isSampleCheckedForH2s')}
          />
          <TextBox
            type="number"
            label="H2S Content (ppm)"
            error={errors.h2sContent?.message}
            {...extendRegister('h2sContent')}
          />
          <TextBox
            label="Cost Center"
            error={errors.costCenter?.message}
            {...extendRegister('costCenter')}
          />
        </TabContentCard>
      </TabContent>
    </Tabs.RadioTab>
  );
};

export default RequestDetailsTab;
