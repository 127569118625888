import { flexRender, getCoreRowModel, useReactTable, TableOptions } from '@tanstack/react-table';
import FooterCell from './components/footer-cell';
import { useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTableConfig } from './ConfigProvider';
import { CustomTableMeta, Row } from '../types';
export { default as EditCell } from './components/edit-cell';
export { default as TextCell } from './components/text-cell';
export { default as DropDownCell } from './components/drop-down-cell';
export { default as TextBoxCell } from './components/textbox-cell';

type EditableTableProps<T extends Record<string, any> & Row> = Pick<TableOptions<T>, 'columns' | 'data'> & {
  rowDefaultValues: T;
};

export const EditableTable = <T extends Record<string, any> & Row>({
  data,
  columns,
  rowDefaultValues,
}: EditableTableProps<T>) => {
  const { rowsPropertyName } = useTableConfig();
  //const [dataState, setDataState] = useState<T[]>(() => [...data]);
  const { fields, append, remove } = useFieldArray({
    name: rowsPropertyName,
  });
  const newRow = rowDefaultValues;

  // useEffect(() => {
  //   debugger;
  //   setValue(rowsPropertyName, [...data]);
  // }, []);

  // useEffect(() => {
  //   //setValue(rowsPropertyName, [...data]);
  //   append(data);
  // }, [data]);

  // const updateValue = (newRow: T) => {
  //   //TODO CSOTO PENDING TO IMPLENMENT
  //   //setValue(rowsPropertyName, [...getValues(rowsPropertyName), newRow]);
  // };

  // const removeValue = (rowIndex: number) => {
  //   remove(rowIndex);
  //   //unregister(`${rowsPropertyName}.${rowId}`);
  //   // setValue(
  //   //   rowsPropertyName,
  //   //   getValues(rowsPropertyName).filter((item: Row) => item.id !== rowId)
  //   // );
  // };

  const table = useReactTable<T>({
    data: fields as T[],
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      addRow: () => {
        //const currentData = fields;
        //newRow.id = (currentData.length + 1).toString();
        append(newRow as T);
        // const setFunc = (old: T[]) => [...old, newRow];
        // setDataState(setFunc);
        //updateValue(newRow);
        //console.log(getValues('rows'));
      },
      removeRow: (rowIndex: number) => {
        //const setFilterFunc = (old: T[]) => old.filter((_row: T, index: number) => index !== rowIndex);
        //setDataState(setFilterFunc);
        remove(rowIndex);
      },
    } as CustomTableMeta<T>,
  });
  return (
    <div className="w-full overflow-x-auto">
      <table className="w-full min-w-[640px] table-auto">
        <thead className="border-b bg-gray-50">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="px-1 py-1 text-xs font-medium text-gray-900"
                >
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr
                key={row.id}
                className='border-b" bg-white'
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td
                      key={cell.id}
                      className="px-2 py-3 text-sm text-gray-900"
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <th
              colSpan={table.getCenterLeafColumns().length}
              className="px-2 py-3 text-sm text-right"
            >
              <FooterCell table={table} />
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
