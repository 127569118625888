import client from "../../lib/api/client";
import { useMutation } from "@tanstack/react-query";
import type { DeleteApiV1TechnicalServicesTicketrequestidMutationResponse, DeleteApiV1TechnicalServicesTicketrequestidPathParams, DeleteApiV1TechnicalServicesTicketrequestidHeaderParams, DeleteApiV1TechnicalServicesTicketrequestid400, DeleteApiV1TechnicalServicesTicketrequestid401, DeleteApiV1TechnicalServicesTicketrequestid403, DeleteApiV1TechnicalServicesTicketrequestid404 } from "../models/DeleteApiV1TechnicalServicesTicketrequestid";
import type { UseMutationOptions } from "@tanstack/react-query";

type DeleteApiV1TechnicalServicesTicketrequestidClient = typeof client<DeleteApiV1TechnicalServicesTicketrequestidMutationResponse, DeleteApiV1TechnicalServicesTicketrequestid400 | DeleteApiV1TechnicalServicesTicketrequestid401 | DeleteApiV1TechnicalServicesTicketrequestid403 | DeleteApiV1TechnicalServicesTicketrequestid404, never>;
type DeleteApiV1TechnicalServicesTicketrequestid = {
    data: DeleteApiV1TechnicalServicesTicketrequestidMutationResponse;
    error: DeleteApiV1TechnicalServicesTicketrequestid400 | DeleteApiV1TechnicalServicesTicketrequestid401 | DeleteApiV1TechnicalServicesTicketrequestid403 | DeleteApiV1TechnicalServicesTicketrequestid404;
    request: never;
    pathParams: DeleteApiV1TechnicalServicesTicketrequestidPathParams;
    queryParams: never;
    headerParams: DeleteApiV1TechnicalServicesTicketrequestidHeaderParams;
    response: DeleteApiV1TechnicalServicesTicketrequestidMutationResponse;
    client: {
        parameters: Partial<Parameters<DeleteApiV1TechnicalServicesTicketrequestidClient>[0]>;
        return: Awaited<ReturnType<DeleteApiV1TechnicalServicesTicketrequestidClient>>;
    };
};
/**
     * @link /api/v1/technical-services/:ticketRequestId */
export function useDeleteApiV1TechnicalServicesTicketrequestid(ticketRequestId: DeleteApiV1TechnicalServicesTicketrequestidPathParams["ticketRequestId"], headers: DeleteApiV1TechnicalServicesTicketrequestid["headerParams"], options: {
    mutation?: UseMutationOptions<DeleteApiV1TechnicalServicesTicketrequestid["response"], DeleteApiV1TechnicalServicesTicketrequestid["error"], void>;
    client?: DeleteApiV1TechnicalServicesTicketrequestid["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    return useMutation({
        mutationFn: async () => {
            const res = await client<DeleteApiV1TechnicalServicesTicketrequestid["data"], DeleteApiV1TechnicalServicesTicketrequestid["error"], void>({
                method: "delete",
                url: `/api/v1/technical-services/${ticketRequestId}`,
                headers: { ...headers, ...clientOptions.headers },
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions
    });
}