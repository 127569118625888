import client from "../../lib/api/client";
import { useQuery, queryOptions } from "@tanstack/react-query";
import type { GetApiV1TechnicalServicesTicketrequestidQueryResponse, GetApiV1TechnicalServicesTicketrequestidPathParams, GetApiV1TechnicalServicesTicketrequestid400, GetApiV1TechnicalServicesTicketrequestid401, GetApiV1TechnicalServicesTicketrequestid403, GetApiV1TechnicalServicesTicketrequestid404 } from "../models/GetApiV1TechnicalServicesTicketrequestid";
import type { QueryObserverOptions, UseQueryResult, QueryKey } from "@tanstack/react-query";

type GetApiV1TechnicalServicesTicketrequestidClient = typeof client<GetApiV1TechnicalServicesTicketrequestidQueryResponse, GetApiV1TechnicalServicesTicketrequestid400 | GetApiV1TechnicalServicesTicketrequestid401 | GetApiV1TechnicalServicesTicketrequestid403 | GetApiV1TechnicalServicesTicketrequestid404, never>;
type GetApiV1TechnicalServicesTicketrequestid = {
    data: GetApiV1TechnicalServicesTicketrequestidQueryResponse;
    error: GetApiV1TechnicalServicesTicketrequestid400 | GetApiV1TechnicalServicesTicketrequestid401 | GetApiV1TechnicalServicesTicketrequestid403 | GetApiV1TechnicalServicesTicketrequestid404;
    request: never;
    pathParams: GetApiV1TechnicalServicesTicketrequestidPathParams;
    queryParams: never;
    headerParams: never;
    response: GetApiV1TechnicalServicesTicketrequestidQueryResponse;
    client: {
        parameters: Partial<Parameters<GetApiV1TechnicalServicesTicketrequestidClient>[0]>;
        return: Awaited<ReturnType<GetApiV1TechnicalServicesTicketrequestidClient>>;
    };
};
export const getApiV1TechnicalServicesTicketrequestidQueryKey = (ticketRequestId: GetApiV1TechnicalServicesTicketrequestidPathParams["ticketRequestId"]) => [{ url: "/api/v1/technical-services/:ticketRequestId", params: { ticketRequestId: ticketRequestId } }] as const;
export type GetApiV1TechnicalServicesTicketrequestidQueryKey = ReturnType<typeof getApiV1TechnicalServicesTicketrequestidQueryKey>;
export function getApiV1TechnicalServicesTicketrequestidQueryOptions(ticketRequestId: GetApiV1TechnicalServicesTicketrequestidPathParams["ticketRequestId"], options: GetApiV1TechnicalServicesTicketrequestid["client"]["parameters"] = {}) {
    const queryKey = getApiV1TechnicalServicesTicketrequestidQueryKey(ticketRequestId);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<GetApiV1TechnicalServicesTicketrequestid["data"], GetApiV1TechnicalServicesTicketrequestid["error"]>({
                method: "get",
                url: `/api/v1/technical-services/${ticketRequestId}`,
                ...options
            });
            return res.data;
        },
    });
}
/**
     * @link /api/v1/technical-services/:ticketRequestId */
export function useGetApiV1TechnicalServicesTicketrequestid<TData = GetApiV1TechnicalServicesTicketrequestid["response"], TQueryData = GetApiV1TechnicalServicesTicketrequestid["response"], TQueryKey extends QueryKey = GetApiV1TechnicalServicesTicketrequestidQueryKey>(ticketRequestId: GetApiV1TechnicalServicesTicketrequestidPathParams["ticketRequestId"], options: {
    query?: Partial<QueryObserverOptions<GetApiV1TechnicalServicesTicketrequestid["response"], GetApiV1TechnicalServicesTicketrequestid["error"], TData, TQueryData, TQueryKey>>;
    client?: GetApiV1TechnicalServicesTicketrequestid["client"]["parameters"];
} = {}): UseQueryResult<TData, GetApiV1TechnicalServicesTicketrequestid["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getApiV1TechnicalServicesTicketrequestidQueryKey(ticketRequestId);
    const query = useQuery({
        ...getApiV1TechnicalServicesTicketrequestidQueryOptions(ticketRequestId, clientOptions) as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, GetApiV1TechnicalServicesTicketrequestid["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}