import { Path, RegisterOptions, useFormContext } from 'react-hook-form';
import { ZodObject, ZodRawShape, ZodTypeAny, z } from 'zod';

//CSOTO TODO: Pending to replace useFormContextExtended from requestForm to this generic one
const useFormContextExtended = <TSchema extends ZodRawShape>(schema: ZodObject<TSchema>) => {
  type typeSchema = z.infer<typeof schema>;
  const {
    control,
    register,
    watch,
    formState: { errors },
    setValue,
    unregister,
  } = useFormContext<typeSchema>();

  // Generic function to validate if a field is required
  const extendRegister = (fieldName: string, options?: RegisterOptions) => {
    const required = isFieldRequired(schema, fieldName);
    return {
      is_required: required ? 'true' : '',
      ...register(fieldName as Path<typeSchema>, options),
    };
  };

  return {
    control,
    extendRegister,
    errors,
    setValue,
    register,
    watch,
    unregister,
  };
};

// function to verify if a schema is a Zod object
function isZodObject(schema: ZodTypeAny): schema is ZodObject<any> {
  return schema._def.typeName === 'ZodObject';
}

const isFieldRequired = <T extends ZodRawShape>(schema: ZodObject<T>, fieldName: string): boolean => {
  const [part, ...rest] = fieldName.split('.');
  const field = schema.shape[part];

  if (isZodObject(field) && rest.length > 0) {
    return isFieldRequired(field, rest.join('.'));
  }

  if (!field) {
    throw new Error(`Field '${fieldName}' does not exist on schema.`);
  }

  if (
    field._def.typeName === 'ZodString' ||
    field._def.typeName === 'ZodNumber' ||
    field._def.typeName === 'ZodDefault'
  )
    return field.safeParse('').success === false;

  return false;
};

export default useFormContextExtended;
