import { BasicTable } from '@components/tables/BasicTable/BasicTable';
import { createColumns } from './columns';
import { useAllUsersQuery } from '@lib/api/authClient';
import { LoadingRing } from '@components/loaders/LoadingRing';

type UsersAndRolesProps = {};

export const UsersAndRoles = (props: UsersAndRolesProps) => {
  const { data, isLoading } = useAllUsersQuery('StimChem');
  const columns = createColumns({});
  if (isLoading) return <LoadingRing />;
  return (
    <BasicTable
      title="Users and Roles"
      data={data ?? []}
      columns={columns}
    />
  );
};
