import { Select } from 'react-daisyui';
import { useMemo, useState } from 'react';
import { useGetApiV1CatalogsDropdownsTypeid } from '@sdk-client/hooks';
import { LoadingRing } from '@components/loaders/LoadingRing';
import { CatalogType, catalogComponentsMap } from './DropDownListData/types';

type CorrosionCatalogsProps = {};

export const CorrosionCatalogs = (props: CorrosionCatalogsProps) => {
  const [value, setValue] = useState<string>(CatalogType.EQUIPMENTS);
  const { data, isPending, refetch } = useGetApiV1CatalogsDropdownsTypeid(Number(value));

  const catalogComponent = useMemo(() => {
    const Component = catalogComponentsMap[value];
    const onSave = async () => await refetch();
    // if exists a component for the current catalog type, render it with the data
    return Component ? (
      <Component
        data={data ?? []}
        type={value as CatalogType}
        refetch={onSave}
      />
    ) : null;
  }, [data, refetch, value]);

  if (isPending) return <LoadingRing />;

  return (
    <>
      <div className="flex w-full component-preview p-1 items-center justify-center gap-2 font-sans">
        <Select
          value={value}
          onChange={(event) => setValue(event.target.value)}
        >
          <Select.Option
            value={''}
            disabled
          >
            Pick a catalog
          </Select.Option>
          <Select.Option value={CatalogType.EQUIPMENTS}>Equipment</Select.Option>
          <Select.Option value={CatalogType.CELL_NUMBERS}>Cell number</Select.Option>
          <Select.Option value={CatalogType.METALLURGIES}>Metallurgies</Select.Option>
          <Select.Option value={CatalogType.ADDITIVE_TYPE}>Additive type</Select.Option>
          <Select.Option value={CatalogType.ADDITIVE}>Additive</Select.Option>
          <Select.Option value={CatalogType.PHYSICAL_STATE}>Physical state</Select.Option>
          <Select.Option value={CatalogType.PITTING_INDEX_SOURCE}>Pitting index</Select.Option>
          <Select.Option value={CatalogType.LOCATION}>Locations</Select.Option>
        </Select>
      </div>
      {catalogComponent}
    </>
  );
};
