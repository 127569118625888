import client from "../../lib/api/client";
import { useMutation } from "@tanstack/react-query";
import type { PutApiV1ExperimentsCorrosionExperimentidMutationRequest, PutApiV1ExperimentsCorrosionExperimentidMutationResponse, PutApiV1ExperimentsCorrosionExperimentidPathParams, PutApiV1ExperimentsCorrosionExperimentid400, PutApiV1ExperimentsCorrosionExperimentid401, PutApiV1ExperimentsCorrosionExperimentid403 } from "../models/PutApiV1ExperimentsCorrosionExperimentid";
import type { UseMutationOptions } from "@tanstack/react-query";

type PutApiV1ExperimentsCorrosionExperimentidClient = typeof client<PutApiV1ExperimentsCorrosionExperimentidMutationResponse, PutApiV1ExperimentsCorrosionExperimentid400 | PutApiV1ExperimentsCorrosionExperimentid401 | PutApiV1ExperimentsCorrosionExperimentid403, PutApiV1ExperimentsCorrosionExperimentidMutationRequest>;
type PutApiV1ExperimentsCorrosionExperimentid = {
    data: PutApiV1ExperimentsCorrosionExperimentidMutationResponse;
    error: PutApiV1ExperimentsCorrosionExperimentid400 | PutApiV1ExperimentsCorrosionExperimentid401 | PutApiV1ExperimentsCorrosionExperimentid403;
    request: PutApiV1ExperimentsCorrosionExperimentidMutationRequest;
    pathParams: PutApiV1ExperimentsCorrosionExperimentidPathParams;
    queryParams: never;
    headerParams: never;
    response: PutApiV1ExperimentsCorrosionExperimentidMutationResponse;
    client: {
        parameters: Partial<Parameters<PutApiV1ExperimentsCorrosionExperimentidClient>[0]>;
        return: Awaited<ReturnType<PutApiV1ExperimentsCorrosionExperimentidClient>>;
    };
};
/**
     * @link /api/v1/experiments/corrosion/:experimentId */
export function usePutApiV1ExperimentsCorrosionExperimentid(experimentId: PutApiV1ExperimentsCorrosionExperimentidPathParams["experimentId"], options: {
    mutation?: UseMutationOptions<PutApiV1ExperimentsCorrosionExperimentid["response"], PutApiV1ExperimentsCorrosionExperimentid["error"], PutApiV1ExperimentsCorrosionExperimentid["request"]>;
    client?: PutApiV1ExperimentsCorrosionExperimentid["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    return useMutation({
        mutationFn: async (data) => {
            const res = await client<PutApiV1ExperimentsCorrosionExperimentid["data"], PutApiV1ExperimentsCorrosionExperimentid["error"], PutApiV1ExperimentsCorrosionExperimentid["request"]>({
                method: "put",
                url: `/api/v1/experiments/corrosion/${experimentId}`,
                data,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions
    });
}