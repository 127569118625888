import React from 'react';
import { Input } from 'react-daisyui';
import clsx from 'clsx';
import './styles.css';

type TextBoxProps = {
  type?: string;
  label?: React.ReactNode;
  placeholder?: string;
  error?: string;
  is_required?: string;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  readOnly?: boolean;
};
export const TextBox = React.forwardRef<HTMLInputElement, TextBoxProps>(
  ({ type = 'text', label, placeholder, error, value, onChange, is_required, readOnly, ...restProps }, ref) => {
    return (
      <div className="flex w-full component-preview pb-3 items-center justify-center gap-2 font-sans">
        <div className="form-control w-28 xs:w-40 md:w-full max-w-xs">
          <label className="label">
            <span className="label-text">
              {label}
              {is_required && <span className="label-text text-red-400"> * </span>}
            </span>
            {error && <span className="label-text-alt text-red-400">{error}</span>}
          </label>

          <Input
            readOnly={readOnly}
            step="any"
            ref={ref}
            type={type}
            value={value}
            className={clsx({ 'border-1  border-red-400 focus:border-red-400': error })}
            size="sm"
            bordered={true}
            placeholder={placeholder}
            onChange={onChange}
            {...restProps}
          />
        </div>
      </div>
    );
  }
);

export const valueAsNumberOptional = (value: any) => (isNaN(value) || value === '' ? undefined : Number(value));
