import { Button } from 'react-daisyui';
import { useFormContext } from 'react-hook-form';
import { FormInput } from '../schema';
import TabsNavigator from './tabs-navigator';
import { Dispatch, SetStateAction } from 'react';
import { Authorization } from '@lib/Authorization';

type TabsFooterProps = {
  reset: () => void;
  save: () => void;
  totalTabs: number;
  activeTabState: [number, Dispatch<SetStateAction<number>>];
};

const TabsFooter = ({ reset, save, totalTabs, activeTabState }: TabsFooterProps) => {
  const {
    formState: { isValid },
  } = useFormContext<FormInput>();
  return (
    <Authorization allowedRoles={['Admin', 'Scientist/Technician', 'Operations']}>
      <div className="flex mt-4 items-center gap-2">
        <div className="w-full">
          <TabsNavigator {...{ totalTabs, activeTabState }} />
        </div>
        <div className="w-0 flex justify-end gap-2">
          <Button
            id="save"
            color="neutral"
            size="xs"
            type="submit"
            //onClick={() => save()}
          >
            Save
          </Button>
          <Button
            color="secondary"
            size="xs"
            type="submit"
            disabled={!isValid}
          >
            Save & Close
          </Button>
          <Button
            size="xs"
            color="primary"
            type="reset"
            onClick={() => reset()}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Authorization>
  );
};

export default TabsFooter;
