import { useFormContext, useWatch } from 'react-hook-form';
import { CellProps, OptionRelated } from '@components/tables/EditableTable';
import { useTableConfig } from '@components/tables/EditableTable/ConfigProvider';
import { useEffect, useState } from 'react';
import { Row } from '@components/tables/types';

export const PhysicalStateCell = <T extends Row>({ row, column }: CellProps<T>) => {
  const [additiveOld, setAdditiveOld] = useState<string | undefined>();
  const [rowIdValueOld, setRowIdValueOld] = useState<string | undefined>();
  const meta = column.columnDef.meta as OptionRelated[];
  const { setValue } = useFormContext();
  const { rowsPropertyName } = useTableConfig();

  const additiveValue = useWatch({
    name: `${rowsPropertyName}.${row.index}.additive`,
    defaultValue: '',
  });

  const physicalStateValue = useWatch({
    name: `${rowsPropertyName}.${row.index}.${column.id}`,
    defaultValue: '',
  });

  useEffect(() => {
    if (additiveValue && additiveOld !== additiveValue && (!rowIdValueOld || rowIdValueOld === row.original.id)) {
      setRowIdValueOld(row.original.id);
      setAdditiveOld(additiveValue);
    } else if (meta && meta.length > 0) {
      const option = meta.find((option) => option.relatedKeys.includes(additiveValue));
      if (option) {
        setValue(`${rowsPropertyName}.${row.index}.${column.id}`, option.text);
      } else {
        setValue(`${rowsPropertyName}.${row.index}.${column.id}`, '');
      }
    }
  }, [
    additiveOld,
    additiveValue,
    column.id,
    meta,
    row.index,
    row.original.id,
    rowIdValueOld,
    rowsPropertyName,
    setValue,
  ]);

  return (
    <span
      key={row.original.id}
      className="font-sans"
    >
      {physicalStateValue}
    </span>
  );
};
