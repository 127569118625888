import { useFieldArray, useFormContext } from 'react-hook-form';
import { CellProps } from '@components/tables/EditableTable';
import { useTableConfig } from '@components/tables/EditableTable/ConfigProvider';
import { useEffect } from 'react';
import { Row } from '@components/tables/types';

export const OrderOfAddition = <T extends Row>({ row, column }: CellProps<T>) => {
  const { getValues, setValue } = useFormContext();
  const { rowsPropertyName } = useTableConfig();
  const { fields } = useFieldArray({
    name: rowsPropertyName,
  });

  useEffect(() => {
    fields.forEach((field, index) => {
      setValue(`${rowsPropertyName}.${index}.orderOfAddition`, (index + 1).toString());
    });
  }, [fields, setValue, rowsPropertyName]);

  return (
    <span
      key={row.original.id}
      className="font-sans"
    >
      {getValues(`${rowsPropertyName}.${row.index}.${column.id}`)}
    </span>
  );
};
