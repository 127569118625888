import { DatePicker, Dropdown, TextBox, valueAsNumberOptional } from '@components/form';
import useFormContextExtended from './useFormContextExtended';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Option, OptionRelated } from '@components/form/Dropdown/types';
import { Collapse } from 'react-daisyui';
import { Controller } from 'react-hook-form';
import { DateValueType } from 'react-tailwindcss-datepicker';
import { schema } from '../schema';
import { CollapseCustom } from './collapse-custom';
import { SelectAutocomplete, SelectAutocompleteOption } from '@components/form/SelectAutocomplete';

type ExperimentInformationProps = {
  dateState: [DateValueType, Dispatch<SetStateAction<DateValueType>>];
  projectIdState: [SelectAutocompleteOption | null, Dispatch<SetStateAction<SelectAutocompleteOption | null>>];
  equipments: Option[];
  cellNumbers: Option[];
  locations: Option[];
  openDefault?: boolean;
};

function ExperimentInformation({
  dateState,
  projectIdState,
  equipments,
  cellNumbers,
  locations,
  openDefault = false,
}: ExperimentInformationProps) {
  const [dateValue, setDateValue] = dateState;
  const { control, extendRegister, errors } = useFormContextExtended(schema);
  const [selectedEquipment, setSelectedEquipment] = useState<OptionRelated | null>(null);
  const [filteredCellNumbers, setFilteredCellNumbers] = useState<Option[]>(cellNumbers);
  const [projectSelectedOption, setProjectSelectedOption] = projectIdState;

  useEffect(() => {
    if (selectedEquipment) {
      const filtered = cellNumbers.filter((cellNumber) => {
        return selectedEquipment.relatedKeys.includes(cellNumber.value);
      });
      setFilteredCellNumbers(filtered);
    }
  }, [selectedEquipment, cellNumbers]);

  return (
    <CollapseCustom openDefault={openDefault}>
      <Collapse.Title className="text-xl font-medium ">Experiment Information</Collapse.Title>
      <Collapse.Content>
        <div className="flex">
          <div className="w-2/5 px-1">
            <Controller
              name="date"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  label="Date"
                  is_required="true"
                  value={dateValue}
                  onChange={(newValue) => {
                    field.onChange(newValue?.startDate);
                    field.value = newValue?.startDate as string;
                    setDateValue(newValue);
                  }}
                  error={error?.message}
                />
              )}
            />
            <TextBox
              label="Operator"
              error={errors.operator?.message}
              {...extendRegister('operator')}
            />
            <Controller
              name="projectID"
              control={control}
              defaultValue={''}
              render={({ field, fieldState: { error } }) => (
                <SelectAutocomplete
                  {...field}
                  is_required="true"
                  label="Project ID"
                  selectedOption={projectSelectedOption}
                  error={error?.message}
                  onChange={(selected) => {
                    setProjectSelectedOption(selected);
                    field.onChange(selected?.value);
                  }}
                />
              )}
            />
            <Dropdown
              label="Equipment"
              {...extendRegister('equipment', {
                onChange(e) {
                  const selectedValue = e.target.value;
                  const selectedOption = equipments.filter((x) => x.value === selectedValue);
                  if (selectedOption && selectedOption[0]) {
                    setSelectedEquipment(selectedOption[0] as OptionRelated);
                  }
                },
              })}
              error={errors.equipment?.message}
              options={equipments}
            />
          </div>
          <div className="w-2/5 px-1">
            <Dropdown
              label="Cell Number"
              {...extendRegister('cellNumber')}
              error={errors.cellNumber?.message}
              options={filteredCellNumbers}
            />
            <TextBox
              type="number"
              label="Temperature (deg F)"
              error={errors.temperatureDegF?.message}
              {...extendRegister('temperatureDegF', {
                setValueAs: valueAsNumberOptional,
              })}
            />
            <TextBox
              type="number"
              label="Temp Ramp Up Time (min)"
              error={errors.tempRampUpTime?.message}
              {...extendRegister('tempRampUpTime', {
                setValueAs: valueAsNumberOptional,
              })}
            />
            <TextBox
              type="number"
              label="Initial Pressure (psig)"
              error={errors.pressure?.message}
              {...extendRegister('pressure', {
                setValueAs: valueAsNumberOptional,
              })}
            />
          </div>
          <div className="w-2/5 px-1">
            <TextBox
              type="number"
              label="Incubation Time (h)"
              error={errors.incubationTime?.message}
              {...extendRegister('incubationTime', {
                setValueAs: valueAsNumberOptional,
              })}
            />
            <Dropdown
              label="Location"
              {...extendRegister('location')}
              error={errors.location?.message}
              options={locations}
            />
          </div>
        </div>
      </Collapse.Content>
    </CollapseCustom>
  );
}

export default ExperimentInformation;
