import client from "../../lib/api/client";
import { useQuery, queryOptions } from "@tanstack/react-query";
import type { GetApiV1TechnicalServicesQueryResponse, GetApiV1TechnicalServices401, GetApiV1TechnicalServices403 } from "../models/GetApiV1TechnicalServices";
import type { QueryObserverOptions, UseQueryResult, QueryKey } from "@tanstack/react-query";

type GetApiV1TechnicalServicesClient = typeof client<GetApiV1TechnicalServicesQueryResponse, GetApiV1TechnicalServices401 | GetApiV1TechnicalServices403, never>;
type GetApiV1TechnicalServices = {
    data: GetApiV1TechnicalServicesQueryResponse;
    error: GetApiV1TechnicalServices401 | GetApiV1TechnicalServices403;
    request: never;
    pathParams: never;
    queryParams: never;
    headerParams: never;
    response: GetApiV1TechnicalServicesQueryResponse;
    client: {
        parameters: Partial<Parameters<GetApiV1TechnicalServicesClient>[0]>;
        return: Awaited<ReturnType<GetApiV1TechnicalServicesClient>>;
    };
};
export const getApiV1TechnicalServicesQueryKey = () => [{ url: "/api/v1/technical-services" }] as const;
export type GetApiV1TechnicalServicesQueryKey = ReturnType<typeof getApiV1TechnicalServicesQueryKey>;
export function getApiV1TechnicalServicesQueryOptions(options: GetApiV1TechnicalServices["client"]["parameters"] = {}) {
    const queryKey = getApiV1TechnicalServicesQueryKey();
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<GetApiV1TechnicalServices["data"], GetApiV1TechnicalServices["error"]>({
                method: "get",
                url: `/api/v1/technical-services`,
                ...options
            });
            return res.data;
        },
    });
}
/**
     * @link /api/v1/technical-services */
export function useGetApiV1TechnicalServices<TData = GetApiV1TechnicalServices["response"], TQueryData = GetApiV1TechnicalServices["response"], TQueryKey extends QueryKey = GetApiV1TechnicalServicesQueryKey>(options: {
    query?: Partial<QueryObserverOptions<GetApiV1TechnicalServices["response"], GetApiV1TechnicalServices["error"], TData, TQueryData, TQueryKey>>;
    client?: GetApiV1TechnicalServices["client"]["parameters"];
} = {}): UseQueryResult<TData, GetApiV1TechnicalServices["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getApiV1TechnicalServicesQueryKey();
    const query = useQuery({
        ...getApiV1TechnicalServicesQueryOptions(clientOptions) as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, GetApiV1TechnicalServices["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}