import React from 'react'
import { RequestLocation } from './types'

interface InitiateRequestButtonProps {
    location: RequestLocation,
    onClick?: (location: RequestLocation) => void,
}

export const InitiateRequestButton = ({location, onClick, ...rest}: InitiateRequestButtonProps & {className:string}) => {
    return <button onClick={() => onClick?.(location)} {...rest}>Create Request</button>
}