import React, { useEffect, useState } from 'react';
import { Input } from 'react-daisyui';

type DebouncedInputProps = {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
  placeholder?: string;
  className?: string;
  sizeProp?: 'sm' | 'lg' | 'md' | 'xs';
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>;

export const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  placeholder = '',
  className,
  sizeProp = 'sm',
}: DebouncedInputProps) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <Input
      value={value}
      size={sizeProp}
      bordered={true}
      onChange={(e) => setValue(e.target.value)}
      placeholder={placeholder}
      className={className}
    />
  );
};
