import { Button, Hero } from 'react-daisyui';

type WelcomeProps = { onClick: () => void };

const Welcome = ({ onClick }: WelcomeProps) => {
  return (
    <Hero>
      <Hero.Content className="text-center">
        <div className="max-w-md">
          <h1 className="text-5xl font-bold">LabSight</h1>
          <p className="py-6">Welcome to Stimulation Chemistry Technical Services</p>

          <Button color="primary" onClick={onClick}>
            Get Started
          </Button>
        </div>
      </Hero.Content>
    </Hero>
  );
};

export default Welcome;
