import { Controller } from 'react-hook-form';
import { TabProps } from './types';
import { Tabs } from 'react-daisyui';
import { DatePicker, Dropdown, TextBox } from '@components/form';
import { CONTENT_TAB_STYLE } from './constants';
import TabContent from './tab-content';
import TabContentCard from './tab-content-card';
import { DateValueType } from 'react-tailwindcss-datepicker';
import { Dispatch, SetStateAction } from 'react';
import useFormContextExtended from './useFormContextExtended';
import { useGetApiV1CatalogsDropdownsTypeid } from '@sdk-client/hooks';
import { CatalogType } from '@pages/Admin/DropDownListData/types';
import { LoadingRing } from '@components/loaders/LoadingRing';

type ProjectDetailsTabProps = {
  dateReadyCloseState: [DateValueType, Dispatch<SetStateAction<DateValueType>>];
} & TabProps;

const ProjectDetails = ({ indexTab, activeTab, setActiveTab, dateReadyCloseState }: ProjectDetailsTabProps) => {
  const [dateReadyCloseDate, setDateReadyCloseDate] = dateReadyCloseState;
  const { control, extendRegister, errors } = useFormContextExtended();

  const { data: statusData, isPending: isPendingStatusData } = useGetApiV1CatalogsDropdownsTypeid(
    Number(CatalogType.STATUS)
  );

  if (isPendingStatusData) return <LoadingRing />;

  return (
    <Tabs.RadioTab
      checked={activeTab === indexTab}
      onChange={() => setActiveTab(indexTab)}
      name="section"
      label="Project Details"
      contentClassName={CONTENT_TAB_STYLE}
    >
      <TabContent>
        <TabContentCard>
          <TextBox
            label="Project Number"
            {...extendRegister('projectNumber')}
          />
          <TextBox
            label="Project Title"
            {...extendRegister('projectTitle')}
          />
          <TextBox
            label="Project Type"
            {...extendRegister('projectType')}
          />
          <TextBox
            label="Network Code"
            {...extendRegister('networkCode')}
          />
        </TabContentCard>
        <TabContentCard>
          <TextBox
            label="Labor Code"
            {...extendRegister('laborCode')}
          />
          <TextBox
            label="Materials Code"
            {...extendRegister('materialsCode')}
          />
          <TextBox
            label="Services Code"
            {...extendRegister('servicesCode')}
          />
          <TextBox
            label="Travel Code"
            {...extendRegister('travelCode')}
          />
        </TabContentCard>
        <TabContentCard>
          <TextBox
            label="Project Lead"
            {...extendRegister('projectLead')}
          />
          <TextBox
            label="Project Lead Email"
            {...extendRegister('projectLeadEmail')}
          />
          <TextBox
            label="Shipping Address"
            {...extendRegister('shippingAddress')}
          />
          <TextBox
            label="Project Resources"
            {...extendRegister('projectResources')}
          />
        </TabContentCard>
        <TabContentCard>
          <Controller
            name="readyForCloseDate"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                label="Ready for Close Date"
                value={dateReadyCloseDate}
                onChange={(newValue) => {
                  field.onChange(newValue?.startDate);
                  field.value = newValue?.startDate as string;
                  setDateReadyCloseDate(newValue);
                }}
                error={error?.message}
              />
            )}
          />
          <Dropdown
            label="Status"
            {...extendRegister('status')}
            error={errors.status?.message}
            options={statusData}
          />
        </TabContentCard>
      </TabContent>
    </Tabs.RadioTab>
  );
};

export default ProjectDetails;
