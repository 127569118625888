import { TextBox, valueAsNumberOptional } from '@components/form';
import useFormContextExtended from '../useFormContextExtended';
import { schema } from '../../schema';
import PaddedDivision from '../padded-division';
import { useEffect } from 'react';
import { getHollowCylinderSourfaceArea } from './surface-area-calculations';

type HollowCylinderProps = {};

const HollowCylinder = (props: HollowCylinderProps) => {
  const { extendRegister, errors, watch, setValue } = useFormContextExtended(schema);
  const diameter1 = watch('hollowCylinder.diameter1');
  const diameter2 = watch('hollowCylinder.diameter2');
  const length = watch('hollowCylinder.length');

  useEffect(() => {
    const sa = getHollowCylinderSourfaceArea(diameter1, diameter2, length);
    const roundedSa = Number(sa.toFixed(4));
    setValue('hollowCylinder.surfaceArea', roundedSa);
    setValue('surfaceArea', roundedSa);
  }, [diameter1, diameter2, length, setValue]);

  return (
    <PaddedDivision>
      <TextBox
        type="number"
        label="Diameter 1 (D1), in "
        error={errors.hollowCylinder?.diameter1?.message}
        {...extendRegister('hollowCylinder.diameter1', {
          setValueAs: valueAsNumberOptional,
        })}
      />
      <TextBox
        type="number"
        label="Diameter 2 (D2), in"
        error={errors.hollowCylinder?.diameter2?.message}
        {...extendRegister('hollowCylinder.diameter2', {
          setValueAs: valueAsNumberOptional,
        })}
      />
      <TextBox
        type="number"
        label="Length (L), in"
        error={errors.hollowCylinder?.length?.message}
        {...extendRegister('hollowCylinder.length', {
          setValueAs: valueAsNumberOptional,
        })}
      />
      <TextBox
        type="number"
        readOnly={true}
        label={
          <span>
            Surface Area, in <sup>2</sup>
          </span>
        }
        error={errors.hollowCylinder?.surfaceArea?.message}
        {...extendRegister('hollowCylinder.surfaceArea', {
          setValueAs: valueAsNumberOptional,
        })}
      />
    </PaddedDivision>
  );
};

export default HollowCylinder;
