import { OptionRelated, Option } from '@components/form/Dropdown/types';
import { shapeEnum } from './schema';

export const equipments: OptionRelated[] = [
  {
    id: '1',
    value: '1',
    text: 'Polyblock A',
    relatedObject: 'cellNumber',
    relatedKeys: ['1', '2', '3', '4', '5', '6', '7', '8'],
  },
  {
    id: '2',
    value: '2',
    text: 'Polyblock B',
    relatedObject: 'cellNumber',
    relatedKeys: ['1', '2', '3', '4', '5', '6', '7', '8'],
  },
  {
    id: '3',
    value: '3',
    text: 'Parr 3000A',
    relatedObject: 'cellNumber',
    relatedKeys: ['1', '2', '3', '4'],
  },
  {
    id: '4',
    value: '4',
    text: 'Parr 3000B',
    relatedObject: 'cellNumber',
    relatedKeys: ['1', '2', '3', '4'],
  },
  {
    id: '5',
    value: '5',
    text: 'Parr 3000C',
    relatedObject: 'cellNumber',
    relatedKeys: ['1', '2', '3', '4'],
  },
  {
    id: '6',
    value: '6',
    text: 'Parr 3000D',
    relatedObject: 'cellNumber',
    relatedKeys: ['1', '2', '3', '4'],
  },
  {
    id: '7',
    value: '7',
    text: 'Parr 6000A',
    relatedObject: 'cellNumber',
    relatedKeys: ['1'],
  },
];

export const cellNumbers: Option[] = [
  { id: '1', value: '1', text: '1' },
  { id: '2', value: '2', text: '2' },
  { id: '3', value: '3', text: '3' },
  { id: '4', value: '4', text: '4' },
  { id: '5', value: '5', text: '5' },
  { id: '6', value: '6', text: '6' },
  { id: '7', value: '7', text: '7' },
  { id: '8', value: '8', text: '8' },
];
export const metallurgies: Option[] = [
  { id: '1', value: '1Cr', text: '1Cr' },
  { id: '2', value: '1018', text: '1018' },
  { id: '3', value: '2205', text: '2205' },
  { id: '4', value: '13Cr', text: '13Cr' },
  { id: '5', value: '13Cr110 Modified', text: '13Cr110 Modified' },
  { id: '7', value: '13Cr110 Super', text: '13Cr110 Super' },
  { id: '9', value: '13Cr80', text: '13Cr80' },
  { id: '10', value: '13Cr85', text: '13Cr85' },
  { id: '11', value: '13Cr-L80', text: '13Cr-L80' },
  { id: '12', value: '15Cr125', text: '15Cr125' },
  { id: '13', value: '22Cr125', text: '22Cr125' },
  { id: '14', value: '25Cr125', text: '25Cr125' },
  { id: '15', value: '25CrW125', text: '25CrW125' },
  { id: '16', value: '28-110(29 ppf)', text: '28-110(29 ppf)' },
  { id: '17', value: '316 SS', text: '316 SS' },
  { id: '18', value: '316L', text: '316L' },
  { id: '19', value: '5LX65', text: '5LX65' },
  { id: '20', value: '7075-T6', text: '7075-T6' },
  { id: '21', value: 'ATP-140CT (QT-55884)', text: 'ATP-140CT (QT-55884)' },
  { id: '22', value: 'ATP-140CT (QT-55885)', text: 'ATP-140CT (QT-55885)' },
  { id: '23', value: 'C1026', text: 'C1026' },
  { id: '24', value: 'C-110', text: 'C-110' },
  { id: '25', value: 'C4130', text: 'C4130' },
  { id: '26', value: 'C4140', text: 'C4140' },
  { id: '27', value: 'C4330', text: 'C4330' },
  { id: '28', value: 'C90', text: 'C90' },
  { id: '29', value: 'CDA268 (Yellow Brass)', text: 'CDA268 (Yellow Brass)' },
  { id: '30', value: 'CDA377 (Yellow Brass)', text: 'CDA377 (Yellow Brass)' },
  { id: '31', value: 'CDA464 (Naval Brass)', text: 'CDA464 (Naval Brass)' },
  { id: '32', value: 'CDA510 (Phosphorus Bronze)', text: 'CDA510 (Phosphorus Bronze)' },
  { id: '33', value: 'CDA630 (Aluminum Bronze)', text: 'CDA630 (Aluminum Bronze)' },
  { id: '34', value: 'CDA836 (Red Brass)', text: 'CDA836 (Red Brass)' },
  { id: '35', value: 'DC-110', text: 'DC-110' },
  { id: '36', value: 'Elgiloy', text: 'Elgiloy' },
  { id: '37', value: 'F9 (A387) or 9Cr', text: 'F9 (A387) or 9Cr' },
  { id: '38', value: 'GREYCAST', text: 'GREYCAST' },
  { id: '39', value: 'GT-100', text: 'GT-100' },
  { id: '40', value: 'GT-90', text: 'GT-90' },
  { id: '41', value: 'HT-95', text: 'HT-95' },
  { id: '42', value: 'I625', text: 'I625' },
  { id: '43', value: 'I718', text: 'I718' },
  { id: '44', value: 'I825', text: 'I825' },
  { id: '45', value: 'I925', text: 'I925' },
  { id: '46', value: 'J55', text: 'J55' },
  { id: '47', value: 'K55', text: 'K55' },
  { id: '48', value: 'L-80', text: 'L-80' },
  { id: '49', value: 'M400', text: 'M400' },
  { id: '50', value: 'N-80', text: 'N-80' },
  { id: '51', value: 'NIT50 (22CR)', text: 'NIT50 (22CR)' },
  { id: '52', value: 'NT-90', text: 'NT-90' },
  { id: '53', value: 'P-110', text: 'P-110' },
  { id: '54', value: 'P-110ICY', text: 'P-110ICY' },
  { id: '55', value: 'P-110S', text: 'P-110S' },
  { id: '56', value: 'P550', text: 'P550' },
  { id: '57', value: 'PR140-6084/1', text: 'PR140-6084/1' },
  { id: '58', value: 'Q125', text: 'Q125' },
  { id: '59', value: 'QT 900', text: 'QT 900' },
  { id: '60', value: 'QT-1300', text: 'QT-1300' },
  { id: '61', value: 'S135', text: 'S135' },
  { id: '62', value: 'S13CrS110', text: 'S13CrS110' },
  { id: '63', value: 'SAN28', text: 'SAN28' },
  { id: '64', value: 'SAP 2205', text: 'SAP 2205' },
  { id: '65', value: 'SM13CrS110', text: 'SM13CrS110' },
  { id: '66', value: 'SM13CrS95', text: 'SM13CrS95' },
  { id: '67', value: 'SM2535-110', text: 'SM2535-110' },
  { id: '68', value: 'SM2550-110', text: 'SM2550-110' },
  { id: '69', value: 'SM2550-125', text: 'SM2550-125' },
  { id: '70', value: 'SM25Cr110', text: 'SM25Cr110' },
  { id: '71', value: 'SM25CrW125', text: 'SM25CrW125' },
  { id: '72', value: 'SS2205', text: 'SS2205' },
  { id: '73', value: 'T-304', text: 'T-304' },
  { id: '74', value: 'T95', text: 'T95' },
  { id: '75', value: 'Zeron 100', text: 'Zeron 100' },
];
export const shapes: Option[] = [
  {
    id: '1',
    value: shapeEnum.Enum.CylinderWithHole,
    text: 'Hollow Cylinder',
  },
  {
    id: '2',
    value: shapeEnum.Enum.CylinderNoHole,
    text: 'Cylinder',
  },
  //{ key: '3', value: 'sphere', text: 'Sphere' },
  {
    id: '4',
    value: shapeEnum.Enum.CurvedNoHole,
    text: 'Curved Sample (no hole)',
  },
  {
    id: '5',
    value: shapeEnum.Enum.CurvedWithHole,
    text: 'Curved Sample (with hole)',
  },
  {
    id: '6',
    value: shapeEnum.Enum.FlatNoHole,
    text: 'Flat Rectangular/Square (no hole)',
  },
  {
    id: '7',
    value: shapeEnum.Enum.FlatWithHole,
    text: 'Flat Rectangular/Square (with hole)',
  },
  {
    id: '8',
    value: shapeEnum.Enum.CustomShape,
    text: 'Custom Shape',
  },
  // {
  //   key: '8',
  //   value: 'flatCircleNoHole',
  //   text: 'Flat Circle (no hole)',
  // },
  // {
  //   key: '9',
  //   value: 'flatCircleWithHole',
  //   text: 'Flat Circle (with hole)',
  // },
];

/************ ACID BLEND RECIPE SOURCE *******************/

export const additiveType: OptionRelated[] = [
  {
    id: '1',
    value: 'solvent',
    text: 'Solvent',
    relatedObject: 'additive',
    relatedKeys: ['water', 'musol'],
  },
  {
    id: '2',
    value: 'corrosion_inhibitor',
    text: 'Corrosion Inhibitor',
    relatedObject: 'additive',
    relatedKeys: ['HAI-404M', 'HAI-500M', 'HAI-501', 'HAI-601', 'HAI-602', 'HAI-701', 'HAI-OS'],
  },
  {
    id: '3',
    value: 'ci_intensifier',
    text: 'CI Intensifier',
    relatedObject: 'additive',
    relatedKeys: ['HII-124B', 'HII-124C', 'HII-124F', 'HII-500M', 'HII-600'],
  },
  {
    id: '4',
    value: 'acid',
    text: 'Acid',
    relatedObject: 'additive',
    relatedKeys: ['20_be_hcl', '22_be_hcl'],
  },
  {
    id: '5',
    value: 'anti_sludge_agent',
    text: 'Anti-Sludge Agent',
    relatedObject: 'additive',
    relatedKeys: ['AS-5', 'AS-7', 'AS-9', 'AS-10', 'AS-11', 'AS-12', 'AS-S1'],
  },
  {
    id: '6',
    value: 'surfactant',
    text: 'Surfactant',
    relatedObject: 'additive',
    relatedKeys: [
      'LoSurf-2000L',
      'LoSurf-259',
      'LoSurf-300D',
      'LoSurf-300M',
      'LoSurf-357',
      'LoSurf-360',
      'LoSurf-360W',
      'LoSurf-396',
      'LoSurf-400',
      'LoSurf-4000L',
      'LoSurf-500',
    ],
  },
  {
    id: '7',
    value: 'iron_control',
    text: 'Iron Control',
    relatedObject: 'additive',
    relatedKeys: ['FE-1A', 'FE-2', 'FERCHEK'],
  },
  {
    id: '8',
    value: 'gelling_agent',
    text: 'Gelling Agent',
    relatedObject: 'additive',
    relatedKeys: ['SGA-I', 'SGA-II', 'SGA-III', 'SGA-V', 'SGA-7', 'SGA-HT_M', 'SGA-VXL'],
  },
  {
    id: '9',
    value: 'h2s_scavenger',
    text: 'H2S Scavenger',
    relatedObject: 'additive',
    relatedKeys: ['SGA-II'],
  },
  {
    id: '10',
    value: 'other',
    text: 'Other',
    relatedObject: 'additive',
    relatedKeys: ['alchek'],
  },
];

export const additive: OptionRelated[] = [
  {
    id: '1',
    value: 'water',
    text: 'Water',
    relatedObject: 'additiveType',
    relatedKeys: ['solvent'],
  },
  {
    id: '2',
    value: 'musol',
    text: 'Musol',
    relatedObject: 'additiveType',
    relatedKeys: ['solvent'],
  },
  {
    id: '3',
    value: 'HAI-404M',
    text: 'HAI-404M',
    relatedObject: 'additiveType',
    relatedKeys: ['corrosion_inhibitor'],
  },
  {
    id: '4',
    value: 'HAI-500M',
    text: 'HAI-500M',
    relatedObject: 'additiveType',
    relatedKeys: ['corrosion_inhibitor'],
  },
  {
    id: '5',
    value: 'HAI-501',
    text: 'HAI-501',
    relatedObject: 'additiveType',
    relatedKeys: ['corrosion_inhibitor'],
  },
  {
    id: '6',
    value: 'HAI-601',
    text: 'HAI-601',
    relatedObject: 'additiveType',
    relatedKeys: ['corrosion_inhibitor'],
  },
  {
    id: '7',
    value: 'HAI-602',
    text: 'HAI-602',
    relatedObject: 'additiveType',
    relatedKeys: ['corrosion_inhibitor'],
  },
  {
    id: '8',
    value: 'HAI-701',
    text: 'HAI-701',
    relatedObject: 'additiveType',
    relatedKeys: ['corrosion_inhibitor'],
  },
  {
    id: '9',
    value: 'HAI-OS',
    text: 'HAI-OS',
    relatedObject: 'additiveType',
    relatedKeys: ['corrosion_inhibitor'],
  },
  {
    id: '10',
    value: 'HII-124B',
    text: 'HII-124B',
    relatedObject: 'additiveType',
    relatedKeys: ['ci_intensifier'],
  },
  {
    id: '11',
    value: 'HII-124C',
    text: 'HII-124C',
    relatedObject: 'additiveType',
    relatedKeys: ['ci_intensifier'],
  },
  {
    id: '12',
    value: 'HII-124F',
    text: 'HII-124F',
    relatedObject: 'additiveType',
    relatedKeys: ['ci_intensifier'],
  },
  {
    id: '13',
    value: 'HII-500M',
    text: 'HII-500M',
    relatedObject: 'additiveType',
    relatedKeys: ['ci_intensifier'],
  },
  {
    id: '14',
    value: 'HII-600',
    text: 'HII-600',
    relatedObject: 'additiveType',
    relatedKeys: ['ci_intensifier'],
  },

  {
    id: '15',
    value: '20_be_hcl',
    text: '20 Be HCl (31.5%)',
    relatedObject: 'additiveType',
    relatedKeys: ['acid'],
  },
  {
    id: '16',
    value: '22_be_hcl',
    text: '22 Be HCl (36%)',
    relatedObject: 'additiveType',
    relatedKeys: ['acid'],
  },
  {
    id: '17',
    value: 'AS-5',
    text: 'AS-5',
    relatedObject: 'additiveType',
    relatedKeys: ['anti_sludge_agent'],
  },
  {
    id: '18',
    value: 'AS-7',
    text: 'AS-7',
    relatedObject: 'additiveType',
    relatedKeys: ['anti_sludge_agent'],
  },
  {
    id: '19',
    value: 'AS-9',
    text: 'AS-9',
    relatedObject: 'additiveType',
    relatedKeys: ['anti_sludge_agent'],
  },
  {
    id: '20',
    value: 'AS-10',
    text: 'AS-10',
    relatedObject: 'additiveType',
    relatedKeys: ['anti_sludge_agent'],
  },
  {
    id: '21',
    value: 'AS-11',
    text: 'AS-11',
    relatedObject: 'additiveType',
    relatedKeys: ['anti_sludge_agent'],
  },
  {
    id: '22',
    value: 'AS-12',
    text: 'AS-12',
    relatedObject: 'additiveType',
    relatedKeys: ['anti_sludge_agent'],
  },
  {
    id: '23',
    value: 'AS-S1',
    text: 'AS-S1',
    relatedObject: 'additiveType',
    relatedKeys: ['anti_sludge_agent'],
  },

  {
    id: '24',
    value: 'LoSurf-2000L',
    text: 'LoSurf-2000L',
    relatedObject: 'additiveType',
    relatedKeys: ['surfactant'],
  },
  {
    id: '25',
    value: 'LoSurf-259',
    text: 'LoSurf-259',
    relatedObject: 'additiveType',
    relatedKeys: ['surfactant'],
  },
  {
    id: '26',
    value: 'LoSurf-300D',
    text: 'LoSurf-300D',
    relatedObject: 'additiveType',
    relatedKeys: ['surfactant'],
  },
  {
    id: '27',
    value: 'LoSurf-300M',
    text: 'LoSurf-300M',
    relatedObject: 'additiveType',
    relatedKeys: ['surfactant'],
  },
  {
    id: '28',
    value: 'LoSurf-357',
    text: 'LoSurf-357',
    relatedObject: 'additiveType',
    relatedKeys: ['surfactant'],
  },
  {
    id: '29',
    value: 'LoSurf-360',
    text: 'LoSurf-360',
    relatedObject: 'additiveType',
    relatedKeys: ['surfactant'],
  },
  {
    id: '30',
    value: 'LoSurf-360W',
    text: 'LoSurf-360W',
    relatedObject: 'additiveType',
    relatedKeys: ['surfactant'],
  },
  {
    id: '31',
    value: 'LoSurf-396',
    text: 'LoSurf-396',
    relatedObject: 'additiveType',
    relatedKeys: ['surfactant'],
  },
  {
    id: '32',
    value: 'LoSurf-400',
    text: 'LoSurf-400',
    relatedObject: 'additiveType',
    relatedKeys: ['surfactant'],
  },
  {
    id: '33',
    value: 'LoSurf-4000L',
    text: 'LoSurf-4000L',
    relatedObject: 'additiveType',
    relatedKeys: ['surfactant'],
  },
  {
    id: '34',
    value: 'LoSurf-500',
    text: 'LoSurf-500',
    relatedObject: 'additiveType',
    relatedKeys: ['surfactant'],
  },

  {
    id: '35',
    value: 'FE-1A',
    text: 'FE-1A',
    relatedObject: 'additiveType',
    relatedKeys: ['iron_control'],
  },
  {
    id: '36',
    value: 'FE-2',
    text: 'FE-2',
    relatedObject: 'additiveType',
    relatedKeys: ['iron_control'],
  },
  {
    id: '37',
    value: 'FERCHEK',
    text: 'FERCHEK',
    relatedObject: 'additiveType',
    relatedKeys: ['iron_control'],
  },

  {
    id: '38',
    value: 'SGA-I',
    text: 'SGA-I',
    relatedObject: 'additiveType',
    relatedKeys: ['gelling_agent'],
  },
  {
    id: '39',
    value: 'SGA-II',
    text: 'SGA-II',
    relatedObject: 'additiveType',
    relatedKeys: ['gelling_agent', 'h2s_scavenger'],
  },
  {
    id: '40',
    value: 'SGA-III',
    text: 'SGA-III',
    relatedObject: 'additiveType',
    relatedKeys: ['gelling_agent'],
  },
  {
    id: '41',
    value: 'SGA-V',
    text: 'SGA-V',
    relatedObject: 'additiveType',
    relatedKeys: ['gelling_agent'],
  },
  {
    id: '42',
    value: 'SGA-7',
    text: 'SGA-7',
    relatedObject: 'additiveType',
    relatedKeys: ['gelling_agent'],
  },
  {
    id: '43',
    value: 'SGA-HT_M',
    text: 'SGA-HT M',
    relatedObject: 'additiveType',
    relatedKeys: ['gelling_agent'],
  },
  {
    id: '44',
    value: 'SGA-VXL',
    text: 'SGA-VXL',
    relatedObject: 'additiveType',
    relatedKeys: ['gelling_agent'],
  },
  {
    id: '46',
    value: 'alchek',
    text: 'Alchek',
    relatedObject: 'additiveType',
    relatedKeys: ['other'],
  },
];
export const physicalState: OptionRelated[] = [
  {
    id: '1',
    value: 'liquid',
    text: 'Liquid',
    relatedObject: 'additive',
    relatedKeys: [
      'water',
      'musol',
      'HAI-404M',
      'HAI-500M',
      'HAI-501',
      'HAI-601',
      'HAI-602',
      'HAI-701',
      'HAI-OS',
      'HII-124F',
      'HII-500M',
      'HII-600',
      '20_be_hcl',
      '22_be_hcl',
      'AS-5',
      'AS-7',
      'AS-9',
      'AS-10',
      'AS-11',
      'AS-12',
      'AS-S1',
      'LoSurf-2000L',
      'LoSurf-259',
      'LoSurf-300D',
      'LoSurf-300M',
      'LoSurf-357',
      'LoSurf-360',
      'LoSurf-360W',
      'LoSurf-396',
      'LoSurf-400',
      'LoSurf-4000L',
      'LoSurf-500',
      'FE-1A',
      'FE-2',
      'FERCHEK',
      'SGA-I',
      'SGA-II',
      'SGA-III',
      'SGA-V',
      'SGA-7',
      'SGA-HT_M',
      'SGA-VXL',
      'alchek',
    ],
  },
  {
    id: '2',
    value: 'solid',
    text: 'Solid',
    relatedObject: 'additive',
    relatedKeys: ['HII-124B', 'HII-124C'],
  },
];

/************ RESULTS SOURCE *******************/

export const pittingIndexSource: Option[] = [
  { id: '0', value: '0', text: '0' },
  { id: '1', value: '1', text: '1' },
  { id: '2', value: '2', text: '2' },
  { id: '3', value: '3', text: '3' },
  { id: '4', value: '4', text: '4' },
  { id: '5', value: '5', text: '5' },
];
