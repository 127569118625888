import { Rating } from 'react-daisyui';
import './chem-process-item.css';
import { Link } from 'react-router-dom';
import { Process } from '../types';
import { useState } from 'react';

type ChemProcessItemProps = {
  item: Process;
};
const ChemProcessItem = ({ item }: ChemProcessItemProps) => {
  const [rating, setRating] = useState(0);
  const handleRatingChange = () => setRating((prev) => (prev === 1 ? 0 : 1));

  return (
    <div className="relative flex gap-x-3 rounded-lg bg-white p-4 shadow-md">
      <Rating
        value={rating}
        onChange={handleRatingChange}
        size="md"
      >
        <Rating.Item
          name="rating-2"
          className="mask mask-star-2 bg-orange-400"
        />
      </Rating>
      <div className="text-sm leading-6">
        <Link to={item.linkTo}>
          <span className="font-medium text-gray-900">{item.name}</span>
        </Link>
      </div>
    </div>
  );
};

export default ChemProcessItem;
