import { TextBox, valueAsNumberOptional } from '@components/form';
import PaddedDivision from '../padded-division';
import useFormContextExtended from '../useFormContextExtended';
import { schema } from '../../schema';
import { useEffect } from 'react';
import { getFlatRectangularSquareWithHoleSurfaceArea } from './surface-area-calculations';

type FlatRectangularSquareWithHoleProps = {};

const FlatRectangularSquareWithHole = (props: FlatRectangularSquareWithHoleProps) => {
  const { extendRegister, errors, watch, setValue } = useFormContextExtended(schema);
  const length = watch('flatRectangularSquareWithHole.length');
  const width = watch('flatRectangularSquareWithHole.width');
  const thickness = watch('flatRectangularSquareWithHole.thickness');
  const holeDiameter = watch('flatRectangularSquareWithHole.holeDiameter');

  useEffect(() => {
    const sa = getFlatRectangularSquareWithHoleSurfaceArea(length, width, thickness, holeDiameter);
    const roundedSa = Number(sa.toFixed(4));
    setValue('flatRectangularSquareWithHole.surfaceArea', roundedSa);
    setValue('surfaceArea', roundedSa);
  }, [length, width, thickness, holeDiameter, setValue]);

  return (
    <PaddedDivision>
      <TextBox
        type="number"
        label="Length (L), in"
        error={errors.flatRectangularSquareWithHole?.length?.message}
        {...extendRegister('flatRectangularSquareWithHole.length', {
          setValueAs: valueAsNumberOptional,
        })}
      />
      <TextBox
        type="number"
        label="Width (W), in"
        error={errors.flatRectangularSquareWithHole?.width?.message}
        {...extendRegister('flatRectangularSquareWithHole.width', {
          setValueAs: valueAsNumberOptional,
        })}
      />
      <TextBox
        type="number"
        label="Thickness (T), in"
        error={errors.flatRectangularSquareWithHole?.thickness?.message}
        {...extendRegister('flatRectangularSquareWithHole.thickness', {
          setValueAs: valueAsNumberOptional,
        })}
      />
      <TextBox
        type="number"
        label="Hole Diameter (D), in"
        error={errors.flatRectangularSquareWithHole?.holeDiameter?.message}
        {...extendRegister('flatRectangularSquareWithHole.holeDiameter', {
          setValueAs: valueAsNumberOptional,
        })}
      />
      <TextBox
        type="number"
        readOnly={true}
        label={
          <span>
            Surface Area, in <sup>2</sup>
          </span>
        }
        error={errors.flatRectangularSquareWithHole?.surfaceArea?.message}
        {...extendRegister('flatRectangularSquareWithHole.surfaceArea', {
          setValueAs: valueAsNumberOptional,
        })}
      />
    </PaddedDivision>
  );
};

export default FlatRectangularSquareWithHole;
