import {
  curvedWithHole,
  curvedNoHole,
  flatWithHole,
  flatNoHole,
  cylinderWithHole,
  cylinderNoHole,
  customShape,
} from '@images/corrosion-coupon-renders/';
import Cylinder from './shapes-surface-area/cylinder';
import HollowCylinder from './shapes-surface-area/hollow-cylinder';
import CurvedSampleWithHole from './shapes-surface-area/curved-sample-with-hole';
import FlatRectangularSquareNoHole from './shapes-surface-area/flat-rectangular-square-no-hole';
import FlatRectangularSquareWithHole from './shapes-surface-area/flat-rectangular-square-with-hole';
import CurvedSampleNoHole from './shapes-surface-area/curved-sample-no-hole';
import Sphere from './shapes-surface-area/sphere';
import FlatCircleNoHole from './shapes-surface-area/flat-circle-no-hole';
import FlatCircleWithHole from './shapes-surface-area/flat-circle-with-hole';
import { useEffect } from 'react';
import useFormContextExtended from './useFormContextExtended';
import { ShapeType, schema, shapeEnum } from '../schema';
import { CustomShape } from './shapes-surface-area/CustomShape';

// the value of Shapes is the value of the value property of the shapes source

type ShapeSurfaceAreaProps = {
  shape: ShapeType;
};

const couponImages = {
  [shapeEnum.Enum.CurvedWithHole]: curvedWithHole,
  [shapeEnum.Enum.CurvedNoHole]: curvedNoHole,
  [shapeEnum.Enum.FlatWithHole]: flatWithHole,
  [shapeEnum.Enum.FlatNoHole]: flatNoHole,
  [shapeEnum.Enum.CylinderWithHole]: cylinderWithHole,
  [shapeEnum.Enum.CylinderNoHole]: cylinderNoHole,
  [shapeEnum.Enum.CustomShape]: customShape,
  [shapeEnum.Enum.Sphere]: 'https://via.placeholder.com/420x320/fff/333333?text=Sample',
  [shapeEnum.Enum.FlatCircleWithHole]: 'https://via.placeholder.com/420x320/fff/333333?text=Sample',
  [shapeEnum.Enum.FlatCircleNoHole]: 'https://via.placeholder.com/420x320/fff/333333?text=Sample',
};

const ShapeSurfaceArea = ({ shape }: ShapeSurfaceAreaProps) => {
  const { unregister } = useFormContextExtended(schema);
  useEffect(() => {
    // Cleaup properties when the shape changes
    if (Object.getOwnPropertyNames(shapeEnum.Enum).includes(shape)) {
      if (shape !== shapeEnum.Enum.CylinderNoHole) unregister('cylinder');
      if (shape !== shapeEnum.Enum.CylinderWithHole) unregister('hollowCylinder');
      if (shape !== shapeEnum.Enum.CurvedWithHole) unregister('curvedSampleWithHole');
      if (shape !== shapeEnum.Enum.CurvedNoHole) unregister('curvedSampleNoHole');
      if (shape !== shapeEnum.Enum.FlatNoHole) unregister('flatRectangularSquareNoHole');
      if (shape !== shapeEnum.Enum.FlatWithHole) unregister('flatRectangularSquareWithHole');
      if (shape !== shapeEnum.Enum.Sphere) unregister('sphere');
      if (shape !== shapeEnum.Enum.FlatCircleNoHole) unregister('flatCircleNoHole');
      if (shape !== shapeEnum.Enum.FlatCircleWithHole) unregister('flatCircleWithHole');
      if (shape !== shapeEnum.Enum.CustomShape) unregister('customShape');
    }
  }, [shape, unregister]);
  return (
    <>
      <div className="flex flex-col justify-center">
        {shape && (
          <img
            src={couponImages[shape]}
            alt={`Shape ${shape}`}
            className="w-96 h-96 object-contain"
          />
        )}
      </div>
      {shape === shapeEnum.Enum.CylinderNoHole && <Cylinder />}
      {shape === shapeEnum.Enum.CylinderWithHole && <HollowCylinder />}
      {shape === shapeEnum.Enum.CurvedWithHole && <CurvedSampleWithHole />}
      {shape === shapeEnum.Enum.CurvedNoHole && <CurvedSampleNoHole />}
      {shape === shapeEnum.Enum.FlatNoHole && <FlatRectangularSquareNoHole />}
      {shape === shapeEnum.Enum.FlatWithHole && <FlatRectangularSquareWithHole />}
      {shape === shapeEnum.Enum.Sphere && <Sphere />}
      {shape === shapeEnum.Enum.FlatCircleNoHole && <FlatCircleNoHole />}
      {shape === shapeEnum.Enum.FlatCircleWithHole && <FlatCircleWithHole />}
      {shape === shapeEnum.Enum.CustomShape && <CustomShape />}
    </>
  );
};

export default ShapeSurfaceArea;
