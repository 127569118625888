import { DebouncedInput } from '@components/form/DebouncedInput';
import { useCallback, useRef } from 'react';
import { Button, Checkbox, Modal } from 'react-daisyui';
import { FunnelIcon, XMarkIcon } from '@heroicons/react/24/outline';

type GlobalFilterProps = {
  globalFilter: string;
  setGlobalFilter: (value: string) => void;
  columnsFilterables: ColumnFilterable[];
  setColumnsFilterables: (value: ColumnFilterable[]) => void;
};

export type ColumnFilterable = {
  headerName: string;
  accessorKey: string;
  filterable: boolean;
};

export const GlobalFilter = ({
  globalFilter,
  setGlobalFilter,
  columnsFilterables,
  setColumnsFilterables,
}: GlobalFilterProps) => {
  const ref = useRef<HTMLDialogElement>(null);
  const handleShow = useCallback(() => {
    ref.current?.showModal();
  }, [ref]);

  function updateFilterable(accessorKey: string, newFilterableValue: boolean) {
    const updatedColumnsFilterables = columnsFilterables.map((column) => {
      if (column.accessorKey === accessorKey) {
        return { ...column, filterable: newFilterableValue };
      }
      return column;
    });
    setColumnsFilterables(updatedColumnsFilterables);
  }

  return (
    <>
      <DebouncedInput
        value={globalFilter ?? ''}
        onChange={(value) => setGlobalFilter(String(value))}
        placeholder="Search all columns..."
      />
      <div className="font-sans">
        <Button
          size="xs"
          onClick={handleShow}
          endIcon={<FunnelIcon className="w-5 h-5" />}
        />
        <Modal ref={ref}>
          <Modal.Header className="font-bold">Select columns to include in global filter</Modal.Header>
          <Modal.Body>
            <div className="flex flex-wrap -mx-1">
              {columnsFilterables.map((col, index) => (
                <div
                  className="flex w-1/2 p-1"
                  key={index}
                >
                  <div className="flex flex-grow items-center gap-2">
                    <Checkbox
                      id={col.accessorKey}
                      size="sm"
                      checked={col.filterable}
                      onChange={(e) => {
                        updateFilterable(col.accessorKey, e.target.checked);
                      }}
                    />
                    {col.headerName}
                  </div>
                </div>
              ))}
            </div>
          </Modal.Body>
          <Modal.Actions>
            <form method="dialog">
              <Button
                size="xs"
                endIcon={<XMarkIcon className="w-5 h-5" />}
              >
                Close
              </Button>
            </form>
          </Modal.Actions>
        </Modal>
      </div>
    </>
  );
};
