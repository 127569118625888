import { CONVERSION_FACTORS } from './recipe-table/components/constants';

const convertInfinityToZero = (value: number): number => {
  return value === Infinity ? 0 : value;
};

/**
 * function to get weight loss
 * @param initialWeight
 * @param finalWeight
 * @returns g
 */
export const getWeightLoss = (initialWeight: number, finalWeight: number) => {
  return initialWeight - finalWeight;
};

/**
 * function to get conversion factor
 * @param surfaceArea_in2
 * @returns
 */
export const getConversionFactor = (surfaceArea_in2: number) => {
  return 144 / (CONVERSION_FACTORS.g_lb * surfaceArea_in2);
};

/**
 * function to get corrosion loss
 * @param initialWeight
 * @param finalWeight
 * @param surfaceArea_in2
 * @returns  [lb/ft²]
 */
export const getCorrosionLoss = (initialWeight: number, finalWeight: number, surfaceArea_in2: number) => {
  return convertInfinityToZero(getWeightLoss(initialWeight, finalWeight) * getConversionFactor(surfaceArea_in2));
};

/**
 * function to get corrosion rate
 * @param initialWeight
 * @param finalWeight
 * @param metallurgyDensity
 * @param surfaceArea_in2
 * @param testDuration
 * @returns [mpy]
 */
export const getCorrosionRate_mpy = (
  initialWeight: number,
  finalWeight: number,
  metallurgyDensity: number,
  surfaceArea_in2: number,
  testDuration: number
) => {
  return convertInfinityToZero(
    getCorrosionRate_mmpy(initialWeight, finalWeight, metallurgyDensity, surfaceArea_in2, testDuration) / 0.0254
  );
};

/**
 * function to get corrosion rate
 * @param initialWeight
 * @param finalWeight
 * @param metallurgyDensity
 * @param surfaceArea_in2
 * @param testDuration
 * @returns [mmpy]
 */
export const getCorrosionRate_mmpy = (
  initialWeight: number,
  finalWeight: number,
  metallurgyDensity: number,
  surfaceArea_in2: number,
  testDuration: number
): number => {
  return convertInfinityToZero(
    87.6 *
      ((getWeightLoss(initialWeight, finalWeight) * 1000) /
        (metallurgyDensity * (surfaceArea_in2 * 6.4516) * testDuration))
  );
};
