import { RequestLocation } from './components/types';
import { RequestLocationCard } from './components/request-location-card';
import { HoustonTechCenter, puneTechCenter } from '@assets/images/ticketing-home';

interface RequestFormSelectionProps {
  onSelectionMade?: (location: RequestLocation) => void;
}

export const RequestFormSelection = ({ onSelectionMade }: RequestFormSelectionProps) => {
  return (
    <div className="flex flex-col">
      <div className="flex justify-center">
        <div className="flex">
          <RequestLocationCard
            imageSrc={HoustonTechCenter}
            location="Houston"
            onSelectionMade={onSelectionMade}
            requestCardPrompt="Please click create request below in order to initiate a project through Houston Tech Services"
          />
          <RequestLocationCard
            location="Pune"
            imageSrc={puneTechCenter}
            onSelectionMade={onSelectionMade}
            requestCardPrompt="Please click create request below in order to initiate a project through Pune Tech Services"
          />
        </div>
      </div>
      <div className="flex justify-center">
        <div className="flex flex-col text-center">
          <p className="m-2">
            For general questions, please contact{' '}
            <a
              href="mailto:FHOUPETECHSVCS@Halliburton.com"
              className="text-primary hover:underline"
            >
              FHOUPETECHSVCS@Halliburton.com
            </a>
          </p>
          <p className="m-2">Houston Shipping Address: 15081 1/2 Milner Rd, Gate 3, Houston, TX 77032</p>
          <p className="m-2">Pune Shipping Address: Halliburton Technology Center, Attention: Lalit Salgaonkar,</p>
          <p className="m-2">Sai Radhe Building 100 + 101 Kennedy Road, Pune 411001 India</p>
        </div>
      </div>
    </div>
  );
};
