import React, { useState, useRef, useCallback, useEffect } from 'react';
import { CouponImageResultType } from '../schema';
import { useGetApiV1ExperimentsCorrosionExperimentidResultsImageType } from '@sdk-client/hooks';
import { LoadingRing } from '@components/loaders/LoadingRing';
import { useFormContext } from 'react-hook-form';

interface FileInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onFileSelect: (file: File) => void;
  className?: string;
  label?: string;
  footerLabel?: string;
  acceptedTypes?: string[];
  previewSize?: number;
  error?: string;
  couponImageResultType?: CouponImageResultType;
  experimentId?: string;
  propertyPath: string;
}

export const FileInput = React.forwardRef<HTMLInputElement, FileInputProps>(
  (
    {
      onFileSelect,
      className = '',
      label = 'Attach image',
      acceptedTypes = ['image/jpeg', 'image/png', 'image/gif'],
      previewSize = 210,
      footerLabel = '',
      error,
      couponImageResultType = '',
      experimentId = '',
      propertyPath,
      ...restProps
    },
    ref
  ) => {
    const [isDragOver, setIsDragOver] = useState(false);
    const [previewImage, setPreviewImage] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [shouldFetch, setShouldFetch] = useState(false);

    const { setValue } = useFormContext();

    const { onChange, ...rest } = restProps;

    const { data, isPending, refetch } = useGetApiV1ExperimentsCorrosionExperimentidResultsImageType(
      experimentId,
      couponImageResultType,
      {
        client: {
          responseType: 'blob',
        },
        query: {
          enabled: false,
          refetchOnWindowFocus: false,
          retry: false,
        },
      }
    );

    useEffect(() => {
      if (data) {
        const objectURL = URL.createObjectURL(data);
        setPreviewImage(objectURL);
        setValue(propertyPath, data);
      }
    }, [data, propertyPath, setValue]);

    useEffect(() => {
      // Here you check if the parameters are valid
      const isValidParameter = Boolean(experimentId && couponImageResultType);
      setShouldFetch(isValidParameter);
      if (isValidParameter) {
        refetch();
      }
    }, [couponImageResultType, experimentId, refetch]);

    const handleFileSelect = useCallback(
      (file: File) => {
        if (file && acceptedTypes.includes(file.type)) {
          onFileSelect(file);
          const previewUrl = URL.createObjectURL(file);
          setPreviewImage(previewUrl);

          setValue(propertyPath, new Blob([file], { type: file.type }));

          // Cleanup the preview URL on unmount
          return () => URL.revokeObjectURL(previewUrl);
        }
      },
      [acceptedTypes, onFileSelect, propertyPath, setValue]
    );

    const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      setIsDragOver(true);
    }, []);

    const handleDragLeave = useCallback(() => {
      setIsDragOver(false);
    }, []);

    const handleDrop = useCallback((e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      setIsDragOver(false);
      if (e.dataTransfer.files.length > 0) {
        fileInputRef.current!.files = e.dataTransfer.files;
        triggerChange();
      }
    }, []);

    const handleClick = useCallback(() => {
      fileInputRef.current?.click();
    }, []);

    const triggerChange = () => {
      // create a new native event
      const nativeEvent = new Event('change', { bubbles: true });
      // Trigger the onChange event on the input element
      fileInputRef.current?.dispatchEvent(nativeEvent);
    };

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
          handleFileSelect(file);
        }
      },
      [handleFileSelect]
    );

    // Assign the ref to the input element
    React.useImperativeHandle(ref, () => fileInputRef.current!);

    if (shouldFetch && isPending)
      return (
        <div className="w-36 h-36 flex flex-col">
          <LoadingRing />
        </div>
      );

    return (
      <div className="text-center">
        <div
          className={`w-full border-2 border-dashed rounded-md px-2 py-2 cursor-pointer transition-colors duration-300 ${
            isDragOver ? 'border-blue-500 bg-blue-50' : 'border-gray-300 hover:border-gray-400'
          } ${className}`}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={handleClick}
        >
          <input
            type="file"
            className="hidden"
            ref={fileInputRef}
            onChange={handleChange}
            {...rest}
          />
          <div className="w-full h-36 flex flex-col items-center justify-center bg-gray-200 overflow-hidden">
            {previewImage ? (
              <img
                src={previewImage}
                alt="Preview"
                className="rounded-md object-cover"
                style={{ width: `${previewSize}px`, height: `${previewSize}px` }}
              />
            ) : (
              <>
                <span className="font-light text-gray-600">{label}</span>
                {/* SVG Icon */}
              </>
            )}
          </div>
        </div>
        {error && <div className="text-red-500 text-sm mt-2">{error}</div>}
        <span>{footerLabel}</span>
      </div>
    );
  }
);
