export function convertStringToDate(dateString: string): Date {
  // divide the date string into parts (year, month, day)
  const [year, month, day] = dateString.split('-');

  // create a new Date object with the year, month, and day values
  const fecha = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));

  return fecha;
}

export function dateToYYYYMMDD(date: Date = new Date()): string {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}
