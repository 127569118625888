import { useFormContext } from 'react-hook-form';
import { Input } from 'react-daisyui';
import { CellProps } from './types';
import { useTableConfig } from '../ConfigProvider';
import { Row } from '@components/tables/types';

const TextBoxCell = <T extends Row>({ row, column }: CellProps<T>) => {
  const { rowsPropertyName } = useTableConfig();
  const { register } = useFormContext();
  return (
    <Input
      className="input input-sm input-bordered w-full"
      key={row.original.id}
      {...register(`${rowsPropertyName}.${row.index}.${column.id}`)}
    />
  );
};

export default TextBoxCell;
