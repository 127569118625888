import client from "../../lib/api/client";
import { useMutation } from "@tanstack/react-query";
import type { PostApiV1CatalogsDropdownsTypeidMutationRequest, PostApiV1CatalogsDropdownsTypeidMutationResponse, PostApiV1CatalogsDropdownsTypeidPathParams, PostApiV1CatalogsDropdownsTypeidHeaderParams, PostApiV1CatalogsDropdownsTypeid400, PostApiV1CatalogsDropdownsTypeid401, PostApiV1CatalogsDropdownsTypeid403 } from "../models/PostApiV1CatalogsDropdownsTypeid";
import type { UseMutationOptions } from "@tanstack/react-query";

type PostApiV1CatalogsDropdownsTypeidClient = typeof client<PostApiV1CatalogsDropdownsTypeidMutationResponse, PostApiV1CatalogsDropdownsTypeid400 | PostApiV1CatalogsDropdownsTypeid401 | PostApiV1CatalogsDropdownsTypeid403, PostApiV1CatalogsDropdownsTypeidMutationRequest>;
type PostApiV1CatalogsDropdownsTypeid = {
    data: PostApiV1CatalogsDropdownsTypeidMutationResponse;
    error: PostApiV1CatalogsDropdownsTypeid400 | PostApiV1CatalogsDropdownsTypeid401 | PostApiV1CatalogsDropdownsTypeid403;
    request: PostApiV1CatalogsDropdownsTypeidMutationRequest;
    pathParams: PostApiV1CatalogsDropdownsTypeidPathParams;
    queryParams: never;
    headerParams: PostApiV1CatalogsDropdownsTypeidHeaderParams;
    response: PostApiV1CatalogsDropdownsTypeidMutationResponse;
    client: {
        parameters: Partial<Parameters<PostApiV1CatalogsDropdownsTypeidClient>[0]>;
        return: Awaited<ReturnType<PostApiV1CatalogsDropdownsTypeidClient>>;
    };
};
/**
     * @link /api/v1/catalogs/dropdowns/:typeId */
export function usePostApiV1CatalogsDropdownsTypeid(typeId: PostApiV1CatalogsDropdownsTypeidPathParams["typeId"], headers: PostApiV1CatalogsDropdownsTypeid["headerParams"], options: {
    mutation?: UseMutationOptions<PostApiV1CatalogsDropdownsTypeid["response"], PostApiV1CatalogsDropdownsTypeid["error"], PostApiV1CatalogsDropdownsTypeid["request"]>;
    client?: PostApiV1CatalogsDropdownsTypeid["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    return useMutation({
        mutationFn: async (data) => {
            const res = await client<PostApiV1CatalogsDropdownsTypeid["data"], PostApiV1CatalogsDropdownsTypeid["error"], PostApiV1CatalogsDropdownsTypeid["request"]>({
                method: "post",
                url: `/api/v1/catalogs/dropdowns/${typeId}`,
                data,
                headers: { ...headers, ...clientOptions.headers },
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions
    });
}