import { Card } from 'react-daisyui';
import { InitiateRequestButton } from './initiate-request-button';
import { RequestLocation } from './types';

export interface RequestLocationCardProps {
  requestCardPrompt: string;
  imageSrc: string;
  location: RequestLocation;
  onSelectionMade?: (location: RequestLocation) => void;
}

export const RequestLocationCard = ({
  location,
  onSelectionMade,
  imageSrc,
  requestCardPrompt,
}: RequestLocationCardProps) => {
  return (
    <div className="flex w-1/2 m-16">
      <Card bordered={false}>
        <Card.Title className="pb-3 justify-center">
          <span>{location} Tech Services</span>
        </Card.Title>
        <Card.Image
          src={imageSrc}
          className="rounded-xl cursor-pointer"
          onClick={() => onSelectionMade?.(location)}
        ></Card.Image>
        <Card.Body>
          <p className="text-center content-center mb-4">{requestCardPrompt}</p>
          <Card.Actions>
            <InitiateRequestButton
              className="btn btn-primary w-auto m-auto"
              location="Houston"
              onClick={onSelectionMade}
            />
          </Card.Actions>
        </Card.Body>
      </Card>
    </div>
  );
};
