import { TextBox } from '@components/form';
import { Form } from 'react-daisyui';
import { optionRelatedSchema } from './schema';
import { useFormContextExtended } from '@components/form/hooks/useFormContextExtended';
import { useWatch } from 'react-hook-form';
import { useEffect } from 'react';
import { CatalogType } from '../types';
import { DropDownCatalogViewSch } from '@sdk-client/models';

export type AddNewItemFormRelatedProps = {
  typeId: CatalogType;
  initialData: DropDownCatalogViewSch | null;
};

export const AddNewItemFormRelated = ({ typeId, initialData }: AddNewItemFormRelatedProps) => {
  const { extendRegister, errors, setValue } = useFormContextExtended(optionRelatedSchema);
  const relatedKeysValue = useWatch({ name: 'relatedKeysStr' });
  useEffect(() => {
    if (relatedKeysValue) {
      setValue(
        'relatedKeys',
        relatedKeysValue.split(',').map((el: string) => el.trim())
      );
    }
  }, [relatedKeysValue, setValue]);

  useEffect(() => {
    if (initialData) {
      setValue('id', initialData.id);
      setValue('value', initialData.value);
      setValue('text', initialData.text);
      setValue('relatedKeysStr', initialData.relatedKeys.join(','));
    }
  }, [initialData, setValue]);

  return (
    <>
      <Form>
        <TextBox
          label="Value"
          error={errors.value?.message}
          {...extendRegister('value')}
        />
        <TextBox
          label="Text"
          error={errors.text?.message}
          {...extendRegister('text')}
        />
        <TextBox
          label={`${getRelatedText(typeId)} (e.g. 1,2,3)`}
          error={errors.relatedKeysStr?.message}
          {...extendRegister('relatedKeysStr')}
        />
      </Form>
    </>
  );
};

const getRelatedText = (type: CatalogType) => {
  switch (type) {
    case CatalogType.EQUIPMENTS:
      return 'Related to Cell Number Value';
    case CatalogType.ADDITIVE_TYPE:
      return 'Related to Additive Type Value';
    case CatalogType.ADDITIVE:
      return 'Related Additive Type Value';
    case CatalogType.PHYSICAL_STATE:
      return 'Related Additive Value';
    default:
      return 'Related Values';
  }
};
